import { useMemo } from "react";

import { GetUserByIdQuery, UserSettingsInput } from "../../API";
import { useAuthenticatedUser } from "../../common/hooks/useAuthenticatedUser";
import { GET_CURRENT_USER } from "../../common/operations/queries";
import client from "../../configs/apolloClient";
import {
  DEFAULT_CAMERA_FOV,
  DEFAULT_WINDSPEED_UNIT,
  DEFAULT_VIDEO_SPEED,
} from "./useLazyGetUser";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";

export const useCachedUserSettings = () => {
  const { user, customerId } = useAuthenticatedUser();

  const memoizedCachedUserInfo = useMemo(() => {
    const userId = `U#${user?.attributes?.sub as string}`;
    const customerId = useCustomerIdGuard();

    let userSettingsFromCache: UserSettingsInput | null = null;

    const cachedUser = client.readQuery<GetUserByIdQuery>({
      query: GET_CURRENT_USER,
      variables: {
        userId,
        customerId,
      },
    });

    try {
      if (cachedUser?.getUserById) {
        const settings = cachedUser?.getUserById?.userSettings
          ? JSON.parse(cachedUser?.getUserById?.userSettings)
          : null;

        userSettingsFromCache = {
          defaultCameraFov: settings?.defaultCameraFov ?? DEFAULT_CAMERA_FOV,
          defaultWindspeedUnit:
            settings?.defaultWindspeedUnit ?? DEFAULT_WINDSPEED_UNIT,
          defaultVideoSpeed: settings?.defaultVideoSpeed ?? DEFAULT_VIDEO_SPEED,
        };

        return {
          ...cachedUser?.getUserById,
          userSettings: userSettingsFromCache,
        };
      }
    } catch (error) {
      console.error(error);

      return {
        ...cachedUser?.getUserById,
        userSettings: null,
      };
    }
  }, [user?.attributes?.sub, customerId]);

  return {
    ...memoizedCachedUserInfo,
    userSettings: memoizedCachedUserInfo?.userSettings,
  };
};
