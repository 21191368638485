import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";
import { SubmitHandler, useForm } from "react-hook-form";
import { RouteEnum } from "../../../../../common/models/enums";
import confirmDialogStyleOptions from "../../../../../common/helpers/confirmDialogParams";
import BreadcrumbNavigation from "../../../../../common/components/tabs/BreadcrumbNavigation";
import MainPaperWrapper from "../../../../../common/components/item/MainPaperWrapper";
import StyledLoadingButton from "../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";

const PauseAlertsPage = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { handleSubmit } = useForm<any>({});

  const onSubmit: SubmitHandler<any> = formData => {};

  const confirmCancel = (path = RouteEnum.LeakFinderClientUploads): void => {
    confirm({
      title: "Are you sure you want to cancel editing?",
      description: "If you leave, the new informations won’t be saved",
      confirmationText: "Leave",
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then((): void => {
      navigate(path);
    });
  };

  const handleCancel = (): void => {
    confirmCancel();
  };

  // if (loading) {
  //   return <FullPageLoader />;
  // }

  const breadcrumbItems = [
    {
      label: "Data hub",
      onClick: () => confirmCancel(RouteEnum.RecentActivities),
    },
    {
      label: "Gate guard events",
      onClick: () => confirmCancel(RouteEnum.Agg),
    },
    {
      label: "Plates Management",
      onClick: () => confirmCancel(RouteEnum.RecentActivitiesPlatesManagement),
    },
    {
      label: "Disable alerts",
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <Typography variant="h3" sx={{ padding: "1em 0" }}>
        {"Disable alerts"}
      </Typography>

      <MainPaperWrapper>
        <Typography variant="body1Bold" sx={{ padding: "1em 0" }}>
          {"Set time period when alerts shouldn't be received"}
        </Typography>
        {/* Disable alerts form here with selected time component */}
        {/* <EditPauseAlertsForm control={control} errors={errors} /> */}
      </MainPaperWrapper>

      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Disable alerts
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default PauseAlertsPage;
