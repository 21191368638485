import {
  ApolloError,
  gql,
  useMutation,
  type FetchResult,
} from "@apollo/client";

import type {
  GetServicesByLocationQuery,
  Service,
  SetIsRunningServiceStatusMutation,
  SetIsRunningServiceStatusMutationVariables,
  SetServiceStatusInput,
} from "../../../API";
import { SET_RUNNING_SERVICE_STATUS } from "../../../common/operations/mutations";
import { GET_SERVICES_BY_LOCATION } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import { servicesByLocationVariable } from "../variables/devices";

interface UpdateServiceInterface {
  setIsRunningServiceStatus: (
    input: SetServiceStatusInput
  ) => Promise<FetchResult<SetIsRunningServiceStatusMutation>>;
  data?: SetIsRunningServiceStatusMutation | null;
  loading: boolean;
}

export const useSetIsRunningServiceStatus = (): UpdateServiceInterface => {
  const [updateStatus, { data, loading }] = useMutation<
    SetIsRunningServiceStatusMutation,
    SetIsRunningServiceStatusMutationVariables
  >(SET_RUNNING_SERVICE_STATUS);

  const setIsRunningServiceStatus = async (
    input: SetServiceStatusInput
  ): Promise<FetchResult<SetIsRunningServiceStatusMutation>> => {
    const nodeId = input.nodeId;
    const parts = nodeId.split("#");
    const locationId = parts.slice(0, 4).join("#");

    return await updateStatus({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        console.error(error);

        errorNotification(error.message);
      },
      update(cache, { data }) {
        if (data && data.setIsRunningServiceStatus) {
          const queryData = cache.readQuery<GetServicesByLocationQuery>({
            query: GET_SERVICES_BY_LOCATION,
            variables: { locationId },
          });

          if (queryData) {
            const updatedServices =
              queryData.getServicesByLocation.items.map(service => {
                if (
                  (data.setIsRunningServiceStatus.id ?? "").includes(
                    service?.id as string
                  ) &&
                  service?.deviceId === data.setIsRunningServiceStatus.deviceId
                ) {
                  return {
                    ...service,
                    isRunning: data.setIsRunningServiceStatus.isRunning,
                  };
                }

                return service;
              }) ?? [];

            cache.writeQuery({
              query: GET_SERVICES_BY_LOCATION,
              data: {
                getServicesByLocation: {
                  ...queryData.getServicesByLocation,
                  items: updatedServices,
                },
              },
            });

            servicesByLocationVariable(updatedServices as Service[]);
          }

          cache.modify({
            fields: {
              getServicesByDevice(existing) {
                const newServiceRef = cache.writeFragment({
                  data: data.setIsRunningServiceStatus,
                  fragment: gql`
                    fragment NewService on Service {
                      configuration
                      customerId
                      deviceId
                      healthData
                      id
                      isRunning
                      locationId
                      nodeId
                      serviceType
                    }
                  `,
                });

                return {
                  ...existing,
                  items: existing.items.map((item: { id: any }) => {
                    if (item?.id !== data.setIsRunningServiceStatus.id) {
                      return item;
                    }

                    return newServiceRef;
                  }),
                };
              },
            },
          });
        }
      },
    });
  };

  return { setIsRunningServiceStatus, data, loading };
};
