import { FC } from "react";
import { useParams } from "react-router-dom";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";

import CloseDialogIcon from "../../../../common/components/icons/CloseDialogIcon";
import ControlTable from "../../../../common/components/stream/ControlTable";
import {
  PAN_DEFAULT,
  TILT_DEFAULT,
  usePTController,
  ZOOM_DEFAULT,
} from "../../../../common/hooks/usePTController";
import HLSStreamPlayer from "../../../data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import PTZoneListTableContainer from "../../../data-hub/components/liveview/components/devices/ptzonelist/PTZoneListTableContainer";
import {
  selectedZoneVariable,
  useFirstZoneVariable,
} from "../../../data-hub/components/liveview/components/devices/ptzonelist/zones.variable";
import { DeviceDataType } from "../../hooks/useDeviceTableRows";
import { usePublishNode } from "../../hooks/usePublishNode";

interface PtControllerDialogProps {
  deviceId: string;
  deviceName: string;
  nodeId: string;
  showPtButton?: boolean;
  ptOpen: boolean;
  deviceData?: DeviceDataType;
  onPtOpen?: () => void;
  onPtClose?: () => void;
  hasZoom?: boolean;
}

const PtControllerDialog: FC<PtControllerDialogProps> = ({
  deviceId,
  deviceName,
  nodeId,
  showPtButton,
  ptOpen,
  onPtOpen,
  onPtClose,
  hasZoom,
  deviceData,
}): JSX.Element => {
  const { deviceId: deviceIdSearchParameter = "" } = useParams();

  const firstZoneVariable = useFirstZoneVariable();

  const { publishNode } = usePublishNode();

  const {
    pan,
    tilt,
    zoom,
    handleGoToPt,
    handlePanInputChange,
    handleTiltInputChange,
    handleZoomInputChange,
    movePtDown,
    movePtLeft,
    movePtRight,
    movePtUp,
    validationState,
    disabled,
  } = usePTController({
    deviceId: deviceIdSearchParameter ?? deviceId,
    deviceName,
    nodeId,
    deviceData,
  });

  const handleOpenPtControlsOnClick = (): void => {
    if (onPtOpen) {
      onPtOpen();
    }

    // No need to trigger KINESIS_STREAM_START since it's already started from the page
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "START_PT_CONTROL",
        device_name: deviceName,
      }),
      nodeId: nodeId,
    });
  };

  const handleClose = (): void => {
    const firstZonePan = firstZoneVariable?.pan ?? PAN_DEFAULT;
    const firstZoneTilt = firstZoneVariable?.tilt ?? TILT_DEFAULT;
    const firstZoneZoom = firstZoneVariable?.zoom ?? ZOOM_DEFAULT;
    const firstZoneName = firstZoneVariable?.zoneName ?? "";

    handleGoToPt(firstZonePan, firstZoneTilt, firstZoneZoom, firstZoneName);

    // No need to trigger KINESIS_STREAM_END since we need it running on the details page when close the dialog
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "STOP_PT_CONTROL",
        device_name: deviceName,
      }),
      nodeId,
    }).then((): void => {
      if (onPtClose) {
        onPtClose();

        selectedZoneVariable(firstZoneVariable);
      }
    });
  };

  return (
    <>
      {showPtButton && (
        <Button
          sx={{
            "&": {
              color: "text.primary",
              borderColor: "text.primary",
              backgroundColor: "otherBackgroundLight.main",
              textTransform: "none",
            },
            "&:hover": {
              color: "primary.main",
              borderColor: "primary.main",
              backgroundColor: "otherBackgroundLight.main",
              textTransform: "none",
            },
          }}
          variant="outlined"
          size="small"
          onClick={handleOpenPtControlsOnClick}
        >
          <Typography variant="buttonMedium" component="span">
            Open PT controller
          </Typography>
        </Button>
      )}
      <Dialog
        sx={{
          width: "100%",
        }}
        PaperProps={{
          sx: {
            maxHeight: "calc(100% - 174px)",
            height: "100%",
            maxWidth: "1360px",
          },
        }}
        open={ptOpen}
        maxWidth="xl"
        fullWidth
        onClose={handleClose}
      >
        <CloseDialogIcon onClose={handleClose} />
        <DialogContent
          sx={{
            display: "flex",
            padding: "0px",
            width: "100%",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <HLSStreamPlayer setDialogStyles />
          </Box>
          <Box
            sx={{
              padding: "2em",
              paddingBottom: 0,
              width: "412px",
              maxWidth: "412px",
              minWidth: "412px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5em",
                }}
              >
                <ErrorOutlineIcon
                  sx={{
                    color: "warning.main",
                  }}
                />
                <Typography
                  sx={{
                    color: "warning.main",
                    "& p": {
                      margin: 0,
                    },
                  }}
                  variant="body2Regular"
                  component="span"
                >
                  <p>All models are stopped while you</p>
                  <p>are running pt control</p>
                </Typography>
              </Box>

              <Divider
                sx={{
                  margin: "1.5em 0",
                }}
              />

              <Typography
                sx={{
                  marginBottom: "1em",
                }}
                variant="subtitle1"
              >
                Move the camera position
              </Typography>

              <ControlTable
                pan={pan}
                tilt={tilt}
                zoom={zoom}
                validation={validationState}
                showValidationAlert
                handleGoToPt={handleGoToPt}
                handlePanInputChange={handlePanInputChange}
                handleTiltInputChange={handleTiltInputChange}
                handleZoomInputChange={handleZoomInputChange}
                movePtLeft={movePtLeft}
                movePtRight={movePtRight}
                movePtUp={movePtUp}
                movePtDown={movePtDown}
                hasZoom={hasZoom}
                ptDisabled={disabled}
              />

              <Divider
                sx={{
                  margin: "1.5em 0",
                }}
              />

              <Typography variant="body1Bold" component="span">
                Observing zones
              </Typography>

              <PTZoneListTableContainer
                currentDeviceId={deviceIdSearchParameter ?? deviceId ?? ""}
                nodeId={nodeId ?? ""}
                hasZoom={hasZoom ?? false}
                disableRowClick={disabled}
                handleGoToPt={handleGoToPt}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PtControllerDialog;
