import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";
import { IHardHatEventsTableRow } from "../../common/events.models";
import EventSnapshotTableCell from "../../common/EventSnapshotTableCell";
import { HardHatColumnFields } from "../enums/HardHatColumnFields";
import TableCellTruncated from "../../../../../../common/components/table/TableCellTruncated";

export const useGetHardHatEventsTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef<IHardHatEventsTableRow>[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: HardHatColumnFields.Snapshot,
      headerName: "Snapshot",
      flex: 1,
      minWidth: 160,
      width: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: EventSnapshotTableCell,
    },
    {
      field: HardHatColumnFields.Location,
      headerName: "Location",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (locationId?: string | null): string => {
        const locationName = getCachedLocationName(locationId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row?.location ?? "");

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={locationName}
            text={locationName}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: HardHatColumnFields.Confidence,
      headerName: "Confidence",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const confidence = params?.row?.confidence;

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={""}
            text={confidence}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row.cameraId}
            text={params?.row.cameraId}
            TypographyProps={{
              variant: "body2Bold",
            }}
          />
        ) : null,
    },
    {
      field: HardHatColumnFields.Timestamp,
      headerName: "Timestamp",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (timestamp: string): string => formatTimestamp(timestamp),
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={formatTimestamp(params?.row.timestamp)}
            text={formatTimestamp(params?.row.timestamp)}
            TypographyProps={{
              variant: "body2",
            }}
          />
        ) : null,
    },
  ];

  return { columns };
};
