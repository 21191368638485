import { gql, useSubscription } from "@apollo/client";

import { ListenToUpdateZoneSubscription, Zone } from "../../../../API";
import { LISTEN_UPDATE_ZONE } from "../../../../common/operations/subscriptions";
import { successNotification } from "../../../../common/variables/notification";

export const useListenToUpdateZone = (zoneId?: string) => {
  const { data, loading, error } =
    useSubscription<ListenToUpdateZoneSubscription>(LISTEN_UPDATE_ZONE, {
      fetchPolicy: "no-cache",
      variables: {
        id: zoneId,
      },
      shouldResubscribe: true,
      onSubscriptionData: ({ client, subscriptionData }) => {
        if (subscriptionData?.data?.listenToUpdateZone) {
          const zone = subscriptionData.data.listenToUpdateZone;

          const zoneId = `Zone:${zone.deviceId}#${zone.id}`;

          const zoneData = client.cache.readFragment<Zone>({
            id: zoneId,
            fragment: gql`
              fragment ZoneFragment on Zone {
                id
                status
              }
            `,
          });

          const isStatusUpdated = zoneData?.status !== zone.status;

          client.cache.writeQuery({
            query: LISTEN_UPDATE_ZONE,
            data: subscriptionData.data,
            variables: { id: zoneId },
          });

          let message = `Zone ${zone.name} is updated`;

          if (isStatusUpdated) {
            message = zone.status
              ? `Zone ${zone.name} is enabled`
              : `Zone ${zone.name} is disabled`;
          }

          successNotification(message);
        }
      },
    });

  if (error) {
    console.error("Subscription error:", error);
  }

  return { data, loading, error } as const;
};
