import { useBase64 } from "../../../../../common/hooks/useBase64";
import useLocationFromCache from "../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../common/utils/timestampUtils";
import { errorNotification } from "../../../../../common/variables/notification";
import useCSVDownloader from "../../../../../leak-finder/hooks/useCSVDownloader";
import useConvertArrayToCSV from "../../../../../leak-finder/hooks/useConvertArrayToCSV";
import { useGetLiquidLeakEventsToExport } from "../liquidleak/hooks/useGetLiquidLeakEventsToExport";

const useExportLiquidleakData = () => {
  const { getBase64MediaViewerPageLink } = useBase64();

  const { downloadCSV } = useCSVDownloader();

  const { convertArrayToCSV } = useConvertArrayToCSV();

  const { getCachedLocation } = useLocationFromCache();

  const { getCsvDataToExport, loading } = useGetLiquidLeakEventsToExport();

  const liquidLeakColumns = [
    { field: "location", headerName: "Location" },
    { field: "timestamp", headerName: "Timestamp" },
    { field: "cameraId", headerName: "Device ID" },
    { field: "mediaOutput", headerName: "Media" },
  ];

  const exportLiquidleakData = async () => {
    try {
      const rows = await getCsvDataToExport();

      if (rows.length > 0) {
        const updatedRows = rows.map(row => {
          const newRow = { ...row };
          const locationParts = newRow.location.includes("#L#")
            ? getCachedLocation(newRow.location ?? "")?.name ?? ""
            : newRow.location;

          if (locationParts.length > 1) {
            newRow.location = locationParts;
          }

          newRow.timestamp = formatTimestamp(newRow.timestamp);

          if (newRow.mediaOutput) {
            newRow.mediaOutput = getBase64MediaViewerPageLink(
              newRow.mediaOutput
            );
          }

          return newRow;
        });

        const csvString = convertArrayToCSV(updatedRows, liquidLeakColumns);

        downloadCSV(csvString, `liquidleak_events.csv`);
      }
    } catch (error) {
      errorNotification("No data available to export");

      console.error("Error exporting liquid leak data:", error);
    }
  };

  return { exportLiquidleakData, loading };
};

export default useExportLiquidleakData;
