import { MouseEvent, useRef, useState } from "react";

import { GridCellParams, GridRowParams, MuiEvent } from "@mui/x-data-grid";

import { S3Helper } from "../../../common/utils/s3helper";

interface IUseRowClick {
  open: boolean;
  mediaOutputLoading: boolean;
  mediaOutput: string;
  signedUrl: string;
  closeDialog: () => void;
  handleRowClick: (
    key?: string
  ) => (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => void;
  handleKeyFrameClick: (params: GridCellParams) => void;
  handleMediaOutputClick: (params: GridRowParams) => void;
}

export const useRowClick = (): IUseRowClick => {
  const keyFrameRef = useRef(false);

  const [open, setOpen] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaOutput, setMediaOutput] = useState("");
  const [signedUrl, setSignedUrl] = useState("");

  const closeDialog = (): void => {
    setMediaOutput("");

    keyFrameRef.current = false;

    setOpen(false);
  };

  const handleRowClick =
    (key = "mediaOutput") =>
    (params: GridRowParams, event: MuiEvent<MouseEvent>) => {
      if (event.defaultMuiPrevented) {
        return;
      }

      const mediaUrl = params?.row?.[key];

      setMediaLoading(true);

      setMediaOutput(mediaUrl ?? "");

      S3Helper.getObject(mediaUrl)
        .then((signedUrl: string): void => {
          setSignedUrl(signedUrl);

          setMediaLoading(false);

          setOpen(true);
        })
        .catch((error: Error): void => {
          console.log("S3Video", error.message);

          setMediaOutput("");

          setSignedUrl("");
        })
        .finally((): void => setMediaLoading(false));
    };

  const handleKeyFrameClick = (params: GridCellParams): void => {
    setMediaLoading(true);

    keyFrameRef.current = true;

    const mediaUrl = params?.row?.keyFrame;

    S3Helper.getObject(mediaUrl)
      .then((signedUrl: string): void => {
        setSignedUrl(signedUrl);

        setMediaOutput(mediaUrl ?? "");

        setMediaLoading(false);

        setOpen(true);
      })
      .catch((error: Error): void => {
        console.log("S3Image", error.message);

        setMediaOutput("");

        setSignedUrl("");
      })
      .finally((): void => setMediaLoading(false));
  };

  const handleMediaOutputClick = (params: GridRowParams): void => {
    // prevent the row click event from firing if the keyFrame was clicked
    if (keyFrameRef.current) {
      return;
    }

    setMediaLoading(true);

    const mediaUrl = params?.row?.mediaOutput;

    S3Helper.getObject(mediaUrl)
      .then((signedUrl: string): void => {
        setSignedUrl(signedUrl);

        setMediaOutput(mediaUrl ?? "");

        setMediaLoading(false);

        setOpen(true);
      })
      .catch((error: Error): void => {
        console.error("S3Video", error.message);

        setMediaOutput("");

        setSignedUrl("");
      })
      .finally((): void => setMediaLoading(false));
  };

  return {
    open,
    mediaOutputLoading: mediaLoading,
    mediaOutput,
    signedUrl,
    closeDialog,
    handleRowClick,
    handleKeyFrameClick,
    handleMediaOutputClick,
  };
};
