import { useCallback } from "react";

import { GridCellParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import { parseEventMediaPath } from "../../../../../common/helpers/formatJsonString";
import RecentEventsHoverIcons from "../recentEvents/RecentEventsHoverIcons";

const EventSnapshotTableCell = (props: GridCellParams) => {
  const keyFrame = parseEventMediaPath(props?.row?.keyFrame);

  const mediaOutput = parseEventMediaPath(props?.row?.mediaOutput);

  const memoizedKeyFrameClickCallback = useCallback((): void => {
    props?.row?.onKeyFrameClick && props?.row?.onKeyFrameClick(props);
  }, [props?.row]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "96px",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <RecentEventsHoverIcons
        keyFrame={keyFrame}
        mediaOutput={mediaOutput}
        onClick={memoizedKeyFrameClickCallback}
      />
    </Box>
  );
};

export default EventSnapshotTableCell;
