import { useState } from "react";
import { useGetZonesByDevice } from "../../../data-hub/components/liveview/components/zonelist/hooks/useGetZonesByDevice";

export interface ICreateZoneValidationState {
  zoneName: {
    hasError: boolean;
    errorMessage: string;
  };
  pan: {
    hasError: boolean;
    errorMessage: string;
  };
  panValue: {
    hasError: boolean;
    errorMessage: string;
  };
  tilt: {
    hasError: boolean;
    errorMessage: string;
  };
  tiltValue: {
    hasError: boolean;
    errorMessage: string;
  };
  zoom: {
    hasError: boolean;
    errorMessage: string;
  };
  zoomValue: {
    hasError: boolean;
    errorMessage: string;
  };
}

export const validationStateDefault: ICreateZoneValidationState = {
  zoneName: {
    hasError: false,
    errorMessage: "",
  },
  pan: {
    hasError: false,
    errorMessage: "",
  },
  tilt: {
    hasError: false,
    errorMessage: "",
  },
  zoom: {
    hasError: false,
    errorMessage: "",
  },
  panValue: {
    hasError: false,
    errorMessage: "",
  },
  tiltValue: {
    hasError: false,
    errorMessage: "",
  },
  zoomValue: {
    hasError: false,
    errorMessage: "",
  },
};

const PAN_MIN = -180;
const PAN_MAX = 180;
const TILT_MIN = -90;
const TILT_MAX = 35;
const ZOOM_MIN = 0;
const ZOOM_MAX = 100;

export const useZoneFormValidation = (
  originalZoneName: string | undefined = undefined
) => {
  const [validationState, setValidationState] =
    useState<ICreateZoneValidationState>(validationStateDefault);

  const { data } = useGetZonesByDevice();
  const zones = data?.getZonesByDevice.items;

  const resetValidationState = () => {
    setValidationState(validationStateDefault);
  };

  const validateCreateZoneForm = (
    zoneName: string,
    pan: number | string,
    tilt: number | string,
    zoom: number | string
  ) => {
    resetValidationState();

    let state = { ...validationStateDefault };

    if (!zoneName) {
      const zoneNameError = {
        hasError: true,
        errorMessage: "This field is required",
      };

      state = {
        ...state,
        zoneName: zoneNameError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoneName: zoneNameError,
        })
      );
    }

    if (
      zones?.some(
        zone => zone?.name === zoneName && originalZoneName !== zoneName
      )
    ) {
      const locationNameError = {
        hasError: true,
        errorMessage: "Zone name already exists",
      };

      state = {
        ...state,
        zoneName: locationNameError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoneName: locationNameError,
        })
      );
    }

    if (pan === null || pan === undefined || pan === "") {
      const panError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        pan: panError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          pan: panError,
        })
      );
    }

    if (Number(pan) < PAN_MIN || Number(pan) > PAN_MAX) {
      const panValueError = {
        hasError: true,
        errorMessage: `Pan must be between ${PAN_MIN} and ${PAN_MAX}`,
      };

      state = {
        ...state,
        panValue: panValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          panValue: panValueError,
        })
      );
    }

    if (tilt === null || tilt === undefined || tilt === "") {
      const tiltError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        tilt: tiltError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tilt: tiltError,
        })
      );
    }

    if (Number(tilt) < TILT_MIN || Number(tilt) > TILT_MAX) {
      const tiltValueError = {
        hasError: true,
        errorMessage: `Tilt must be between ${TILT_MIN} and ${TILT_MAX}`,
      };

      state = {
        ...state,
        tiltValue: tiltValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tiltValue: tiltValueError,
        })
      );
    }

    if (zoom === null || zoom === undefined || zoom === "") {
      const zoomError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        zoom: zoomError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoom: zoomError,
        })
      );
    }

    if (Number(zoom) < ZOOM_MIN || Number(zoom) > ZOOM_MAX) {
      const zoomValueError = {
        hasError: true,
        errorMessage: `Zoom must be between ${ZOOM_MIN} and ${ZOOM_MAX}`,
      };

      state = {
        ...state,
        zoomValue: zoomValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoomValue: zoomValueError,
        })
      );
    }

    if (Object.values(state).some((item): boolean => item.hasError)) {
      return false;
    }

    return true;
  };

  const validatePanTilt = (pan: number | string, tilt: number | string) => {
    let state = { ...validationStateDefault };

    if (pan === null || pan === undefined || pan === "") {
      const panError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        pan: panError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          pan: panError,
        })
      );
    }

    if (Number(pan) < PAN_MIN || Number(pan) > PAN_MAX) {
      const panValueError = {
        hasError: true,
        errorMessage: `Pan must be between ${PAN_MIN} and ${PAN_MAX}`,
      };

      state = {
        ...state,
        panValue: panValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          panValue: panValueError,
        })
      );
    }

    if (tilt === null || tilt === undefined || tilt === "") {
      const tiltError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        tilt: tiltError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tilt: tiltError,
        })
      );
    }

    if (Number(tilt) < TILT_MIN || Number(tilt) > TILT_MAX) {
      const tiltValueError = {
        hasError: true,
        errorMessage: `Tilt must be between ${TILT_MIN} and ${TILT_MAX}`,
      };

      state = {
        ...state,
        tiltValue: tiltValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          tiltValue: tiltValueError,
        })
      );
    }

    if (Object.values(state).some((item): boolean => item.hasError)) {
      return false;
    }

    return true;
  };

  const validateZoom = (zoom: number | string) => {
    let state = { ...validationStateDefault };

    if (zoom === null || zoom === undefined || zoom === "") {
      const zoomError = {
        hasError: true,
        errorMessage: "Required",
      };

      state = {
        ...state,
        zoom: zoomError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoom: zoomError,
        })
      );
    }

    if (Number(zoom) < ZOOM_MIN || Number(zoom) > ZOOM_MAX) {
      const zoomValueError = {
        hasError: true,
        errorMessage: `Zoom must be between ${ZOOM_MIN} and ${ZOOM_MAX}`,
      };

      state = {
        ...state,
        zoomValue: zoomValueError,
      };

      setValidationState(
        (prev): ICreateZoneValidationState => ({
          ...prev,
          zoomValue: zoomValueError,
        })
      );
    }

    if (Object.values(state).some((item): boolean => item.hasError)) {
      return false;
    }

    return true;
  };

  return {
    validationState,
    setValidationState,
    resetValidationState,
    validateCreateZoneForm,
    validatePanTilt,
    validateZoom,
  };
};
