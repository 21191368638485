import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  PTZ_MAX_WIDTH,
  PTZ_MIN_WIDTH,
  TILT_PATTERN,
  ZOOM_MAX,
  ZOOM_MIN,
} from "./constants";

interface ZoomInputProps {
  onZoomChange: (pan: string) => void;
  value: string;
}

type ZoomInputPropsType = TextFieldProps & ZoomInputProps;

const ZoomInput = ({ onZoomChange, value, ...props }: ZoomInputPropsType) => {
  const handleZoomInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const zoomValue = event.target.value;

    onZoomChange(zoomValue);
  };

  return (
    <TextField
      type="number"
      error={props.error}
      helperText={props.helperText}
      inputProps={{
        inputMode: "numeric",
        pattern: TILT_PATTERN,
        min: ZOOM_MIN,
        max: ZOOM_MAX,
      }}
      sx={{
        minWidth: PTZ_MIN_WIDTH,
        maxWidth: PTZ_MAX_WIDTH,
        "& input": {
          height: "18px",
          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      }}
      id="zoom"
      size="small"
      variant="outlined"
      margin="dense"
      disabled={props.disabled}
      value={value}
      onChange={handleZoomInputChange}
    />
  );
};

export default ZoomInput;
