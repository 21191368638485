import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import Cookie from "js-cookie";

import appSyncConfig from "../../aws-exports.json";
import { isLocalEnvironment } from "../helpers/userLinkHelper";

export const useSetSharedSession = () => {
  useEffect(() => {
    const strConfig = Cookie.get("amplify_config");

    const config = JSON.parse(strConfig ?? "{}");

    const isLocal = isLocalEnvironment();

    if (isLocal) {
      appSyncConfig.Auth.cookieStorage.domain = window.location.hostname;
    }

    appSyncConfig.aws_user_pools_web_client_id =
      config.appClientId ?? appSyncConfig.aws_user_pools_web_client_id;

    appSyncConfig.Auth.userPoolWebClientId =
      config.appClientId ?? appSyncConfig.Auth.userPoolWebClientId;

    Amplify.configure(appSyncConfig);
  }, []);
};
