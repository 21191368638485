import { RoleEnum } from "../models/enums";

export enum SystemSettingsTabsEnum {
  MANAGE_LOCATIONS = "manage-locations",
  USER_PREFERENCES = "user-preferences",
  ALERT_MANAGEMENT = "alert-management",
  DEVICES = "devices",
  INTEGRATIONS = "integrations",
  PLATES_MANAGEMENT = "plates-management",
  EDIT_CUSTOMER = "edit-customer",
}

export enum CustomerSettingsTabsEnum {
  MANAGE_LOCATIONS = "manage-locations",
  ALERT_MANAGEMENT = "alert-management",
}

export enum AlertManagementTabsEnum {
  CUSTOMER = "customer",
  LOCATION = "location",
  MODEL = "model",
  TAG = "tag",
  UPDATES_ALERTS = "updates-alerts",
  GATE_GUARD = "gate-guard",
}

export const AdminRoles = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
];

export const NonAdminRoles = [
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.PARTNER_USER,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
  RoleEnum.CC_PROCTORS,
];

export const SystemSettingsTabsAllowedRoles = {
  [CustomerSettingsTabsEnum.MANAGE_LOCATIONS]: [
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.PARTNER_USER,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ],
  [CustomerSettingsTabsEnum.ALERT_MANAGEMENT]: [
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
  [SystemSettingsTabsEnum.DEVICES]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
  ],
  [SystemSettingsTabsEnum.EDIT_CUSTOMER]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
  ],
  [SystemSettingsTabsEnum.PLATES_MANAGEMENT]: [
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ],
  [SystemSettingsTabsEnum.INTEGRATIONS]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ],
  [SystemSettingsTabsEnum.USER_PREFERENCES]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.PARTNER_USER,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ],
};

export const SystemSettingsTabsList = [
  {
    id: SystemSettingsTabsEnum.MANAGE_LOCATIONS,
    label: "Manage locations",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.MANAGE_LOCATIONS],
  },
  {
    id: SystemSettingsTabsEnum.ALERT_MANAGEMENT,
    label: "Alert management",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.ALERT_MANAGEMENT],
  },
  {
    id: SystemSettingsTabsEnum.USER_PREFERENCES,
    label: "User preferences",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.USER_PREFERENCES],
  },
  {
    id: SystemSettingsTabsEnum.DEVICES,
    label: "Device makes",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.DEVICES],
  },
  {
    id: SystemSettingsTabsEnum.INTEGRATIONS,
    label: "Integrations",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.INTEGRATIONS],
  },
  {
    id: SystemSettingsTabsEnum.EDIT_CUSTOMER,
    label: "Edit customer",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.EDIT_CUSTOMER],
  },
  {
    id: SystemSettingsTabsEnum.PLATES_MANAGEMENT,
    label: "Plates Management",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.PLATES_MANAGEMENT],
  },
];

export const CustomerSettingsTabsAllowedRoles = {
  [CustomerSettingsTabsEnum.MANAGE_LOCATIONS]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_ADMIN,
  ],
  [CustomerSettingsTabsEnum.ALERT_MANAGEMENT]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.PARTNER_USER,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ],
};

export const CustomerSettingsTabsList = [
  {
    id: CustomerSettingsTabsEnum.MANAGE_LOCATIONS,
    label: "Manage locations",
    allowedRoles:
      CustomerSettingsTabsAllowedRoles[
        CustomerSettingsTabsEnum.MANAGE_LOCATIONS
      ],
  },
  {
    id: CustomerSettingsTabsEnum.ALERT_MANAGEMENT,
    label: "Alert management",
    allowedRoles:
      CustomerSettingsTabsAllowedRoles[
        CustomerSettingsTabsEnum.ALERT_MANAGEMENT
      ],
  },
];

export const AlertManagementTabsAllowedRoles = {
  [AlertManagementTabsEnum.CUSTOMER]: [
    RoleEnum.ROOT,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
  [AlertManagementTabsEnum.GATE_GUARD]: [
    RoleEnum.ROOT,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
  [AlertManagementTabsEnum.LOCATION]: [
    RoleEnum.ROOT,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
  [AlertManagementTabsEnum.MODEL]: [
    RoleEnum.ROOT,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
  [AlertManagementTabsEnum.TAG]: [
    RoleEnum.ROOT,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
  [AlertManagementTabsEnum.UPDATES_ALERTS]: [
    RoleEnum.ROOT,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  ],
};

export const AlertManagementTabsList = [
  {
    id: AlertManagementTabsEnum.CUSTOMER,
    label: "Customer",
    allowedRoles:
      AlertManagementTabsAllowedRoles[AlertManagementTabsEnum.CUSTOMER],
  },
  // NOTE: COMMENTED OUT TEMPORARILY UNTIL PROPER COMPONENTS ARE CREATED
  {
    id: AlertManagementTabsEnum.LOCATION,
    label: "Location",
    allowedRoles:
      AlertManagementTabsAllowedRoles[AlertManagementTabsEnum.LOCATION],
  },
  {
    id: AlertManagementTabsEnum.MODEL,
    label: "Model",
    allowedRoles:
      AlertManagementTabsAllowedRoles[AlertManagementTabsEnum.MODEL],
  },
  // {
  //   id: AlertManagementTabsEnum.TAG,
  //   label: "Tag",
  //   allowedRoles: AlertManagementTabsAllowedRoles[AlertManagementTabsEnum.TAG],
  // },
  // {
  //   id: AlertManagementTabsEnum.UPDATES_ALERTS,
  //   label: "Updates alerts",
  //   allowedRoles:
  //     AlertManagementTabsAllowedRoles[AlertManagementTabsEnum.UPDATES_ALERTS],
  // },
  {
    id: AlertManagementTabsEnum.GATE_GUARD,
    label: "Gate Guard",
    allowedRoles:
      AlertManagementTabsAllowedRoles[AlertManagementTabsEnum.GATE_GUARD],
  },
];
