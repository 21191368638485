import { TextField } from "@mui/material";

import { DeviceData } from "../../../../API";
import { TDeviceConfig } from "../../variables/devices";

interface DeviceDynamicTextInputProps {
  hasError?: boolean;
  disableInput?: boolean;
  field: Exclude<keyof DeviceData, "__typename">;
  deviceConfig: TDeviceConfig;
  setDeviceConfig: (
    field: Exclude<keyof DeviceData, "__typename">,
    value: string
  ) => void;
  label: string;
  helperText?: string;
}

const DeviceDynamicTextInput = ({
  label,
  hasError,
  field,
  disableInput,
  deviceConfig,
  setDeviceConfig,
  helperText,
}: DeviceDynamicTextInputProps): JSX.Element => {
  const value = deviceConfig[field] ?? "";

  return (
    <TextField
      margin="dense"
      disabled={disableInput}
      fullWidth
      id={field + "-id"}
      error={hasError}
      helperText={hasError ? helperText : ""}
      label={label}
      value={value}
      onChange={(event): void => setDeviceConfig(field, event.target.value)}
    />
  );
};

export default DeviceDynamicTextInput;
