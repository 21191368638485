import React, { useEffect, useState } from "react";

import { Box, FormGroup, IconButton, Tooltip } from "@mui/material";

import DeleteIconButton from "../../../common/components/icons/DeleteIconButtonWithText";
import StatusToggle from "../../../common/components/item/StatusToggle";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import { IDeviceTableRow, IModel } from "../hooks/useDeviceTableRows";
import { useGetAnnotations } from "../hooks/useGetAnnotations";
import { useServiceActions } from "../hooks/useServiceActions";

type ModelActionControlsProps = {
  device: IDeviceTableRow;
  deviceModel: IModel;
  isModelRunning: boolean;
  runningLeakModels: IModel[];
  setIsModelRunning: (newStatus: boolean) => void;
};

const ModelActionControls: React.FC<ModelActionControlsProps> = ({
  device,
  deviceModel,
  isModelRunning,
  setIsModelRunning,
  runningLeakModels,
}): JSX.Element => {
  const [allowStartTlmModel, setAllowStartTlmModel] = useState(false);

  const deviceDetailPageLink = `/device/${encodeURIComponent(
    device?.rowId ?? ""
  )}`;

  const { data: annotationsData, loading: annotationsDataLoading } =
    useGetAnnotations({
      serviceId: deviceModel?.serviceId ?? "",
    });

  useEffect(() => {
    if (!annotationsDataLoading && annotationsData?.getAnnotations.items) {
      setAllowStartTlmModel(annotationsData?.getAnnotations.items.length > 0);
    }
  }, [annotationsData?.getAnnotations.items]);

  const { changeModelStatusAction, deleteModelAction, serviceLoading } =
    useServiceActions({
      device,
      deviceModel,
    });

  const onChangeModelStatusHandler = (): void => {
    changeModelStatusAction(isModelRunning).then((): void => {
      setIsModelRunning(!isModelRunning);
    });
  };

  const onDeleteModelHandler = (): void => {
    deleteModelAction(deviceDetailPageLink);
  };

  const isLeakOrLiquidLeakRunning = runningLeakModels.length > 0;

  const getTooltipTitle = (): string => {
    if (
      isLeakOrLiquidLeakRunning &&
      deviceModel?.serviceName.includes(ServiceTypeEnum.LEAK) &&
      !deviceModel?.isRunning
    ) {
      return `Leak and Liquid Leak cannot run at the same time. Please disable ${runningLeakModels[0]?.serviceName} model before starting ${deviceModel?.serviceName} model.`;
    }

    if (
      deviceModel?.serviceName === ServiceTypeEnum.TANK_LEVEL &&
      !allowStartTlmModel
    ) {
      return "TLM model can't be started if Tanks have not been defined";
    }

    if (!device.deviceData.hasPanTilt) {
      return "";
    }

    if (deviceModel?.isRunning) {
      return "Disable model. Zones will not be monitored while disabled";
    }

    if (!deviceModel?.isRunning) {
      return "Enable model. In case you enable the model, it will be monitored";
    }

    return "";
  };

  const tooltipTitle = getTooltipTitle();

  const statusToggleDisabled =
    (deviceModel?.serviceName === ServiceTypeEnum.TANK_LEVEL &&
      !allowStartTlmModel) ||
    (isLeakOrLiquidLeakRunning &&
      !isModelRunning &&
      deviceModel?.serviceName.includes(ServiceTypeEnum.LEAK));

  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        gap: "1em",
        alignItems: "center",
        pointerEvents: serviceLoading.commonLoading ? "none" : "auto",
        opacity: serviceLoading.commonLoading ? 0.6 : 1,
      }}
    >
      <IconButton disableRipple onClick={onDeleteModelHandler}>
        <DeleteIconButton />
      </IconButton>

      <FormGroup>
        <Tooltip title={tooltipTitle} arrow placement="top">
          <span>
            <StatusToggle
              loading={serviceLoading.updateStatusLoading}
              disabled={statusToggleDisabled}
              checked={!!isModelRunning}
              handleChange={onChangeModelStatusHandler}
            />
          </span>
        </Tooltip>
      </FormGroup>
    </Box>
  );
};

export default React.memo(ModelActionControls);
