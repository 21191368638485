import { useMemo } from "react";

import { DeviceData } from "../../../API";
import DeviceDynamicDropDownInput from "../components/DynamicInputs/DeviceDynamicDropDownInput";
import DeviceDynamicTextInput from "../components/DynamicInputs/DeviceDynamicTextInput";
import {
  TDeviceConfig,
  useDeviceFormValidationState,
} from "../variables/devices";

// TODO: fields are supposed to be queried from DDB, this is the idea of a dynamic field to create them in DDB and query them

interface DeviceDynamicFieldsProps {
  hasPanTilt: boolean;
  hasZoom: boolean;
  deviceConfig: TDeviceConfig;
  setDeviceConfig: (
    name: Exclude<keyof DeviceData, "__typename">,
    value: string
  ) => void;
}

const DeviceDynamicFields = ({
  hasPanTilt,
  hasZoom,
  deviceConfig,
  setDeviceConfig,
}: DeviceDynamicFieldsProps): JSX.Element => {
  const validation = useDeviceFormValidationState();

  const fields = useMemo(() => {
    const fieldsArray: Array<
      | { field: string; label: string; type: "STRING" }
      | {
          field: string;
          label: string;
          type: "DROPDOWN";
          entries: Array<{ value: boolean; display: string }>;
        }
    > = [
      {
        field: "sourceVideo",
        label: "Source Video",
        type: "STRING",
      },
      {
        field: "rtspHost",
        label: "RTSP Client",
        type: "STRING",
      },
    ];

    if (hasZoom) {
      fieldsArray.push({
        field: "hasZoom",
        label: "Zoom",
        type: "DROPDOWN",
        entries: [
          {
            value: true,
            display: "Yes",
          },
          {
            value: false,
            display: "No",
          },
        ],
      });
    }

    if (hasPanTilt)
      fieldsArray.push(
        {
          field: "hasPanTilt",
          label: "Pan Tilt",
          type: "DROPDOWN",
          entries: [
            {
              value: true,
              display: "Yes",
            },
            {
              value: false,
              display: "No",
            },
          ],
        },
        {
          field: "panTiltIP",
          label: "Pan Tilt IP",
          type: "STRING",
        }
      );

    return fieldsArray;
  }, [hasPanTilt]);

  return (
    <>
      {fields.map((fieldDatum): JSX.Element | null => {
        switch (fieldDatum?.type) {
          case "STRING": {
            return (
              <DeviceDynamicTextInput
                key={fieldDatum.field}
                hasError={validation?.[fieldDatum.field]?.hasError || false}
                helperText={validation?.[fieldDatum.field]?.errorMessage || ""}
                field={
                  fieldDatum.field as Exclude<keyof DeviceData, "__typename">
                }
                label={fieldDatum.label}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
                disableInput={fieldDatum.field === "rtspHost"}
              />
            );
          }
          case "DROPDOWN":
            return (
              <DeviceDynamicDropDownInput
                key={fieldDatum.field}
                field={
                  fieldDatum.field as Exclude<keyof DeviceData, "__typename">
                }
                label={fieldDatum.label}
                entries={fieldDatum.entries}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
};

export default DeviceDynamicFields;
