import { useSubscription } from "@apollo/client";

import type { ListenToDeviceDataSubscription } from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { LISTEN_TO_DEVICE_DATA } from "../../../common/operations/subscriptions";
import { errorNotification } from "../../../common/variables/notification";

interface UseListenToDeviceDataChangeParams {
  currentDeviceId: string;
}

export const useListenToDeviceDataChange = ({
  currentDeviceId,
}: UseListenToDeviceDataChangeParams) => {
  const customerId = useCustomerIdGuard();

  const { data, loading, error } =
    useSubscription<ListenToDeviceDataSubscription>(LISTEN_TO_DEVICE_DATA, {
      fetchPolicy: "no-cache",
      variables: {
        customerId,
      },
      shouldResubscribe: true,
      onData: ({ client, data }) => {
        const device = data?.data?.listenToDeviceData;

        if (device && device.id === currentDeviceId) {
          const deviceId = `Device:${device.locationId}#${device.id}`;

          client.cache.writeQuery({
            query: LISTEN_TO_DEVICE_DATA,
            data: data?.data,
            variables: { id: deviceId },
          });

          // const message = `Device ${device.name} is updated with new position`;
          // successNotification(message);
        }
      },
      onError: error => {
        errorNotification("Subscription error: " + error?.message);
      },
    });

  if (error) {
    console.error("Subscription error:", error);
  }

  return {
    data,
    loading,
    error,
    deviceData: data?.listenToDeviceData?.deviceData,
  };
};
