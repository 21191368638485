export const PTZ_MIN_WIDTH = "74px";

export const PTZ_MAX_WIDTH = "78px";

// 3 digits, 1 decimal
export const PAN_PATTERN = /^(?!-\.|\.-|\.)(?:-?\d{0,3}(?:\.\d{0,1})?|)$/;

// 2 digits, 1 decimal
export const TILT_PATTERN = /^(?!-\.|\.-|\.)(?:-?\d{0,2}(?:\.\d{0,1})?|)$/;

export const PAN_MIN = -180;

export const PAN_MAX = 180;

export const TILT_MIN = -90;

export const TILT_MAX = 35;

export const ZOOM_MIN = 0;

export const ZOOM_MAX = 100;

export const PAN_TILT_STEP = 0.1;

export const PAN_DEFAULT = "0";

export const TILT_DEFAULT = "0";

export const ZOOM_DEFAULT = "0";
