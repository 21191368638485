import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import AllLocationsFilterDropdownContainer from "../../../../common/components/locations/AllLocationsFilterDropdownContainer";
import SearchInput from "../../../../common/components/search/SearchInput";
import ServiceTypeSelect from "../../../../common/components/select/ServiceTypeSelect";
import TabPanel from "../../../../common/components/tabs/TabPanel";
import { useAuthenticatedUser } from "../../../../common/hooks/useAuthenticatedUser";
import { useGetCustomer } from "../../../../common/hooks/useGetCustomer";
import { AutocompleteOptionType } from "../../../../common/models/autocomplete";
import { RoleEnum, RouteEnum } from "../../../../common/models/enums";
import { selectedCustomerDataVariable } from "../../../../common/variables/selectedCustomerData";
import {
  AlertManagementTabsEnum,
  AlertManagementTabsList,
} from "../../../../common/variables/tabsSettings";
import AlertManagementGateGuardTab from "./AlertManagementGateGuardTab";
import AlertsTable from "./components/AlertsTable";
import NoAlertsFound from "./components/NoAlertsFound";
import { useAlertsTableColumns } from "./hooks/useAlertsTableColumns";
import { useAlertsTableRow } from "./hooks/useAlertsTableRow";
import { useGetAddNewAlertPath } from "./hooks/useGetAddNewAlertPath";
import { useUserAlertsTableRow } from "./hooks/useUserAlertsTableRow";

export const ALERTS_ADMIN_ACCESS = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
];

const ALERTS_NON_ADMIN_ACCESS = [
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.PARTNER_USER,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
];

const AlertManagementTab = () => {
  const { role } = useAuthenticatedUser();

  const { tabId = AlertManagementTabsEnum.CUSTOMER } = useParams<{
    tabId: AlertManagementTabsEnum;
  }>();

  const { customer } = useGetCustomer();

  const navigate = useNavigate();

  const { state } = useLocation();

  const addNewAlertPath = useGetAddNewAlertPath();

  useEffect(() => {
    if (customer) {
      selectedCustomerDataVariable(customer);
    }
  }, [customer]);

  const [selectedTab, setSelectedTab] = useState<AlertManagementTabsEnum>(
    state?.alertTab ?? tabId
  );

  const [searchValue, setSearchValue] = useState("");

  const [selectedLocations, setSelectedLocations] = useState<
    AutocompleteOptionType[] | null
  >([]);

  const [selectedModel, setSelectedModel] = useState("");

  useEffect((): void => {
    if (role !== "") {
      const allowedTabs = AlertManagementTabsList.filter((tab): boolean => {
        return tab.allowedRoles.includes(role);
      });

      const isURLTabAllowed = allowedTabs.find(tab => tab.id === selectedTab);

      setSelectedTab(isURLTabAllowed?.id ?? allowedTabs[0].id);
    }
  }, [role]);

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: AlertManagementTabsEnum
  ): void => {
    setSelectedTab(newValue);

    const isAdminUser = ALERTS_ADMIN_ACCESS.includes(role);

    const tab = isAdminUser
      ? `${RouteEnum.CustomerSettings}/${RouteEnum.AlertManagement}`
      : `${RouteEnum.SensorySystemSettings}/${RouteEnum.AlertManagement}`;

    navigate(tab, {
      state: { alertTab: newValue },
    });
  };

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    setSelectedLocations([locationValue]);
  };

  const handleTagLocationsChange = (
    tagLocations: AutocompleteOptionType[] | null
  ): void => {
    setSelectedLocations(tagLocations);
  };

  const handleSearchValueChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchValue(event.target.value);
  };

  const clearSearchValue = (): void => {
    setSearchValue("");
  };

  const setSelectedService = (
    _event: SyntheticEvent<Element, Event>,
    newValue: string | null
  ): void => {
    setSelectedModel(newValue ?? "");
  };

  const hasAdminAccess = ALERTS_ADMIN_ACCESS.includes(role);

  let component = <></>;
  let customerAlerts = 0;
  let locationAlerts = 0;
  let modelAlerts = 0;
  let rowsLength = 0;

  if (hasAdminAccess) {
    const {
      rows,
      customerAlertsCount,
      locationAlertsCount,
      modelAlertsCount,
      loading,
    } = useAlertsTableRow(
      selectedTab,
      selectedLocations,
      searchValue,
      selectedModel
    );
    const { columns } = useAlertsTableColumns(
      hasAdminAccess,
      selectedTab,
      searchValue
    );

    customerAlerts = customerAlertsCount;

    locationAlerts = locationAlertsCount;

    modelAlerts = modelAlertsCount;

    rowsLength = rows.length;

    component = <AlertsTable rows={rows} columns={columns} loading={loading} />;

    if (
      (searchValue || selectedLocations?.length || selectedModel) &&
      rowsLength === 0
    ) {
      component = <NoAlertsFound />;
    }
  }

  const hasNonAdminAccess = ALERTS_NON_ADMIN_ACCESS.includes(role);

  if (hasNonAdminAccess) {
    const {
      rows,
      customerAlertsCount,
      locationAlertsCount,
      modelAlertsCount,
      loading,
    } = useUserAlertsTableRow(
      selectedTab,
      selectedLocations,
      searchValue,
      selectedModel
    );

    const { columns } = useAlertsTableColumns(
      hasAdminAccess,
      selectedTab,
      searchValue
    );

    customerAlerts = customerAlertsCount;

    locationAlerts = locationAlertsCount;

    modelAlerts = modelAlertsCount;

    rowsLength = rows.length;

    component = <AlertsTable rows={rows} columns={columns} loading={loading} />;

    if (
      (searchValue || selectedLocations?.length || selectedModel) &&
      rowsLength === 0
    ) {
      component = <NoAlertsFound />;
    }
  }

  const allowedTabs = AlertManagementTabsList.filter((tab): boolean => {
    return tab.allowedRoles.includes(role);
  });

  const tabs = allowedTabs.map((tab): JSX.Element => {
    let count = 0;

    if (tab.id === AlertManagementTabsEnum.CUSTOMER) {
      count = customerAlerts;
    }

    if (tab.id === AlertManagementTabsEnum.LOCATION) {
      count = locationAlerts;
    }

    if (tab.id === AlertManagementTabsEnum.MODEL) {
      count = modelAlerts;
    }

    return (
      <Tab
        key={tab.id}
        sx={{
          textTransform: "none",
          minWidth: "auto",
          padding: "6px 12px",
          height: "32px",
          minHeight: "32px",
          fontWeight: selectedTab === tab.id ? "bold" : "normal",
          color:
            selectedTab === tab.id
              ? "otherBackgroundLight.main"
              : "text.secondary",
          backgroundColor:
            selectedTab === tab.id ? "text.secondary" : "inherit",
          borderRadius: "18px",
          "&.Mui-selected": {
            backgroundColor: "#384b65",
            color: "white",
            borderRadius: "24px",
          },
        }}
        label={`${tab.label} ${
          tab.id !== AlertManagementTabsEnum.GATE_GUARD ? `(${count})` : ""
        }`}
        value={tab.id}
      />
    );
  });

  const handleAddNewAlertRedirect = (): void => {
    navigate(addNewAlertPath);
  };

  const showFilters = !(
    rowsLength === 0 &&
    !searchValue &&
    !selectedLocations?.length &&
    !selectedModel
  );

  return (
    <Box padding="0" marginBottom="1em" width="100%">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "1em",
        }}
      >
        <Typography variant="h5">Alert Management</Typography>
      </Box>

      <Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "1em",
          }}
        >
          <Tabs
            onChange={handleTabChange}
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {tabs}
          </Tabs>
        </Box>

        {showFilters && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "1.5em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <Box sx={{ maxWidth: "280px", minWidth: "200px" }}>
                <SearchInput
                  sx={{
                    padding: "0.5em -1em",
                  }}
                  placeholder="Search by alert name"
                  size="small"
                  variant="outlined"
                  value={searchValue}
                  searchValue={searchValue}
                  handleSearchValueChange={handleSearchValueChange}
                  clearSearchValue={clearSearchValue}
                />
              </Box>
              {selectedTab !== AlertManagementTabsEnum.CUSTOMER && (
                <Box sx={{ minWidth: "280px" }}>
                  <AllLocationsFilterDropdownContainer
                    setLocation={handleLocationChange}
                    setTagLocations={handleTagLocationsChange}
                  />
                </Box>
              )}
              {selectedTab === AlertManagementTabsEnum.MODEL && (
                <Box sx={{ minWidth: "160px" }}>
                  <ServiceTypeSelect
                    onChange={setSelectedService}
                    value={selectedModel}
                    AutocompleteProps={{
                      size: "small",
                    }}
                  />
                </Box>
              )}
            </Box>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddOutlinedIcon />}
              sx={{
                whiteSpace: "nowrap",
                marginLeft: "auto",
              }}
              onClick={handleAddNewAlertRedirect}
            >
              New alert
            </Button>
          </Box>
        )}

        <TabPanel value={selectedTab} index={AlertManagementTabsEnum.CUSTOMER}>
          {component}
        </TabPanel>

        <TabPanel value={selectedTab} index={AlertManagementTabsEnum.LOCATION}>
          {component}
        </TabPanel>

        <TabPanel value={selectedTab} index={AlertManagementTabsEnum.MODEL}>
          {component}
        </TabPanel>

        <TabPanel
          value={selectedTab}
          index={AlertManagementTabsEnum.GATE_GUARD}
        >
          <AlertManagementGateGuardTab />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default AlertManagementTab;
