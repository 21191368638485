import { gql } from "@apollo/client";

import {
  getAllAlerts,
  getAllVideosByGroup,
  getAllWildCardsForSubscriptionEntity,
  getAnnotations,
  getCategoriesByCustomer,
  getCategoryById,
  getClientById,
  getClientsByCustomer,
  getCustomer,
  getCustomerDTObjects,
  getCustomerHealth,
  getCustomers,
  getCustomerCounters,
  getDTCustomerProjects,
  getDTFile,
  getDTFiles,
  getDTObjects,
  getDTProject,
  getDTProjects,
  getDTProjectsByUsedDTObject,
  getDeviceById,
  getDeviceMakeById,
  getDeviceMakeByModel,
  getDevices,
  getDevicesByLocation,
  getDevicesByNodeId,
  getDevicesMakes,
  getEventsPendingHumanValidation,
  getForm,
  getGroupByClientId,
  getGroupsByClient,
  getHumanValidatedEventsForAuditing,
  getLicensePlate,
  getLicensePlatesByCustomer,
  getLocationById,
  getLocations,
  getLocationsByTag,
  getNodeById,
  getNodes,
  getNodesByCustomer,
  getProfile,
  getRoles,
  getSendEmail,
  getServiceById,
  getServices,
  getServicesByDevice,
  getServicesByLocation,
  getShadowNode,
  getStatisticsData,
  getTagsByCustomer,
  getTimestreamData,
  getUserAlerts,
  getUserById,
  getZone,
  getZones,
  getZonesByDevice,
  getZonesThresholdsByCustomer,
  getZoneThresholdById,
  getHlsUrl,
  getSignedUrlAction,
} from "../../graphql/queries";

export const GET_SERVICES_BY_DEVICE = gql`
  ${getServicesByDevice}
`;

export const GET_CUSTOMERS = gql`
  ${getCustomers}
`;

export const GET_CUSTOMER_COUNTERS = gql`
  ${getCustomerCounters}
`;

export const GET_EVENTS_PENDING_HV = gql`
  ${getEventsPendingHumanValidation}
`;

export const GET_EVENTS_FOR_AUDITING_HV = gql`
  ${getHumanValidatedEventsForAuditing}
`;

export const GET_LOCATIONS = gql`
  ${getLocations}
`;

export const GET_LOCATIONS_BY_TAG = gql`
  ${getLocationsByTag}
`;

export const GET_PROFILE = gql`
  ${getProfile}
`;

export const GET_SERVICES = gql`
  ${getServices}
`;

export const GET_SERVICES_BY_LOCATION = gql`
  ${getServicesByLocation}
`;

export const GET_CUSTOMER_HEALTH = gql`
  ${getCustomerHealth}
`;

export const GET_FORM = gql`
  ${getForm}
`;

export const GET_DEVICE_BY_ID = gql`
  ${getDeviceById}
`;

export const GET_DEVICE_MAKE_BY_ID = gql`
  ${getDeviceMakeById}
`;

export const GET_DEVICE_MAKE_BY_MODEL = gql`
  ${getDeviceMakeByModel}
`;

export const GET_DEVICES = gql`
  ${getDevices}
`;

export const GET_LOCATION_BY_ID = gql`
  ${getLocationById}
`;

export const GET_DEVICES_BY_LOCATION = gql`
  ${getDevicesByLocation}
`;

export const GET_DEVICES_BY_NODE_ID = gql`
  ${getDevicesByNodeId}
`;

export const GET_DEVICES_MAKES = gql`
  ${getDevicesMakes}
`;

export const GET_NODES = gql`
  ${getNodes}
`;

export const GET_SIGNED_URL_ACTION = gql`
  ${getSignedUrlAction}
`;

export const GET_NODE_BY_ID = gql`
  ${getNodeById}
`;

export const GET_NODES_BY_CUSTOMER = gql`
  ${getNodesByCustomer}
`;

export const GET_TIMESTREAM_DATA = gql`
  ${getTimestreamData}
`;

export const GET_STATISTICS_DATA = gql`
  ${getStatisticsData}
`;

export const GET_ZONES = gql`
  ${getZones}
`;

export const GET_ZONE = gql`
  ${getZone}
`;

export const GET_ZONES_BY_DEVICE = gql`
  ${getZonesByDevice}
`;

export const GET_ANNOTATIONS = gql`
  ${getAnnotations}
`;

export const GET_ALL_WILDCARD_ALERT_EMAIL_SUBSCRIPTIONS = gql`
  ${getAllWildCardsForSubscriptionEntity}
`;

export const GET_SHADOW = gql`
  ${getShadowNode}
`;

export const GET_DT_PROJECTS = gql`
  ${getDTProjects}
`;

export const GET_DT_CUSTOMER_PROJECTS = gql`
  ${getDTCustomerProjects}
`;

export const GET_DT_PROJECTS_BY_USED_DT_OBJECT = gql`
  ${getDTProjectsByUsedDTObject}
`;

export const GET_DT_PROJECT = gql`
  ${getDTProject}
`;

export const GET_DT_OBJECTS = gql`
  ${getDTObjects}
`;

export const GET_CUSTOMER_DT_OBJECTS = gql`
  ${getCustomerDTObjects}
`;

export const GET_ROLES = gql`
  ${getRoles}
`;

export const GET_ALL_ALERTS = gql`
  ${getAllAlerts}
`;

export const GET_USER_ALERTS = gql`
  ${getUserAlerts}
`;

export const GET_SEND_EMAIL = gql`
  ${getSendEmail}
`;

export const GET_DT_FILE = gql`
  ${getDTFile}
`;

export const GET_DT_FILES = gql`
  ${getDTFiles}
`;

export const GET_CUSTOMER = gql`
  ${getCustomer}
`;

export const GET_CLIENTS_BY_CUSTOMER = gql`
  ${getClientsByCustomer}
`;

export const GET_ZONES_THRESHOLDS_BY_CUSTOMER = gql`
  ${getZonesThresholdsByCustomer}
`;

export const GET_CATEGORIES_BY_CUSTOMER = gql`
  ${getCategoriesByCustomer}
`;

export const GET_ALL_VIDEOS_BY_GROUP = gql`
  ${getAllVideosByGroup}
`;

export const GET_CATEGORY_BY_ID = gql`
  ${getCategoryById}
`;

export const GET_CLIENT_BY_ID = gql`
  ${getClientById}
`;

export const GET_ZONE_THRESHOLD_BY_ID = gql`
  ${getZoneThresholdById}
`;

export const GET_GROUP_BY_CLIENT_ID = gql`
  ${getGroupByClientId}
`;

export const GET_GROUPS_BY_CLIENT = gql`
  ${getGroupsByClient}
`;

export const GET_LICENSE_PLATE = gql`
  ${getLicensePlate}
`;

export const GET_LICENSE_PLATES_BY_CUSTOMER = gql`
  ${getLicensePlatesByCustomer}
`;

export const GET_SERVICE_BY_ID = gql`
  ${getServiceById}
`;

export const GET_CURRENT_USER = gql`
  ${getUserById}
`;

export const GET_TAGS_BY_CUSTOMER = gql`
  ${getTagsByCustomer}
`;

export const GET_HLS_URL = gql`
  ${getHlsUrl}
`;
