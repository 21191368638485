import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { circleArrowSvgIconStyles } from "./CircleSvgIcon.styles";

export interface CircleArrowProps extends SvgIconProps {
  disabled?: boolean;
}

const CircleArrowUp = (props: CircleArrowProps): JSX.Element => {
  return (
    <SvgIcon
      sx={{
        ...circleArrowSvgIconStyles.sx,
        pointerEvents: props.disabled ? "none" : "auto",
        cursor: props.disabled ? "not-allowed" : "pointer",
        opacity: props.disabled ? 0.5 : 1,
      }}
      fill="none"
      {...props}
    >
      <circle cx="12" cy="12" r="10" strokeWidth="1.5" />
      <path
        d="M15 13.5L12 10.5L9 13.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CircleArrowUp;
