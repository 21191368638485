import EventsTableServer from "../common/EventsTableServer";
import { useGetHardHatEventsTableColumns } from "./hooks/useGetHardHatEventsTableColumns";
import { useGetHardHatEventsTableRows } from "./hooks/useGetHardHatEventsTableRows";

const HardHatEventsServerTableContainer = (): JSX.Element => {
  const { columns } = useGetHardHatEventsTableColumns();

  const {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetHardHatEventsTableRows();

  return (
    <EventsTableServer
      mediaOutputClick
      columns={columns}
      rows={rows}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      hasNextPage={hasNextPage}
      isActivePageChange={isActivePageChange}
      paginationModel={paginationModel}
    />
  );
};

export default HardHatEventsServerTableContainer;
