import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import TableCellTruncated from "../../../../../../common/components/table/TableCellTruncated";
import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";
import { ITlEventsTableRow } from "../../common/events.models";
import EventSnapshotTableCell from "../../common/EventSnapshotTableCell";

export const useGetTlmTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef<ITlEventsTableRow>[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: "mediaOutput",
      headerName: "Snapshot",
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: EventSnapshotTableCell,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (loactionId: string): string => {
        const locationName = getCachedLocationName(loactionId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row?.location);

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={locationName}
            text={locationName}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    // {
    //   field: "data",
    //   headerName: "Tanks",
    //   flex: 1,
    //   minWidth: 200,
    //   disableColumnMenu: true,
    //   hideable: false,
    //   sortable: false,
    //   renderCell: (params): JSX.Element | null => {
    //     if (!params?.row?.tanksData) {
    //       return null;
    //     }

    //     try {
    //       const tanks = JSON.parse(params?.row?.tanksData);
    //       console.log("tanks", tanks);
    //     } catch (error) {
    //       console.log("Error while parsing tanks:", (error as Error).message);
    //     }

    //     return (
    //       <Typography variant="body2">
    //         {JSON.stringify(params.row.tanksData)}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row.cameraId}
            text={params?.row.cameraId}
            TypographyProps={{
              variant: "body2Bold",
            }}
          />
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (timestamp: string): string => {
        return formatTimestamp(timestamp);
      },
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={formatTimestamp(params?.row.timestamp)}
            text={formatTimestamp(params?.row.timestamp)}
            TypographyProps={{
              variant: "body2",
            }}
          />
        ) : null,
    },
    {
      // Need to keep this column hidden and purely for exporting to CSV
      field: "tanksData",
      headerName: "",
      flex: 1,
      maxWidth: 1,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const tankNumbers = params?.row.tanksData;

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={tankNumbers}
            text={tankNumbers}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
  ];

  return { columns };
};
