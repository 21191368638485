import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  IFireEventsTableRow,
  IParsedFireEvent,
} from "../../common/events.models";
import { useGetTimestreamDataToExport } from "../../common/hooks/useGetTimestreamDataToExport";

export const useGetFireEventsToExport = () => {
  const { fetchTimestreamDataToExport, loading } =
    useGetTimestreamDataToExport();

  const getCsvDataToExport = async (): Promise<IFireEventsTableRow[]> => {
    const rows = await fetchTimestreamDataToExport(mapData);

    return rows;
  };

  const mapData = (parsedData: IParsedFireEvent[]): IFireEventsTableRow[] => {
    const mappedData: IFireEventsTableRow[] =
      parsedData?.map((item: IParsedFireEvent, index: number) => ({
        rowIndex: index + 1,
        rowId: item?.id ?? "",
        location: item?.location_id ?? "",
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        keyFrame: item?.keyFrame ?? "",
        mediaOutput: item?.mediaOutput ?? "",
        cameraId: item?.camera_id ?? "",
        confidence: item?.confidence ?? "",
      })) ?? [];

    return mappedData;
  };

  return { loading, getCsvDataToExport };
};
