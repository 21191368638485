import { FC, useEffect, useState } from "react";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { Annotation, Zone } from "../../../API";
import ViewKeyframeWithAnnotations from "../../../common/components/canvas/ViewKeyframeWithAnnotations";
import DeleteIconButton from "../../../common/components/icons/DeleteIconButtonWithText";
import EditIconButton from "../../../common/components/icons/EditIconButton";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../../common/components/item/ItemDetailHeader";
import ItemDetailTable from "../../../common/components/item/ItemDetailTable";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import ZoneStatusToggle from "../../../common/components/zones/ZoneStatusToggle";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import AnnotationContainer from "../annotations/AnnotationContainer";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { useGetAnnotations } from "../hooks/useGetAnnotations";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import { useGetZone } from "../hooks/useGetZone";
import { usePublishNode } from "../hooks/usePublishNode";
import { useListenToDeleteZone } from "../zones-setup-page/hooks/useListenToDeleteZone";
import { useListenToUpdateZone } from "../zones-setup-page/hooks/useListenToUpdateZone";
import DeleteZoneContainer from "./DeleteZoneContainer";

const ZoneOverviewPage: FC = (): JSX.Element => {
  const theme = useTheme();
  const param = useParams<{ deviceId: string; zoneId: string }>();
  const [searchParams] = useSearchParams();

  const { fetchDevice } = useGetDeviceByUrl();

  const { publishNode } = usePublishNode();

  const navigate = useNavigate();

  const { deviceId = "", zoneId = "" } = param;

  const location = useLocation();

  const [zoneData, setZoneData] = useState<Zone>();

  const { data: updatedZoneData } = useListenToUpdateZone(zoneId);
  const { data: deletedZoneData } = useListenToDeleteZone(zoneId);
  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );

  const [modelType, setModelType] = useState(ServiceTypeEnum.LEAK);

  const {
    data: fetchZoneData,
    loading,
    getZone,
  } = useGetZone(deviceId, zoneId);

  const { data: fetchAnnotationsData, fetchAnnotations } = useGetAnnotations(
    {}
  );

  const [isKeyframeLoaded, setKeyframeLoaded] = useState(false);

  const deviceName = searchParams.get("deviceName") ?? "";

  const annotationsData = fetchAnnotationsData?.getAnnotations.items;

  useEffect((): void => {
    location.state = { deviceId: deviceId };

    fetchDevice().then((device): void => {
      if (device) {
        setDeviceDetail(device);
      }
    });
  }, []);

  useEffect(() => {
    getZone();
  }, []);

  useEffect(() => {
    if (deletedZoneData?.listenToDeleteZone?.id === zoneId) {
      navigate(`/device/${encodeURIComponent(deviceId)}`);
    }
  }, [deletedZoneData]);

  useEffect(() => {
    const serviceCode = zoneData?.serviceId?.substring(2, 5); // if 'S#hh_tp-tp-multi' it will be 'hh_'

    switch (true) {
      case serviceCode?.includes("gl"):
        setModelType(ServiceTypeEnum.LEAK);
        break;

      case serviceCode?.includes("ll"):
        setModelType(ServiceTypeEnum.LIQUID_LEAK);
        break;

      case serviceCode?.includes("hh"):
        setModelType(ServiceTypeEnum.HARD_HAT);
        break;

      case serviceCode?.includes("tlm"):
        setModelType(ServiceTypeEnum.TANK_LEVEL);
        break;

      default:
        break;
    }
  }, [zoneData]);

  useEffect((): void => {
    if (fetchZoneData) {
      setZoneData(fetchZoneData.getZone);

      fetchAnnotations({
        deviceId: fetchZoneData.getZone.deviceId,
        serviceId: fetchZoneData.getZone.serviceId,
        zoneId,
      });
    }
  }, [fetchZoneData, loading]);

  useEffect(() => {
    if (updatedZoneData?.listenToUpdateZone) {
      setZoneData(updatedZoneData.listenToUpdateZone);
    }
  }, [updatedZoneData]);

  if (!zoneData || !fetchAnnotationsData) {
    return <FullPageLoader />;
  }

  const goToEditHandler = async () => {
    navigate(
      `${
        location.pathname
      }/edit-zone?deviceName=${deviceName}&nodeId=${encodeURIComponent(
        zoneData.nodeId
      )}`
    );
  };

  const nodeId = zoneData?.nodeId;

  const captureKeyframe = (): void => {
    const deviceName = zoneData?.deviceId.split("DE#")[1];

    publishNode({
      message: JSON.stringify({
        TARGET: "CAMERA_MANAGER",
        ACTION: "SAVE_SINGLE_ZONE_IMAGE",
        device_name: deviceName,
        pan: zoneData.pan,
        tilt: zoneData.tilt,
        zoom: zoneData.zoom,
        s3_key: zoneData.keyFrame,
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification("A new keyframe is captured");
        }
      })
      .catch((error): void => {
        errorNotification(
          "Something went wrong, a new keyframe isn't captured"
        );

        console.error(error);
      });
  };

  const zoneName = zoneData.name;

  const breadcrumbItems = zoneData
    ? [
        { label: "Devices", path: "/devices" },
        {
          label: deviceName,
          path: "/device/" + encodeURIComponent(deviceId),
        },
        { label: zoneName },
      ]
    : [];

  const tableData = zoneData ? [{ label: "ZONE NAME", value: zoneName }] : [];

  const zoneInfo = zoneData
    ? [
        {
          label: "PAN",
          value: zoneData.pan,
        },
        {
          label: "TILT",
          value: zoneData.tilt,
        },
        ...(deviceDetail?.deviceData?.hasZoom
          ? [{ label: "ZOOM", value: zoneData.zoom }]
          : []),
      ]
    : [];

  if (!zoneName || !fetchAnnotationsData) {
    return <FullPageLoader />;
  }

  const changeZoneOutsideStatus = () => {
    setZoneData(prevData => ({
      ...zoneData,
      status: !prevData?.status,
    }));
  };

  const isCaptureKeyFramesDisabled =
    !zoneData?.status || !zoneData?.keyFrame || !deviceDetail?.isOnline;

  let tooltipTitle =
    !zoneData.status && isCaptureKeyFramesDisabled
      ? "New key image capturing will be available when zone is enabled"
      : "The zone will be stopped while the key image is capturing";

  if (!deviceDetail?.isOnline) {
    tooltipTitle =
      "New key image capturing will be available when device is online";
  }

  return (
    <>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <ItemDetailHeader name={zoneName}>
        <ZoneStatusToggle
          params={zoneData}
          setOutsideState={changeZoneOutsideStatus}
        />
        <DeleteZoneContainer
          serviceId={zoneData.serviceId ?? ""}
          zoneDeviceId={zoneData.deviceId}
          zoneId={zoneId}
          zoneName={zoneName}
        >
          <DeleteIconButton />
        </DeleteZoneContainer>
      </ItemDetailHeader>

      <MainPaperWrapper>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            paddingRight: "1.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1Bold">Zone details</Typography>
            <EditIconButton onClick={goToEditHandler} />
          </Box>

          <ItemDetailTable data={tableData} />
          <Divider sx={{ margin: "1em 0", opacity: "0.5" }} />

          <ItemDetailTable data={zoneInfo} />

          <AnnotationContainer
            nodeId={zoneData.nodeId}
            setKeyframeLoaded={setKeyframeLoaded}
            isKeyframeLoaded={isKeyframeLoaded}
            serviceId={zoneData.serviceId ?? ""}
            serviceType={modelType}
            annotationsData={annotationsData as Annotation[]}
            deviceId={zoneData.deviceId}
            zoneId={zoneData.id}
            keyFrame={zoneData.keyFrame ?? ""}
          />
        </Box>

        {fetchAnnotationsData && (
          <Grid>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Tooltip title={tooltipTitle} placement="top">
                <Box sx={{ marginRight: "0.5em" }}>
                  <Button
                    disabled={isCaptureKeyFramesDisabled}
                    variant="text"
                    size="small"
                    startIcon={<PhotoCameraOutlinedIcon />}
                    color="inherit"
                    onClick={captureKeyframe}
                    sx={{
                      "&.Mui-disabled": {
                        backgroundColor: "transparent",
                        color: theme.palette.text.disabled,
                      },
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Capture a new key image
                  </Button>
                </Box>
              </Tooltip>
              <ViewKeyframeWithAnnotations
                annotationsData={annotationsData as Annotation[]}
                keyFrame={zoneData.keyFrame ?? ""}
                setKeyframeLoaded={setKeyframeLoaded}
                isKeyframeLoaded={isKeyframeLoaded}
              />
            </Stack>
          </Grid>
        )}
      </MainPaperWrapper>
    </>
  );
};

export default ZoneOverviewPage;
