import { useMemo } from "react";

import { useGetZonesByDevice } from "./useGetZonesByDevice";

export interface ZoneThresholdItem {
  id: string;
  name: string;
  value: string;
}

export interface IZonesByDeviceTableRow {
  id: string;
  rowId: string;
  deviceId: string;
  name: string;
  pan: string;
  tilt: string;
  zoom: string;
  status: boolean;
  keyFrame: string | null;
  nodeId: string;
  threshold: ZoneThresholdItem;
  alertData: any;
}

export interface IGetZonesByDeviceTableRow {
  rows: IZonesByDeviceTableRow[];
  loading: boolean;
}

export const useGetZonesByDeviceTableRow = (): IGetZonesByDeviceTableRow => {
  const { data, loading } = useGetZonesByDevice();

  const rows = useMemo(() => {
    const sortedRows = (data?.getZonesByDevice.items ?? []).map(item => {
      return {
        id: item?.id ?? "",
        rowId: item?.id ?? "",
        deviceId: item?.deviceId ?? "",
        name: item?.name ?? "",
        pan: item?.pan ?? "",
        tilt: item?.tilt ?? "",
        zoom: item?.zoom ?? "",
        status: item?.status ?? false,
        shouldNotify: item?.shouldNotify,
        keyFrame: item?.keyFrame ?? "",
        nodeId: item?.nodeId ?? "",
        alertData: JSON.parse(item?.alertData ?? "{}") ?? {},
        threshold: {
          id: item?.threshold?.id ?? "",
          name: item?.threshold?.name ?? "",
          value: item?.threshold?.value.toString() ?? "",
        },
      };
    });

    sortedRows.sort((a, b) => {
      const panA = parseFloat(a.pan);
      const panB = parseFloat(b.pan);

      if (panA === panB) {
        return parseFloat(a.tilt) - parseFloat(b.tilt);
      }

      return panA - panB;
    });

    return sortedRows;
  }, [data?.getZonesByDevice.items]);

  return { rows, loading };
};
