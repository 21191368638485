import { Stack, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import { UpdateCustomerInput } from "../../../API";
import StyledDialogNew from "../../../common/components/dialog/StyledDialogNew";
import StatusToggle from "../../../common/components/item/StatusToggle";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useUpdateCustomer } from "../../../common/hooks/useUpdateCustomer";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { convertTo12Hour } from "../../../common/helpers/convertTo12Hour";
import { convertToUTC } from "../../../common/helpers/convertToUTC";
import { INVALID_DATE_ERROR_MESSAGE } from "../../human-validator/constants";

const PauseAlertsDialog = (): JSX.Element => {
  const [alertSwitch, setAlertSwitch] = useState(false);
  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");

  const selectedCustomerId = useCustomerIdGuard();
  const { updateCustomer } = useUpdateCustomer();

  const [fromValue, setFromValue] = useState<string>("00:00");
  const [toValue, setToValue] = useState<string>("00:00");

  const handleAlertSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlertSwitch(event.target.checked);
  };

  const closeDialog = (): void => {
    setAlertSwitch(false);
  };

  const handleFromTimeChange = (newValue: dayjs.Dayjs | null) => {
    setFromValue(newValue ? newValue.format("HH:mm") : "");
  };

  const handleToTimeChange = (newValue: dayjs.Dayjs | null) => {
    setToValue(newValue ? newValue.format("HH:mm") : "");
  };

  const savePauseAlerts = (): void => {
    const fromValueUTC = convertToUTC(fromValue);
    const toValueUTC = convertToUTC(toValue);

    if (
      !fromValue ||
      !toValue ||
      fromValue === INVALID_DATE_ERROR_MESSAGE ||
      toValue === INVALID_DATE_ERROR_MESSAGE
    ) {
      errorNotification("Please provide valid start and end times.");

      return;
    }

    updateCustomer({
      id: selectedCustomerId,
      aggAlertingData: JSON.stringify({
        endTime: toValueUTC,
        isTimedAlertingEnabled: alertSwitch,
        startTime: fromValueUTC,
      }),
    } as UpdateCustomerInput)
      .then((response): void => {
        if (!response.errors) {
          successNotification(
            `Alerts are disabled for ${convertTo12Hour(
              fromValue
            )} - ${convertTo12Hour(toValue)} `
          );

          closeDialog();
        }
      })
      .catch((error: Error): void => {
        errorNotification("Something went wrong, alerts aren't disabled");
      });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={0.1}>
        <Typography sx={{ color: "#465870" }}>Disable alerts</Typography>
        <StatusToggle
          checked={alertSwitch}
          handleChange={handleAlertSwitchChange}
          label={""}
        />
      </Stack>
      <StyledDialogNew
        open={alertSwitch}
        title="Set time period when alerts shouldn't be received"
        label="This will disable alerts for every location and every user"
        submitButtonTitle="Disable alerts"
        onClose={closeDialog}
        SubmitButtonProps={{
          onSubmit: savePauseAlerts,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ marginTop: 5, marginBottom: 2 }}
          >
            <TimePicker
              label="From"
              value={dayjs(`2024-08-17T${fromValue}`)}
              onChange={handleFromTimeChange}
              onError={newError => {
                if (newError) {
                  setFromError("Please provide a valid time");
                } else {
                  setFromError("");
                }
              }}
              slotProps={{
                textField: {
                  helperText: fromError,
                },
              }}
            />
            <TimePicker
              label="To"
              value={dayjs(`2024-08-17T${toValue}`)}
              onChange={handleToTimeChange}
              onError={newError => {
                if (newError) {
                  setToError("Please provide a valid time");
                } else {
                  setToError("");
                }
              }}
              slotProps={{
                textField: {
                  helperText: toError,
                },
              }}
            />
          </Stack>
        </LocalizationProvider>
      </StyledDialogNew>
    </>
  );
};

export default PauseAlertsDialog;
