import { useEffect, useMemo, useState, type SyntheticEvent } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useNavigate } from "react-router-dom";
import client from "../../../../configs/apolloClient";
import { selectedLocationDevicesVariable } from "../../../../pages/devices/variables/devices";
import { selectedLocationNodesVariable } from "../../../../pages/nodes/variables/nodes";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { useGetCustomer } from "../../../hooks/useGetCustomer";
import { useGetCustomers } from "../../../hooks/useGetCustomers";
import type {
  AutocompleteOptionType,
  DisableClearableOptionsType,
  IDisableClearableOption,
} from "../../../models/autocomplete";
import { CC_ADMIN_ROLES, RouteEnum } from "../../../models/enums";
import { selectedCustomerVariable } from "../../../variables/selectedCustomer";
import { dateRangeVariable } from "../../datePicker/modelsDatePickers";

const defaultOption: IDisableClearableOption = {
  title: "Select customer",
  value: "",
};

const getSelectedCustomerFromStorage = (): IDisableClearableOption => {
  try {
    const selectedCustomer = localStorage.getItem("customer");

    if (selectedCustomer !== "undefined" && selectedCustomer) {
      return JSON.parse(selectedCustomer);
    }

    const defaultOptionJson = JSON.stringify(defaultOption);

    return JSON.parse(defaultOptionJson);
  } catch (error) {
    console.error(error);

    return defaultOption;
  }
};

enum AvailableApps {
  MARKETPLACE = "marketplace",
  SENSORY = "sensory",
  LUMINARY = "luminary",
  ENER_GPT = "energpt",
  LEAK_FINDER = "leakfinder",
}

interface ICustomerSelectProps {
  size?: "small" | "medium";
  isAuditor?: boolean;
}

const CustomerSelect = ({
  size = "medium",
  isAuditor,
}: ICustomerSelectProps): JSX.Element => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const { role } = useAuthenticatedUser();

  const customerId = useCustomerIdGuard();
  const { customers: customersFromDB, loading } = useGetCustomers();
  const { customer } = useGetCustomer();

  const isOnLeakFinder = pathname.startsWith(RouteEnum.LeakFinder);

  const customers = useMemo(() => {
    if (!customersFromDB?.items?.length) return [];

    const sensoryCustomers = customersFromDB.items.filter(customer => {
      const customerConfigurations = JSON.parse(
        customer?.configuration ?? "{}"
      );
      const availableApps = customerConfigurations.available_apps;

      if (isOnLeakFinder) {
        return availableApps.includes(AvailableApps.LEAK_FINDER);
      }

      return availableApps.includes(AvailableApps.SENSORY);
    });

    return sensoryCustomers;
  }, [isOnLeakFinder, customersFromDB]);

  const selectedCustomerFromStorageMemoized = useMemo(() => {
    if (!customers && loading) {
      return defaultOption;
    }

    return getSelectedCustomerFromStorage();
  }, [customers, loading]);

  const [selectedCustomer, setSelectedCustomer] =
    useState<IDisableClearableOption>(selectedCustomerFromStorageMemoized);

  useEffect((): void => {
    const selectedCustomerFromStorage = getSelectedCustomerFromStorage();

    if (selectedCustomerFromStorage?.value !== selectedCustomer?.value) {
      setSelectedCustomer(selectedCustomerFromStorage);
    }
  }, [customerId]);

  const handleOnChange = async (
    _event: SyntheticEvent<Element, Event>,
    optionValue: IDisableClearableOption
  ): Promise<void> => {
    if (!optionValue) {
      setSelectedCustomer(defaultOption);

      selectedCustomerVariable("");

      localStorage.setItem("customer", JSON.stringify(defaultOption));

      // clear previous customer data from cache
      await client.cache.reset();

      return;
    }

    const { value } = optionValue;

    localStorage.setItem("customer", JSON.stringify(optionValue));

    setSelectedCustomer(optionValue);

    selectedCustomerVariable(value);

    selectedLocationNodesVariable(null);

    selectedLocationDevicesVariable(null);

    dateRangeVariable(null);

    await client.cache.reset();

    const isDevicePage = pathname.includes("/device/");

    if (isDevicePage) {
      if (CC_ADMIN_ROLES.includes(role)) {
        navigate(RouteEnum.RecentActivities);
      } else {
        navigate(RouteEnum.SensoryRecentActivities);
      }
    }
  };

  const options =
    useMemo<DisableClearableOptionsType>((): IDisableClearableOption[] => {
      const customersOptions =
        customers?.map((customer): IDisableClearableOption => {
          return {
            title: customer?.name ?? "",
            value: customer?.id ?? "",
          };
        }) ?? [];

      return customersOptions;
    }, [customers]);

  const auditorOptions =
    useMemo<DisableClearableOptionsType>((): IDisableClearableOption[] => {
      if (!customer) return [];

      if (!customer?.auditCustomers) return [];

      let customersOptions = [];

      try {
        customersOptions = JSON.parse(customer?.auditCustomers).map(
          (customer: { name: string; id: string }): IDisableClearableOption => {
            return {
              title: customer?.name ?? "",
              value: customer?.id ?? "",
            };
          }
        );
      } catch (error) {
        console.error(error);
      }

      return customersOptions;
    }, [customer]);

  useEffect((): void => {
    if (!selectedCustomerFromStorageMemoized?.value && options?.length > 0) {
      setSelectedCustomer(options[1]);

      selectedCustomerVariable(options[1]?.value);

      localStorage.setItem("customer", JSON.stringify(options[1]));
    } else {
      setSelectedCustomer(selectedCustomerFromStorageMemoized);

      selectedCustomerVariable(selectedCustomerFromStorageMemoized?.value);
    }
  }, [options, selectedCustomerFromStorageMemoized?.value]);

  // TODO: if no reactive variable customer, return null
  // call modal component to prompt user to select customer

  return (
    <>
      <Autocomplete
        sx={{
          width: 240,
          ".MuiInputBase-root": {
            boxShadow: "none",
            height: "36px",
          },
        }}
        componentsProps={{
          paper: {
            elevation: 0,
            sx: {
              borderRadius: "0.5em",
              boxShadow: "none",
              border: theme =>
                `1px solid ${theme.palette.otherOutlineBorder.main}`,
            },
          },
        }}
        options={isAuditor ? auditorOptions : options}
        disableClearable
        size={size}
        getOptionLabel={(option: AutocompleteOptionType): string => {
          return option?.title ?? defaultOption.title;
        }}
        isOptionEqualToValue={(option, optionValue): boolean => {
          return option?.value === ""
            ? true
            : option?.value === optionValue?.value;
        }}
        value={selectedCustomer}
        onChange={handleOnChange}
        popupIcon={<ExpandMoreOutlinedIcon />}
        ListboxProps={{
          style: {
            maxHeight: "286px",
          },
        }}
        renderOption={(props, option): JSX.Element => {
          return (
            <Box {...props} component="li">
              {option?.title}
            </Box>
          );
        }}
        renderInput={(params): JSX.Element => (
          <TextField {...params} variant="outlined" size="small" fullWidth />
        )}
      />
    </>
  );
};

export default CustomerSelect;
