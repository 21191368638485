import { useEffect } from "react";
import FullPageLoader from "../common/components/item/FullPageLoader";
const MinervaPageRedirect = () => {
  useEffect(() => {
    window.location.replace("/webgl/index.html");
  }, []);

  return <FullPageLoader />;
};

export default MinervaPageRedirect;
