import { LeftMenuItems, RouteEnum } from "../../../../models/enums";
import { LeftSideBarItem } from "../../UnifiedLayout";

export const PartnerAdminItems: LeftSideBarItem[] = [
  {
    name: LeftMenuItems.DEVICES,
    path: RouteEnum.Devices,
  },
  {
    name: LeftMenuItems.AI_MANAGER,
    path: RouteEnum.Model,
  },
  {
    name: LeftMenuItems.NODES,
    path: RouteEnum.Nodes,
  },
  {
    name: LeftMenuItems.CUSTOMER_SETTINGS,
    path: RouteEnum.CustomerSettings,
    isSeparatorOfBottomSection: true,
  },
  {
    name: LeftMenuItems.RECENT_ACTIVITIES,
    path: RouteEnum.RecentActivities,
    showDivider: true,
  },
  {
    name: LeftMenuItems.LIVE_VIEW,
    path: RouteEnum.LiveView,
  },
  {
    name: LeftMenuItems.DEPLOYMENT_HEALTH,
    path: RouteEnum.DeploymentHealth,
  },
  {
    name: LeftMenuItems.STATISTICS,
    path: RouteEnum.Statistics,
  },
  {
    name: LeftMenuItems.SYSTEM_SETTINGS,
    path: RouteEnum.SystemSettings,
    showDivider: true,
  },
];
