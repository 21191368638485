import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AllowLeakFinder } from "../../../../../API";
import FullPageLoader from "../../../../../common/components/item/FullPageLoader";
import MainPaperWrapper from "../../../../../common/components/item/MainPaperWrapper";
import { useGetCustomer } from "../../../../../common/hooks/useGetCustomer";
import { useUpdateCustomer } from "../../../../../common/hooks/useUpdateCustomer";
import StyledLoadingButton from "../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

import EditCustomerForm, { ICustomerForm } from "./EditCustomerForm";

const EditCustomerPage = () => {
  const defaultValues = {
    id: "",
    domains: [""],
    usageLeakFinderCount: 0,
  };
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICustomerForm>({ defaultValues });
  const { customer, loading } = useGetCustomer();
  const { updateCustomer } = useUpdateCustomer();

  useEffect(() => {
    if (customer?.id) {
      const customerId = customer.id ?? "";
      const allowLeakFinder = customer.allowLeakFinder ?? AllowLeakFinder.NONE;
      const totalLeakFinderLimit = customer.totalLeakFinderLimit ?? 0;

      setValue("id", customerId.slice(2));

      setValue("allowLeakFinder", allowLeakFinder);

      setValue("totalLeakFinderLimit", totalLeakFinderLimit);
    }
  }, [customer]);

  const onSubmit: SubmitHandler<ICustomerForm> = formData => {
    if (!customer) return;

    updateCustomer({
      id: customer.id,
      allowLeakFinder: formData.allowLeakFinder,
      totalLeakFinderLimit: formData.totalLeakFinderLimit,
    })
      .then((response): void => {
        if (!response.errors) {
          successNotification("Changes are saved");
        }
      })
      .catch((error: Error): void => {
        errorNotification("Something went wrong, changes aren’t saved");

        console.error(error);
      });
  };
  const formData = watch();

  return loading ? (
    <FullPageLoader />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" sx={{ margin: "1em 0" }}>
        {`Edit ${customer?.name}`}
      </Typography>

      <MainPaperWrapper>
        <EditCustomerForm
          control={control}
          errors={errors}
          customer={formData}
          setValue={setValue}
        />
      </MainPaperWrapper>
      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save changes
        </StyledLoadingButton>
      </Stack>
    </form>
  );
};

export default EditCustomerPage;
