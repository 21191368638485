/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type addAlertEmailNotificationInput = {
  additionalEmails?: Array< string | null > | null,
  additionalPhoneNumbers?: Array< string | null > | null,
  alertName: string,
  alertType: string,
  createdBy: string,
  customService?: string | null,
  customerId: string,
  email?: string | null,
  jsonObject?: string | null,
  location?: string | null,
  model?: string | null,
  node?: string | null,
  notificationSettingsJson?: string | null,
  phoneNumber?: string | null,
  subscribedLevelEntity?: string | null,
  tag?: string | null,
};

export type Alert = {
  __typename: "Alert",
  alertName?: string | null,
  alertType?: string | null,
  createdBy: string,
  customService?: string | null,
  email?: string | null,
  id?: string | null,
  jsonObject?: string | null,
  location?: string | null,
  locationTag?: Array< string | null > | null,
  model?: string | null,
  node?: string | null,
  notificationSettingsJson?: string | null,
  phoneNumber?: string | null,
  subscribedLevelEntity?: string | null,
  tag?: string | null,
};

export type AddAlertSubscriptionInput = {
  email: string,
  entity: string,
};

export type AlertSubscription = {
  __typename: "AlertSubscription",
  email: string,
  entity?: string | null,
};

export type AddAlertWildCardSubscriptionInput = {
  email: string,
  entity: string,
  wildCard: string,
};

export type AlertWildCardSubscription = {
  __typename: "AlertWildCardSubscription",
  email: string,
  entity: string,
  wildCard: string,
};

export type AnnotationInput = {
  annotateTanks?: boolean | null,
  annotationType: AnnotationType,
  bottomHidden?: number | null,
  deviceId: string,
  equipmentType?: string | null,
  id?: string | null,
  name: string,
  nodeId: string,
  polygon?: string | null,
  serviceId: string,
  tankHeight?: number | null,
  tankNumber?: number | null,
  zoneId?: string | null,
};

export enum AnnotationType {
  COMBUSTION_AREA = "COMBUSTION_AREA",
  COMBUSTION_AREA_STATIC = "COMBUSTION_AREA_STATIC",
  EQUIPMENT_TAG = "EQUIPMENT_TAG",
  EXCLUSION_AREA = "EXCLUSION_AREA",
  EXCLUSION_AREA_STATIC = "EXCLUSION_AREA_STATIC",
  FOCUS_AREA_LF = "FOCUS_AREA_LF",
  INCLUSION_AREA = "INCLUSION_AREA",
  INCLUSION_AREA_STATIC = "INCLUSION_AREA_STATIC",
  SEARCH_AREA = "SEARCH_AREA",
  TANK_LEVEL = "TANK_LEVEL",
}


export type Annotation = {
  __typename: "Annotation",
  annotateTanks?: boolean | null,
  annotationType: AnnotationType,
  bottomHidden?: number | null,
  deviceId?: string | null,
  equipmentType?: string | null,
  id: string,
  name: string,
  polygon?: string | null,
  serviceId: string,
  tankHeight?: number | null,
  tankNumber?: number | null,
  zoneId?: string | null,
};

export type AddCategoryInput = {
  categoryName?: string | null,
  customerId?: string | null,
};

export type Category = {
  __typename: "Category",
  categoryName: string,
  id: string,
  isAlertEnabled: boolean,
};

export type AddClientInput = {
  clientName: string,
  customerId: string,
};

export type Client = {
  __typename: "Client",
  clientName: string,
  customerId: string,
  id: string,
};

export type AddClientGroupInput = {
  clientId: string,
  customerId: string,
  groupId: string,
  groupName: string,
};

export type Group = {
  __typename: "Group",
  clientId: string,
  createdAt: number,
  customerId: string,
  groupName: string,
  id: string,
};

export type DTObjectInput = {
  customerId?: string | null,
  filesInfo: Array< DTObjectFileInfoInput >,
  objectId: string,
  objectManufacturerName: string,
  objectName: string,
  objectType: string,
};

export type DTObjectFileInfoInput = {
  contentType: string,
  originalFileName: string,
  useType: string,
};

export type DTObject = {
  __typename: "DTObject",
  filesInfo:  Array<DTObjectFileInfo >,
  id: string,
  isCustomerObject: boolean,
  objectId: string,
  objectManufacturerName: string,
  objectName: string,
  objectType: string,
  version?: number | null,
};

export type DTObjectFileInfo = {
  __typename: "DTObjectFileInfo",
  contentType: string,
  id: string,
  key: string,
  originalFileName: string,
  useType: string,
};

export type AddDTProjectInput = {
  customerId: string,
  displayName: string,
  lastModificationDate?: string | null,
  lastModifierUserName?: string | null,
  measurementSystem: string,
  usedObjects?: Array< string | null > | null,
  withKMZLayer?: boolean | null,
};

export type DTProject = {
  __typename: "DTProject",
  configURL?: string | null,
  customerId?: string | null,
  displayName?: string | null,
  id?: string | null,
  kmzLayerURL?: string | null,
  lastModificationDate?: string | null,
  lastModifierUserName?: string | null,
  measurementSystem?: string | null,
  projectId?: string | null,
  withKMZLayer?: boolean | null,
};

export type AddDeviceInput = {
  cameraIpAddress: string,
  customerId: string,
  deviceData?: string | null,
  locationId: string,
  locationName?: string | null,
  makeModelId: string,
  name: string,
  nodeId: string,
  nodeName?: string | null,
};

export type Device = {
  __typename: "Device",
  cameraIpAddress?: string | null,
  customerId: string,
  deviceData: DeviceData,
  healthData: DeviceHealthData,
  id: string,
  locationId: string,
  locationName?: string | null,
  makeModelId: string,
  name: string,
  nodeId: string,
  nodeName?: string | null,
  status?: string | null,
};

export type DeviceData = {
  __typename: "DeviceData",
  hasPanTilt: boolean,
  hasRecordVideo: boolean,
  hasZoom: boolean,
  keyFrame: string,
  pan: string,
  panTiltIP: string,
  protocol: string,
  recentFrame: string,
  rtspHost: string,
  sourceVideo: string,
  tilt: string,
  zoom: string,
};

export type DeviceHealthData = {
  __typename: "DeviceHealthData",
  isOnline: boolean,
  lastAlive: string,
  totalDowntime: string,
  totalUptime: string,
  uptimeDate: string,
  uptimePercent: number,
};

export type AddDeviceMakeInput = {
  hasPanTilt: boolean,
  hasZoom?: boolean | null,
  model: string,
  modelsToRun: Array< string | null >,
  name: string,
  rtspTemplate: string,
  type: string,
};

export type DeviceMake = {
  __typename: "DeviceMake",
  defaultUserPwd?: string | null,
  deviceTypeShortName?: string | null,
  hasPanTilt: boolean,
  hasZoom?: boolean | null,
  id: string,
  model: string,
  modelsToRun: Array< string | null >,
  name: string,
  rtspTemplate: string,
  type: string,
};

export type Domain = {
  __typename: "Domain",
  customerId?: string | null,
};

export type AddLicensePlateInput = {
  categoryId: string,
  company?: string | null,
  customerId: string,
  driverName?: string | null,
  licensePlate: string,
};

export type LicensePlate = {
  __typename: "LicensePlate",
  categoryId: string,
  company?: string | null,
  driverName?: string | null,
  id: string,
  licensePlate: string,
};

export type LocationInput = {
  customerExternalId: string,
  customerId: string,
  id?: string | null,
  locationData?: string | null,
  name?: string | null,
  referenceId?: string | null,
  tags: Array< TagInput >,
  timeZone?: string | null,
};

export type TagInput = {
  associationId?: string | null,
  id: string,
  tagName: string,
};

export type Location = {
  __typename: "Location",
  devices?:  Array<Device > | null,
  id: string,
  locationData?: string | null,
  name: string,
  referenceId?: string | null,
  tags?:  Array<TagOfAssociation > | null,
  timeZone?: string | null,
};

export type TagOfAssociation = {
  __typename: "TagOfAssociation",
  associationId: string,
  id: string,
  tagName: string,
};

export type AddNodeInput = {
  customerExternalId: string,
  level: NodeLevel,
  locationId: string,
  nodeIP: string,
  nodeName: string,
  referenceId?: string | null,
  tags?: Array< AddTagInput | null > | null,
};

export enum NodeLevel {
  CUSTOMER = "CUSTOMER",
  LOCATION = "LOCATION",
  NODE = "NODE",
}


export type AddTagInput = {
  customerId: string,
  tagName: string,
};

export type Node = {
  __typename: "Node",
  customerId?: string | null,
  deviceData?: string | null,
  devices?:  Array<Device > | null,
  id?: string | null,
  isOnline?: boolean | null,
  level?: NodeLevel | null,
  locationId?: string | null,
  nodeIP?: string | null,
  nodeId?: string | null,
  nodeName?: string | null,
  onboardCommand?: string | null,
  referenceId?: string | null,
  serviceData?: string | null,
  tags?:  Array<Tag | null > | null,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  tagName: string,
};

export type AddServiceInput = {
  configuration?: string | null,
  customerId: string,
  deviceId: string,
  locationId: string,
  nodeId: string,
  serviceType: string,
};

export type Service = {
  __typename: "Service",
  configuration?: string | null,
  customerId?: string | null,
  deviceId?: string | null,
  healthData?: string | null,
  id?: string | null,
  isRunning?: boolean | null,
  locationId?: string | null,
  nodeId?: string | null,
  serviceType?: string | null,
};

export type AddZoneInput = {
  deviceId: string,
  inspectTime?: string | null,
  keyFrame?: string | null,
  name: string,
  nodeId?: string | null,
  pan: string,
  serviceId?: string | null,
  shouldNotify?: boolean | null,
  status: boolean,
  tilt: string,
  zoom?: string | null,
};

export type Zone = {
  __typename: "Zone",
  alertData?: string | null,
  deviceId: string,
  exclusions?: string | null,
  id: string,
  inspectTime?: string | null,
  keyFrame?: string | null,
  name: string,
  nodeId: string,
  pan: string,
  serviceId?: string | null,
  shouldNotify?: boolean | null,
  status: boolean,
  threshold?: ZoneThreshold | null,
  tilt: string,
  zoom?: string | null,
};

export type ZoneThreshold = {
  __typename: "ZoneThreshold",
  id: string,
  name: string,
  value: number,
};

export type AddZoneThresholdInput = {
  customerId: string,
  name: string,
  value: number,
};

export type User = {
  __typename: "User",
  customer_id: string,
  email: string,
  externalId: string,
  firstName: string,
  id: string,
  lastName: string,
  userSettings?: string | null,
  user_role: string,
};

export type BatchAddTagAssociationsInput = {
  arrayOfTags: Array< TagInput >,
  customerId: string,
  tagAssociation: AddTagAssociationInput,
};

export type AddTagAssociationInput = {
  associationId: string,
  associationType: TagAssociationType,
};

export enum TagAssociationType {
  ALL = "ALL",
  LOCATION = "LOCATION",
  NODE = "NODE",
}


export type AddVideosInput = {
  customerId: string,
  videos: Array< VideoInput >,
};

export type VideoInput = {
  annotations?: string | null,
  clientId: string,
  customerId: string,
  fov?: number | null,
  geometry: string,
  groupId: string,
  isVideoProcessed?: boolean | null,
  modelOutputS3Key?: string | null,
  notes?: string | null,
  outputMeasurement?: number | null,
  sourceS3Key: string,
  videoMethod: VideoMethod,
  videoName: string,
  windspeed: number,
};

export enum VideoMethod {
  DRONE = "DRONE",
  HANDHELD = "HANDHELD",
}


export type LeakFinderVideo = {
  __typename: "LeakFinderVideo",
  annotations?: string | null,
  clientId: string,
  customerId: string,
  fov?: number | null,
  geometry: string,
  groupId: string,
  id: string,
  isVideoProcessed?: boolean | null,
  modelOutputS3Key?: string | null,
  notes?: string | null,
  outputMeasurement?: number | null,
  sourceS3Key: string,
  videoMethod: VideoMethod,
  videoName: string,
  videoState: VideoState,
  windspeed: number,
};

export enum VideoState {
  DONE = "DONE",
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}


export type BatchDeleteClientGroupsInput = {
  clientId: string,
  groupId: string,
};

export type BatchDeleteVideosInput = {
  groupId: string,
  id: string,
  videoState: VideoState,
};

export type DeletedVideos = {
  __typename: "DeletedVideos",
  groupId: string,
  id: string,
};

export type ChangeNodeStatusInput = {
  action: string,
  nodeId: string,
};

export type createSecretsManagerSecretInput = {
  customerId: string,
  secretType: SecretType,
  secretValue: string,
};

export enum SecretType {
  ACCESS_KEY = "ACCESS_KEY",
  BUCKET_NAME = "BUCKET_NAME",
  BUCKET_REGION = "BUCKET_REGION",
  CONNECTION_STRING = "CONNECTION_STRING",
  CONTAINER_NAME = "CONTAINER_NAME",
  SECRET_ACCESS_KEY = "SECRET_ACCESS_KEY",
}


export type DeleteAlertInput = {
  alertName: string,
  customerId: string,
  id: string,
};

export type DeleteAllAnnotationsInput = {
  serviceId: string,
};

export type RemovedAnnotations = {
  __typename: "RemovedAnnotations",
  id?: string | null,
  zoneId?: string | null,
};

export type DeleteAnnotationInput = {
  id: string,
  serviceId: string,
  zoneId?: string | null,
};

export type DeleteCategory = {
  categoryId?: string | null,
  customerId?: string | null,
};

export type DeleteClientInput = {
  clientId: string,
  customerId: string,
};

export type DeleteClientGroupInput = {
  clientId: string,
  groupId: string,
};

export type DeleteDTObjectInput = {
  customerId?: string | null,
  objectId: string,
};

export type DeleteDTProjectInput = {
  customerId: string,
  projectId: string,
};

export type DeleteLicensePlate = {
  categoryId: string,
  licensePlateId: string,
};

export type DeleteServiceInput = {
  deviceId: string,
  nodeId: string,
  serviceId: string,
};

export type DeleteTagInput = {
  customerId: string,
  tagId: string,
};

export type DeleteVideoInput = {
  groupId: string,
  videoId: string,
};

export type DeleteZoneInput = {
  deviceId: string,
  serviceId: string,
  zoneId: string,
};

export type EdgeClientMessageInput = {
  id: string,
  payload: string,
  payloadType: PayloadType,
  statusCode: number,
  timestamp: string,
  topic: string,
};

export enum PayloadType {
  DEVICE_PT_COORDS = "DEVICE_PT_COORDS",
  LF_VIDEO_PROCESS_STATUS = "LF_VIDEO_PROCESS_STATUS",
}


export type EdgeClientMessageResponse = {
  __typename: "EdgeClientMessageResponse",
  id: string,
  payload: string,
  payloadType: PayloadType,
  statusCode: number,
  timestamp: string,
  topic: string,
};

export type ValidateEventInput = {
  auditorsExplanation?: string | null,
  customerId: string,
  eventId: string,
  eventTime: string,
  eventType?: ModelType | null,
  explanation?: string | null,
  real: boolean,
};

export enum ModelType {
  agg = "agg",
  fire = "fire",
  gasleak = "gasleak",
  hardhat = "hardhat",
  liquidleak = "liquidleak",
  smoke = "smoke",
  tlm = "tlm",
}


export type HumanValidationEvent = {
  __typename: "HumanValidationEvent",
  audited_by?: string | null,
  auditorsExplanation?: string | null,
  customerId: string,
  data?: string | null,
  eventTime: string,
  explanation?: string | null,
  id: string,
  locationId: string,
  nodeId: string,
  serviceId: string,
  transitionInfo?: string | null,
  validated_by?: string | null,
  validationState?: ValidationState | null,
};

export enum ValidationState {
  EXPIRED = "EXPIRED",
  IN_VALIDATION = "IN_VALIDATION",
  NOT_REAL = "NOT_REAL",
  PENDING = "PENDING",
  REAL = "REAL",
}


export type publishNodeInput = {
  message: string,
  nodeId: string,
};

export type publishNode = {
  __typename: "publishNode",
  message: string,
  nodeId: string,
};

export type NodeFile = {
  __typename: "NodeFile",
  downloadURL: string,
  file?: string | null,
  internalNodeId: string,
};

export type NodeMessage = {
  __typename: "NodeMessage",
  internalNodeId: string,
  message?: string | null,
};

export type SetServiceStatusInput = {
  nodeId: string,
  runStatus: boolean,
  serviceId: string,
};

export type UpdateAdminEmailAlertInput = {
  additionalEmails?: Array< string | null > | null,
  additionalPhoneNumbers?: Array< string | null > | null,
  alertName: string,
  customerId: string,
  id: string,
  location?: string | null,
  model?: string | null,
  notificationSettingsJson?: string | null,
  subscribedLevelEntity?: string | null,
  tag?: string | null,
};

export type UpdateAdminSystemAlertInput = {
  customService?: string | null,
  customerId: string,
  id: string,
  jsonObject?: string | null,
  node?: string | null,
};

export type UpdateAlertInput = {
  additionalEmails?: Array< string | null > | null,
  additionalPhoneNumbers?: Array< string | null > | null,
  alertName: string,
  alertType: string,
  customService?: string | null,
  customerId: string,
  email?: string | null,
  jsonObject?: string | null,
  location?: string | null,
  model?: string | null,
  node?: string | null,
  notificationSettingsJson?: string | null,
  phoneNumber?: string | null,
};

export type UpdateAlertStatusInput = {
  customerId: string,
  id: string,
  notificationSettingsJson?: string | null,
};

export type UpdateCategory = {
  categoryId: string,
  categoryName: string,
  customerId: string,
  isAlertEnabled: boolean,
};

export type UpdateClientInput = {
  clientName: string,
  customerId: string,
  id: string,
};

export type UpdateClientGroupInput = {
  clientId: string,
  groupId: string,
  groupName: string,
};

export type UpdateCustomerInput = {
  aggAlertingData?: string | null,
  allowLeakFinder?: AllowLeakFinder | null,
  configuration?: string | null,
  id: string,
  pointOfContact?: string | null,
  totalLeakFinderLimit?: number | null,
};

export enum AllowLeakFinder {
  FULL_ACCESS = "FULL_ACCESS",
  NONE = "NONE",
  TRIAL_ACCESS = "TRIAL_ACCESS",
}


export type Customer = {
  __typename: "Customer",
  aggAlertingData?: string | null,
  allowLeakFinder?: AllowLeakFinder | null,
  auditCustomers?: string | null,
  configuration?: string | null,
  domains?: Array< string > | null,
  externalId: string,
  id: string,
  name?: string | null,
  pointOfContact?: string | null,
  totalLeakFinderLimit?: number | null,
  usageLeakFinderCount?: number | null,
};

export type UpdateDTProjectInput = {
  customerId: string,
  displayName: string,
  lastModificationDate?: string | null,
  lastModifierUserName?: string | null,
  measurementSystem?: string | null,
  projectId: string,
  usedObjects?: Array< string | null > | null,
  withKMZLayer?: boolean | null,
};

export type UpdateDeviceInput = {
  cameraIpAddress?: string | null,
  customerId: string,
  deviceData?: string | null,
  deviceId: string,
  locationName?: string | null,
  makeModelId?: string | null,
  name?: string | null,
  nodeId?: string | null,
  nodeName?: string | null,
};

export type DeviceIam = {
  __typename: "DeviceIam",
  cameraIpAddress?: string | null,
  customerId: string,
  deviceData: DeviceDataIam,
  healthData: DeviceHealthDataIam,
  id: string,
  locationId: string,
  locationName?: string | null,
  makeModelId: string,
  name: string,
  nodeId: string,
  nodeName?: string | null,
  status?: string | null,
};

export type DeviceDataIam = {
  __typename: "DeviceDataIam",
  hasPanTilt: boolean,
  hasRecordVideo: boolean,
  hasZoom: boolean,
  keyFrame: string,
  pan: string,
  panTiltIP: string,
  protocol: string,
  recentFrame: string,
  rtspHost: string,
  sourceVideo: string,
  tilt: string,
  zoom: string,
};

export type DeviceHealthDataIam = {
  __typename: "DeviceHealthDataIam",
  isOnline: boolean,
  lastAlive: string,
  totalDowntime: string,
  totalUptime: string,
  uptimeDate: string,
  uptimePercent: number,
};

export type UpdateDeviceMakeInput = {
  hasPanTilt: boolean,
  hasZoom?: boolean | null,
  id: string,
  model: string,
  modelsToRun: Array< string | null >,
  name: string,
  rtspTemplate: string,
  type: string,
};

export type UpdateLicensePlate = {
  categoryId: string,
  company?: string | null,
  customerId?: string | null,
  driverName?: string | null,
  licensePlate: string,
  licensePlateId: string,
  newCategoryId?: string | null,
};

export type UpdateLocationInput = {
  customerId: string,
  id?: string | null,
  locationData?: string | null,
  locationId?: string | null,
  name?: string | null,
  referenceId?: string | null,
  tags: Array< TagInput >,
  tagsToDelete: Array< TagInput >,
  timeZone?: string | null,
};

export type UpdateNodeInput = {
  deviceData?: string | null,
  nodeId: string,
  serviceData?: string | null,
};

export type NodeIam = {
  __typename: "NodeIam",
  customerId?: string | null,
  deviceData?: string | null,
  devices?:  Array<Device > | null,
  id?: string | null,
  isOnline?: boolean | null,
  level?: NodeLevel | null,
  locationId?: string | null,
  nodeIP?: string | null,
  nodeId?: string | null,
  nodeName?: string | null,
  onboardCommand?: string | null,
  referenceId?: string | null,
  serviceData?: string | null,
  tags?:  Array<Tag | null > | null,
};

export type ProfileInput = {
  file?: string | null,
  name: string,
};

export type Profile = {
  __typename: "Profile",
  file?: string | null,
  id: string,
  name?: string | null,
};

export type UpdateServiceInput = {
  configuration?: string | null,
  nodeId: string,
  serviceId: string,
};

export type ServiceIam = {
  __typename: "ServiceIam",
  configuration?: string | null,
  customerId?: string | null,
  deviceId?: string | null,
  healthData?: string | null,
  id?: string | null,
  isRunning?: boolean | null,
  locationId?: string | null,
  nodeId?: string | null,
  serviceType?: string | null,
};

export type Shadow = {
  __typename: "Shadow",
  shadow?: string | null,
};

export type UpdateUserAlertInput = {
  alertName: string,
  customerId: string,
  id: string,
  location?: string | null,
  model?: string | null,
  notificationSettingsJson?: string | null,
  phoneNumber?: string | null,
};

export type UserSettingsInput = {
  defaultCameraFov?: number | null,
  defaultVideoSpeed?: number | null,
  defaultWindspeedUnit?: string | null,
};

export type UpdateVideoNotesInput = {
  groupId: string,
  notes?: string | null,
  videoId: string,
};

export type UpdateZoneInput = {
  customerId: string,
  deviceId: string,
  exclusions?: string | null,
  inspectTime?: string | null,
  name?: string | null,
  pan?: string | null,
  serviceId?: string | null,
  shouldNotify?: boolean | null,
  status?: boolean | null,
  tilt?: string | null,
  zoneId: string,
  zoom?: string | null,
};

export type UpdateZoneThresholdItemInput = {
  id: string,
  name: string,
  value: number,
};

export type UpdateZoneThresholdValueInput = {
  customerId: string,
  deviceId: string,
  value?: string | null,
  zoneId: string,
};

export type UploadFileInput = {
  contentType?: string | null,
  fileName?: string | null,
};

export type DTFile = {
  __typename: "DTFile",
  key?: string | null,
  url?: string | null,
};

export type PaginatedAlerts = {
  __typename: "PaginatedAlerts",
  items:  Array<Alert | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedResponse = {
  __typename: "PaginatedResponse",
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedAllEmailsForSubscriptionEntity = {
  __typename: "PaginatedAllEmailsForSubscriptionEntity",
  items:  Array<AlertSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedAlertSubscriptions = {
  __typename: "PaginatedAlertSubscriptions",
  items:  Array<AlertSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedLeakFinderVideos = {
  __typename: "PaginatedLeakFinderVideos",
  items:  Array<LeakFinderVideo >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedWildCardsForSubscriptionEntity = {
  __typename: "PaginatedWildCardsForSubscriptionEntity",
  items:  Array<AlertWildCardSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedAnnotations = {
  __typename: "PaginatedAnnotations",
  items:  Array<Annotation >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedCategories = {
  __typename: "PaginatedCategories",
  items:  Array<Category >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedClients = {
  __typename: "PaginatedClients",
  items:  Array<Client >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedCustomers = {
  __typename: "PaginatedCustomers",
  items:  Array<Customer | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedDTProjects = {
  __typename: "PaginatedDTProjects",
  items:  Array<DTProject | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedDevices = {
  __typename: "PaginatedDevices",
  items:  Array<Device | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedDevicesMakes = {
  __typename: "PaginatedDevicesMakes",
  items:  Array<DeviceMake | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedHumanValidationEvents = {
  __typename: "PaginatedHumanValidationEvents",
  items:  Array<HumanValidationEvent | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedGroups = {
  __typename: "PaginatedGroups",
  items:  Array<Group >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedLicensePlates = {
  __typename: "PaginatedLicensePlates",
  items:  Array<LicensePlate >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedLocations = {
  __typename: "PaginatedLocations",
  items:  Array<Location >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedNodes = {
  __typename: "PaginatedNodes",
  items:  Array<Node | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedRoles = {
  __typename: "PaginatedRoles",
  items:  Array<Role | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type Role = {
  __typename: "Role",
  id: string,
  name: string,
  userGroup?: Array< string | null > | null,
};

export type PaginatedServices = {
  __typename: "PaginatedServices",
  items:  Array<Service | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedSubscriptionsForEmail = {
  __typename: "PaginatedSubscriptionsForEmail",
  items:  Array<AlertSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedTagAssociations = {
  __typename: "PaginatedTagAssociations",
  items:  Array<TagOfAssociation >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedTags = {
  __typename: "PaginatedTags",
  items:  Array<Tag >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedZones = {
  __typename: "PaginatedZones",
  items:  Array<Zone | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedZonesThresholds = {
  __typename: "PaginatedZonesThresholds",
  items:  Array<ZoneThreshold >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedUsers = {
  __typename: "PaginatedUsers",
  items:  Array<User | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllVideosByClientInput = {
  clientId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllVideosByGroupInput = {
  groupId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllVideosByStateInput = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
  videoState: VideoState,
};

export type GetAnnotationsInput = {
  customerId?: string | null,
  deviceId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
  nodeId?: string | null,
  serviceId?: string | null,
  zoneId?: string | null,
};

export type GetCategoriesByCustomerInput = {
  categoryName?: string | null,
  customerId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCategoryInput = {
  categoryId: string,
  customerId: string,
};

export type GetClientInput = {
  clientId: string,
  customerId: string,
};

export type GetClientsByCustomerInput = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerCountersType = {
  __typename: "CustomerCountersType",
  allowLeakFinder?: AllowLeakFinder | null,
  id: string,
  totalLeakFinderLimit?: number | null,
  usageLeakFinderCount?: number | null,
};

export type Form = {
  __typename: "Form",
  customerId?: string | null,
  fields?:  Array<Field | null > | null,
  id?: string | null,
};

export type Field = {
  __typename: "Field",
  entries?: Array< string | null > | null,
  itemToQuery?: string | null,
  name: string,
  type: string,
};

export type GetGroupByClientIdInput = {
  clientId: string,
  groupId: string,
};

export type GetGroupsByClientInput = {
  clientId: string,
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetLicensePlatesByCustomerInput = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetServiceInput = {
  customerId?: string | null,
  locationId?: string | null,
  nodeId?: string | null,
  serviceId?: string | null,
};

export type GetSignedUrlActionInput = {
  contentType?: string | null,
  objectKey: string,
  operation?: signedUrlActions | null,
};

export enum signedUrlActions {
  deleteObject = "deleteObject",
  getObject = "getObject",
  putObject = "putObject",
}


export type StatisticsDataInput = {
  customerId: string,
  date?: string | null,
  dateRange?: DateRange | null,
  deviceName?: string | null,
  eventType: eventType,
  locations?: Array< string > | null,
  models?: Array< ModelType | null > | null,
  timePeriod?: TimePeriod | null,
};

export type DateRange = {
  from?: string | null,
  to?: string | null,
};

export enum eventType {
  PPEInfractions = "PPEInfractions",
  allAlerts = "allAlerts",
  deviceOverview = "deviceOverview",
  gasVolumes = "gasVolumes",
  totalSiteCounts = "totalSiteCounts",
  visitors = "visitors",
}


export enum TimePeriod {
  ALL_TIME = "ALL_TIME",
  ONE_DAY = "ONE_DAY",
  ONE_MONTH = "ONE_MONTH",
  ONE_WEEK = "ONE_WEEK",
}


export type GetTagAssociationsInput = {
  associationId?: string | null,
  associationType: TagAssociationType,
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTagsByCustomerInput = {
  customerId: string,
  nextToken?: string | null,
};

export type TimestreamDataInput = {
  categoryName?: string | null,
  companyName?: string | null,
  customerId: string,
  dateRange?: string | null,
  licensePlate?: string | null,
  limit?: number | null,
  locations?: Array< string > | null,
  model: string,
  nextToken?: string | null,
  offset?: number | null,
};

export type GetZoneThresholdInput = {
  customerId: string,
  thresholdId: string,
};

export type GetZonesThresholdsByCustomerInput = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type AddAlertEmailNotificationMutationVariables = {
  input?: addAlertEmailNotificationInput | null,
};

export type AddAlertEmailNotificationMutation = {
  addAlertEmailNotification?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type AddAlertSubscriptionMutationVariables = {
  input: AddAlertSubscriptionInput,
};

export type AddAlertSubscriptionMutation = {
  addAlertSubscription?:  {
    __typename: "AlertSubscription",
    email: string,
    entity?: string | null,
  } | null,
};

export type AddAlertWildCardSubscriptionMutationVariables = {
  input: AddAlertWildCardSubscriptionInput,
};

export type AddAlertWildCardSubscriptionMutation = {
  addAlertWildCardSubscription?:  {
    __typename: "AlertWildCardSubscription",
    email: string,
    entity: string,
    wildCard: string,
  } | null,
};

export type AddAnnotationMutationVariables = {
  input: AnnotationInput,
};

export type AddAnnotationMutation = {
  addAnnotation:  {
    __typename: "Annotation",
    annotateTanks?: boolean | null,
    annotationType: AnnotationType,
    bottomHidden?: number | null,
    deviceId?: string | null,
    equipmentType?: string | null,
    id: string,
    name: string,
    polygon?: string | null,
    serviceId: string,
    tankHeight?: number | null,
    tankNumber?: number | null,
    zoneId?: string | null,
  },
};

export type AddCategoryMutationVariables = {
  input: AddCategoryInput,
};

export type AddCategoryMutation = {
  addCategory?:  {
    __typename: "Category",
    categoryName: string,
    id: string,
    isAlertEnabled: boolean,
  } | null,
};

export type AddClientMutationVariables = {
  input: AddClientInput,
};

export type AddClientMutation = {
  addClient?:  {
    __typename: "Client",
    clientName: string,
    customerId: string,
    id: string,
  } | null,
};

export type AddClientGroupMutationVariables = {
  input?: AddClientGroupInput | null,
};

export type AddClientGroupMutation = {
  addClientGroup?:  {
    __typename: "Group",
    clientId: string,
    createdAt: number,
    customerId: string,
    groupName: string,
    id: string,
  } | null,
};

export type AddCustomerDTObjectMutationVariables = {
  input: DTObjectInput,
};

export type AddCustomerDTObjectMutation = {
  addCustomerDTObject?:  {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null,
};

export type AddDTObjectMutationVariables = {
  input: DTObjectInput,
};

export type AddDTObjectMutation = {
  addDTObject?:  {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null,
};

export type AddDTProjectMutationVariables = {
  input: AddDTProjectInput,
};

export type AddDTProjectMutation = {
  addDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    kmzLayerURL?: string | null,
    lastModificationDate?: string | null,
    lastModifierUserName?: string | null,
    measurementSystem?: string | null,
    projectId?: string | null,
    withKMZLayer?: boolean | null,
  } | null,
};

export type AddDeviceMutationVariables = {
  input: AddDeviceInput,
};

export type AddDeviceMutation = {
  addDevice?:  {
    __typename: "Device",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceData",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthData",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type AddDeviceMakeMutationVariables = {
  input: AddDeviceMakeInput,
};

export type AddDeviceMakeMutation = {
  addDeviceMake?:  {
    __typename: "DeviceMake",
    defaultUserPwd?: string | null,
    deviceTypeShortName?: string | null,
    hasPanTilt: boolean,
    hasZoom?: boolean | null,
    id: string,
    model: string,
    modelsToRun: Array< string | null >,
    name: string,
    rtspTemplate: string,
    type: string,
  } | null,
};

export type AddDomainMutationVariables = {
  customerId: string,
  domain: string,
};

export type AddDomainMutation = {
  addDomain?:  {
    __typename: "Domain",
    customerId?: string | null,
  } | null,
};

export type AddLicensePlateMutationVariables = {
  input: AddLicensePlateInput,
};

export type AddLicensePlateMutation = {
  addLicensePlate?:  {
    __typename: "LicensePlate",
    categoryId: string,
    company?: string | null,
    driverName?: string | null,
    id: string,
    licensePlate: string,
  } | null,
};

export type AddLocationMutationVariables = {
  input: LocationInput,
};

export type AddLocationMutation = {
  addLocation:  {
    __typename: "Location",
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id: string,
    locationData?: string | null,
    name: string,
    referenceId?: string | null,
    tags?:  Array< {
      __typename: "TagOfAssociation",
      associationId: string,
      id: string,
      tagName: string,
    } > | null,
    timeZone?: string | null,
  },
};

export type AddNodeMutationVariables = {
  input: AddNodeInput,
};

export type AddNodeMutation = {
  addNode:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  },
};

export type AddServiceMutationVariables = {
  input: AddServiceInput,
};

export type AddServiceMutation = {
  addService?:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type AddTagMutationVariables = {
  input: AddTagInput,
};

export type AddTagMutation = {
  addTag:  {
    __typename: "Tag",
    id: string,
    tagName: string,
  },
};

export type AddZoneMutationVariables = {
  input: AddZoneInput,
};

export type AddZoneMutation = {
  addZone:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  },
};

export type AddZoneThresholdMutationVariables = {
  input: AddZoneThresholdInput,
};

export type AddZoneThresholdMutation = {
  addZoneThreshold:  {
    __typename: "ZoneThreshold",
    id: string,
    name: string,
    value: number,
  },
};

export type AssignFirstRoleToUserMutationVariables = {
  customerId: string,
  groupId: string,
  roleId: string,
  userId: string,
  userPoolId?: string | null,
};

export type AssignFirstRoleToUserMutation = {
  assignFirstRoleToUser:  {
    __typename: "User",
    customer_id: string,
    email: string,
    externalId: string,
    firstName: string,
    id: string,
    lastName: string,
    userSettings?: string | null,
    user_role: string,
  },
};

export type BatchAddCategoryMutationVariables = {
  input: Array< AddCategoryInput >,
};

export type BatchAddCategoryMutation = {
  batchAddCategory:  Array< {
    __typename: "Category",
    categoryName: string,
    id: string,
    isAlertEnabled: boolean,
  } >,
};

export type BatchAddLicensePlateMutationVariables = {
  input: Array< AddLicensePlateInput >,
};

export type BatchAddLicensePlateMutation = {
  batchAddLicensePlate:  Array< {
    __typename: "LicensePlate",
    categoryId: string,
    company?: string | null,
    driverName?: string | null,
    id: string,
    licensePlate: string,
  } >,
};

export type BatchAddTagAssociationsMutationVariables = {
  input?: BatchAddTagAssociationsInput | null,
};

export type BatchAddTagAssociationsMutation = {
  batchAddTagAssociations:  Array< {
    __typename: "TagOfAssociation",
    associationId: string,
    id: string,
    tagName: string,
  } >,
};

export type BatchAddVideosMutationVariables = {
  input?: AddVideosInput | null,
};

export type BatchAddVideosMutation = {
  batchAddVideos:  Array< {
    __typename: "LeakFinderVideo",
    annotations?: string | null,
    clientId: string,
    customerId: string,
    fov?: number | null,
    geometry: string,
    groupId: string,
    id: string,
    isVideoProcessed?: boolean | null,
    modelOutputS3Key?: string | null,
    notes?: string | null,
    outputMeasurement?: number | null,
    sourceS3Key: string,
    videoMethod: VideoMethod,
    videoName: string,
    videoState: VideoState,
    windspeed: number,
  } >,
};

export type BatchDeleteClientGroupsMutationVariables = {
  input: Array< BatchDeleteClientGroupsInput >,
};

export type BatchDeleteClientGroupsMutation = {
  batchDeleteClientGroups:  Array< {
    __typename: "Group",
    clientId: string,
    createdAt: number,
    customerId: string,
    groupName: string,
    id: string,
  } >,
};

export type BatchDeleteVideosMutationVariables = {
  input: Array< BatchDeleteVideosInput >,
};

export type BatchDeleteVideosMutation = {
  batchDeleteVideos:  Array< {
    __typename: "DeletedVideos",
    groupId: string,
    id: string,
  } >,
};

export type ChangeNodeStatusMutationVariables = {
  input: ChangeNodeStatusInput,
};

export type ChangeNodeStatusMutation = {
  changeNodeStatus: string,
};

export type CreateSecretsManagerSecretMutationVariables = {
  input: createSecretsManagerSecretInput,
};

export type CreateSecretsManagerSecretMutation = {
  createSecretsManagerSecret?: string | null,
};

export type DeactivateNodeOnboardScriptMutationVariables = {
  locationId: string,
  nodeId: string,
};

export type DeactivateNodeOnboardScriptMutation = {
  deactivateNodeOnboardScript:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  },
};

export type DeleteAlertMutationVariables = {
  input: DeleteAlertInput,
};

export type DeleteAlertMutation = {
  deleteAlert?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type DeleteAllAnnotationsMutationVariables = {
  input: DeleteAllAnnotationsInput,
};

export type DeleteAllAnnotationsMutation = {
  deleteAllAnnotations?:  {
    __typename: "RemovedAnnotations",
    id?: string | null,
    zoneId?: string | null,
  } | null,
};

export type DeleteAnnotationMutationVariables = {
  input: DeleteAnnotationInput,
};

export type DeleteAnnotationMutation = {
  deleteAnnotation:  {
    __typename: "Annotation",
    annotateTanks?: boolean | null,
    annotationType: AnnotationType,
    bottomHidden?: number | null,
    deviceId?: string | null,
    equipmentType?: string | null,
    id: string,
    name: string,
    polygon?: string | null,
    serviceId: string,
    tankHeight?: number | null,
    tankNumber?: number | null,
    zoneId?: string | null,
  },
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategory,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    categoryName: string,
    id: string,
    isAlertEnabled: boolean,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    clientName: string,
    customerId: string,
    id: string,
  } | null,
};

export type DeleteClientGroupMutationVariables = {
  input?: DeleteClientGroupInput | null,
};

export type DeleteClientGroupMutation = {
  deleteClientGroup?:  {
    __typename: "Group",
    clientId: string,
    createdAt: number,
    customerId: string,
    groupName: string,
    id: string,
  } | null,
};

export type DeleteCustomerDTObjectMutationVariables = {
  input: DeleteDTObjectInput,
};

export type DeleteCustomerDTObjectMutation = {
  deleteCustomerDTObject?:  {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null,
};

export type DeleteDTProjectMutationVariables = {
  input: DeleteDTProjectInput,
};

export type DeleteDTProjectMutation = {
  deleteDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    kmzLayerURL?: string | null,
    lastModificationDate?: string | null,
    lastModifierUserName?: string | null,
    measurementSystem?: string | null,
    projectId?: string | null,
    withKMZLayer?: boolean | null,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  customerId: string,
  deviceId: string,
};

export type DeleteDeviceMutation = {
  deleteDevice: string,
};

export type DeleteDeviceMakeMutationVariables = {
  id: string,
};

export type DeleteDeviceMakeMutation = {
  deleteDeviceMake: string,
};

export type DeleteLicensePlateMutationVariables = {
  input: DeleteLicensePlate,
};

export type DeleteLicensePlateMutation = {
  deleteLicensePlate?:  {
    __typename: "LicensePlate",
    categoryId: string,
    company?: string | null,
    driverName?: string | null,
    id: string,
    licensePlate: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  customerId: string,
  locationId: string,
};

export type DeleteLocationMutation = {
  deleteLocation: string,
};

export type DeleteNodeMutationVariables = {
  nodeId: string,
};

export type DeleteNodeMutation = {
  deleteNode:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  },
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput,
};

export type DeleteServiceMutation = {
  deleteService?:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
};

export type DeleteTagMutation = {
  deleteTag:  {
    __typename: "Tag",
    id: string,
    tagName: string,
  },
};

export type DeleteUserMutationVariables = {
  customerId: string,
  userId: string,
  userPoolId: string,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    customer_id: string,
    email: string,
    externalId: string,
    firstName: string,
    id: string,
    lastName: string,
    userSettings?: string | null,
    user_role: string,
  },
};

export type DeleteVideoMutationVariables = {
  input: DeleteVideoInput,
};

export type DeleteVideoMutation = {
  deleteVideo?:  {
    __typename: "LeakFinderVideo",
    annotations?: string | null,
    clientId: string,
    customerId: string,
    fov?: number | null,
    geometry: string,
    groupId: string,
    id: string,
    isVideoProcessed?: boolean | null,
    modelOutputS3Key?: string | null,
    notes?: string | null,
    outputMeasurement?: number | null,
    sourceS3Key: string,
    videoMethod: VideoMethod,
    videoName: string,
    videoState: VideoState,
    windspeed: number,
  } | null,
};

export type DeleteZoneMutationVariables = {
  input: DeleteZoneInput,
};

export type DeleteZoneMutation = {
  deleteZone:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  },
};

export type DeleteZoneThresholdMutationVariables = {
  id: string,
};

export type DeleteZoneThresholdMutation = {
  deleteZoneThreshold:  {
    __typename: "ZoneThreshold",
    id: string,
    name: string,
    value: number,
  },
};

export type EdgeClientMessengerMutationVariables = {
  message?: EdgeClientMessageInput | null,
};

export type EdgeClientMessengerMutation = {
  edgeClientMessenger:  {
    __typename: "EdgeClientMessageResponse",
    id: string,
    payload: string,
    payloadType: PayloadType,
    statusCode: number,
    timestamp: string,
    topic: string,
  },
};

export type OverruleHumanValidatedEventsMutationVariables = {
  input: ValidateEventInput,
};

export type OverruleHumanValidatedEventsMutation = {
  overruleHumanValidatedEvents:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type PublishNodeMutationVariables = {
  input: publishNodeInput,
};

export type PublishNodeMutation = {
  publishNode?:  {
    __typename: "publishNode",
    message: string,
    nodeId: string,
  } | null,
};

export type SendToListenToHumanValidationEventsMutationVariables = {
  humanValidationEvent: string,
};

export type SendToListenToHumanValidationEventsMutation = {
  sendToListenToHumanValidationEvents:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type SendToListenToNodeFilesMutationVariables = {
  nodeFile: string,
};

export type SendToListenToNodeFilesMutation = {
  sendToListenToNodeFiles:  {
    __typename: "NodeFile",
    downloadURL: string,
    file?: string | null,
    internalNodeId: string,
  },
};

export type SendToListenToNodeMessagesMutationVariables = {
  nodeMessage: string,
};

export type SendToListenToNodeMessagesMutation = {
  sendToListenToNodeMessages?:  {
    __typename: "NodeMessage",
    internalNodeId: string,
    message?: string | null,
  } | null,
};

export type SetIsRunningServiceStatusMutationVariables = {
  input: SetServiceStatusInput,
};

export type SetIsRunningServiceStatusMutation = {
  setIsRunningServiceStatus:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  },
};

export type SetPendingStateMutationVariables = {
  customerId?: string | null,
  eventId?: string | null,
};

export type SetPendingStateMutation = {
  setPendingState:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type SyncShadowMutationVariables = {
  nodeId: string,
};

export type SyncShadowMutation = {
  syncShadow?:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  } | null,
};

export type TakeEventToAuditMutationVariables = {
  customerId?: string | null,
  eventId?: string | null,
};

export type TakeEventToAuditMutation = {
  takeEventToAudit:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type TakeEventToValidateMutationVariables = {
  customerId?: string | null,
  eventId?: string | null,
};

export type TakeEventToValidateMutation = {
  takeEventToValidate:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type UpdateAdminEmailAlertMutationVariables = {
  input: UpdateAdminEmailAlertInput,
};

export type UpdateAdminEmailAlertMutation = {
  updateAdminEmailAlert?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type UpdateAdminSystemAlertMutationVariables = {
  input: UpdateAdminSystemAlertInput,
};

export type UpdateAdminSystemAlertMutation = {
  updateAdminSystemAlert?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type UpdateAlertMutationVariables = {
  input: UpdateAlertInput,
};

export type UpdateAlertMutation = {
  updateAlert?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type UpdateAlertStatusMutationVariables = {
  input: UpdateAlertStatusInput,
};

export type UpdateAlertStatusMutation = {
  updateAlertStatus?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type UpdateAnnotationMutationVariables = {
  input: AnnotationInput,
};

export type UpdateAnnotationMutation = {
  updateAnnotation:  {
    __typename: "Annotation",
    annotateTanks?: boolean | null,
    annotationType: AnnotationType,
    bottomHidden?: number | null,
    deviceId?: string | null,
    equipmentType?: string | null,
    id: string,
    name: string,
    polygon?: string | null,
    serviceId: string,
    tankHeight?: number | null,
    tankNumber?: number | null,
    zoneId?: string | null,
  },
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategory,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    categoryName: string,
    id: string,
    isAlertEnabled: boolean,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    clientName: string,
    customerId: string,
    id: string,
  } | null,
};

export type UpdateClientGroupMutationVariables = {
  input?: UpdateClientGroupInput | null,
};

export type UpdateClientGroupMutation = {
  updateClientGroup?:  {
    __typename: "Group",
    clientId: string,
    createdAt: number,
    customerId: string,
    groupName: string,
    id: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
};

export type UpdateCustomerMutation = {
  updateCustomer:  {
    __typename: "Customer",
    aggAlertingData?: string | null,
    allowLeakFinder?: AllowLeakFinder | null,
    auditCustomers?: string | null,
    configuration?: string | null,
    domains?: Array< string > | null,
    externalId: string,
    id: string,
    name?: string | null,
    pointOfContact?: string | null,
    totalLeakFinderLimit?: number | null,
    usageLeakFinderCount?: number | null,
  },
};

export type UpdateCustomerDTObjectMutationVariables = {
  input: DTObjectInput,
};

export type UpdateCustomerDTObjectMutation = {
  updateCustomerDTObject?:  {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null,
};

export type UpdateDTProjectMutationVariables = {
  input: UpdateDTProjectInput,
};

export type UpdateDTProjectMutation = {
  updateDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    kmzLayerURL?: string | null,
    lastModificationDate?: string | null,
    lastModifierUserName?: string | null,
    measurementSystem?: string | null,
    projectId?: string | null,
    withKMZLayer?: boolean | null,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input: UpdateDeviceInput,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceData",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthData",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type UpdateDeviceDataMutationVariables = {
  customerId: string,
  deviceData?: string | null,
  deviceId: string,
};

export type UpdateDeviceDataMutation = {
  updateDeviceData?:  {
    __typename: "DeviceIam",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceDataIam",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthDataIam",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type UpdateDeviceHealthMutationVariables = {
  deviceId: string,
  isOnline?: boolean | null,
  locationId: string,
};

export type UpdateDeviceHealthMutation = {
  updateDeviceHealth?:  {
    __typename: "DeviceIam",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceDataIam",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthDataIam",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type UpdateDeviceMakeMutationVariables = {
  input: UpdateDeviceMakeInput,
};

export type UpdateDeviceMakeMutation = {
  updateDeviceMake?:  {
    __typename: "DeviceMake",
    defaultUserPwd?: string | null,
    deviceTypeShortName?: string | null,
    hasPanTilt: boolean,
    hasZoom?: boolean | null,
    id: string,
    model: string,
    modelsToRun: Array< string | null >,
    name: string,
    rtspTemplate: string,
    type: string,
  } | null,
};

export type UpdateLicensePlateWithNewCategoryMutationVariables = {
  input: UpdateLicensePlate,
};

export type UpdateLicensePlateWithNewCategoryMutation = {
  updateLicensePlateWithNewCategory?:  {
    __typename: "LicensePlate",
    categoryId: string,
    company?: string | null,
    driverName?: string | null,
    id: string,
    licensePlate: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
};

export type UpdateLocationMutation = {
  updateLocation:  {
    __typename: "Location",
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id: string,
    locationData?: string | null,
    name: string,
    referenceId?: string | null,
    tags?:  Array< {
      __typename: "TagOfAssociation",
      associationId: string,
      id: string,
      tagName: string,
    } > | null,
    timeZone?: string | null,
  },
};

export type UpdateNodeMutationVariables = {
  input: UpdateNodeInput,
};

export type UpdateNodeMutation = {
  updateNode:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  },
};

export type UpdateNodeOnlineStatusMutationVariables = {
  isOnline?: boolean | null,
  nodeId: string,
};

export type UpdateNodeOnlineStatusMutation = {
  updateNodeOnlineStatus?:  {
    __typename: "NodeIam",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input?: ProfileInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    file?: string | null,
    id: string,
    name?: string | null,
  } | null,
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput,
};

export type UpdateServiceMutation = {
  updateService?:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type UpdateServiceHealthMutationVariables = {
  isOnline?: boolean | null,
  nodeId: string,
  serviceId: string,
};

export type UpdateServiceHealthMutation = {
  updateServiceHealth?:  {
    __typename: "ServiceIam",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type UpdateShadowNodeMutationVariables = {
  payload?: string | null,
  shadowName?: string | null,
  thingName?: string | null,
};

export type UpdateShadowNodeMutation = {
  updateShadowNode?:  {
    __typename: "Shadow",
    shadow?: string | null,
  } | null,
};

export type UpdateUserAlertMutationVariables = {
  input: UpdateUserAlertInput,
};

export type UpdateUserAlertMutation = {
  updateUserAlert?:  {
    __typename: "Alert",
    alertName?: string | null,
    alertType?: string | null,
    createdBy: string,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    notificationSettingsJson?: string | null,
    phoneNumber?: string | null,
    subscribedLevelEntity?: string | null,
    tag?: string | null,
  } | null,
};

export type UpdateUserSettingsMutationVariables = {
  customerId: string,
  userId: string,
  userSettings: UserSettingsInput,
};

export type UpdateUserSettingsMutation = {
  updateUserSettings:  {
    __typename: "User",
    customer_id: string,
    email: string,
    externalId: string,
    firstName: string,
    id: string,
    lastName: string,
    userSettings?: string | null,
    user_role: string,
  },
};

export type UpdateVideoNotesMutationVariables = {
  input: UpdateVideoNotesInput,
};

export type UpdateVideoNotesMutation = {
  updateVideoNotes:  {
    __typename: "LeakFinderVideo",
    annotations?: string | null,
    clientId: string,
    customerId: string,
    fov?: number | null,
    geometry: string,
    groupId: string,
    id: string,
    isVideoProcessed?: boolean | null,
    modelOutputS3Key?: string | null,
    notes?: string | null,
    outputMeasurement?: number | null,
    sourceS3Key: string,
    videoMethod: VideoMethod,
    videoName: string,
    videoState: VideoState,
    windspeed: number,
  },
};

export type UpdateZoneMutationVariables = {
  input: UpdateZoneInput,
};

export type UpdateZoneMutation = {
  updateZone:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  },
};

export type UpdateZoneThresholdItemMutationVariables = {
  input: UpdateZoneThresholdItemInput,
};

export type UpdateZoneThresholdItemMutation = {
  updateZoneThresholdItem:  {
    __typename: "ZoneThreshold",
    id: string,
    name: string,
    value: number,
  },
};

export type UpdateZoneThresholdValueMutationVariables = {
  input: UpdateZoneThresholdValueInput,
};

export type UpdateZoneThresholdValueMutation = {
  updateZoneThresholdValue:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  },
};

export type UploadDTFileMutationVariables = {
  customerId?: string | null,
  file: UploadFileInput,
  objectId: string,
};

export type UploadDTFileMutation = {
  uploadDTFile?:  {
    __typename: "DTFile",
    key?: string | null,
    url?: string | null,
  } | null,
};

export type UploadDTFilesMutationVariables = {
  customerId?: string | null,
  files: Array< UploadFileInput >,
  objectId: string,
};

export type UploadDTFilesMutation = {
  uploadDTFiles?:  Array< {
    __typename: "DTFile",
    key?: string | null,
    url?: string | null,
  } | null > | null,
};

export type ValidateEventMutationVariables = {
  input: ValidateEventInput,
};

export type ValidateEventMutation = {
  validateEvent:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type GetAllAlertsQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllAlertsQuery = {
  getAllAlerts:  {
    __typename: "PaginatedAlerts",
    items:  Array< {
      __typename: "Alert",
      alertName?: string | null,
      alertType?: string | null,
      createdBy: string,
      customService?: string | null,
      email?: string | null,
      id?: string | null,
      jsonObject?: string | null,
      location?: string | null,
      locationTag?: Array< string | null > | null,
      model?: string | null,
      node?: string | null,
      notificationSettingsJson?: string | null,
      phoneNumber?: string | null,
      subscribedLevelEntity?: string | null,
      tag?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllEmailsForSubscriptionEntityQueryVariables = {
  entity: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllEmailsForSubscriptionEntityQuery = {
  getAllEmailsForSubscriptionEntity:  {
    __typename: "PaginatedAllEmailsForSubscriptionEntity",
    items:  Array< {
      __typename: "AlertSubscription",
      email: string,
      entity?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllSubscriptionsQueryVariables = {
  entity: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllSubscriptionsQuery = {
  getAllSubscriptions:  {
    __typename: "PaginatedAlertSubscriptions",
    items:  Array< {
      __typename: "AlertSubscription",
      email: string,
      entity?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllVideosByClientQueryVariables = {
  input?: GetAllVideosByClientInput | null,
};

export type GetAllVideosByClientQuery = {
  getAllVideosByClient:  {
    __typename: "PaginatedLeakFinderVideos",
    items:  Array< {
      __typename: "LeakFinderVideo",
      annotations?: string | null,
      clientId: string,
      customerId: string,
      fov?: number | null,
      geometry: string,
      groupId: string,
      id: string,
      isVideoProcessed?: boolean | null,
      modelOutputS3Key?: string | null,
      notes?: string | null,
      outputMeasurement?: number | null,
      sourceS3Key: string,
      videoMethod: VideoMethod,
      videoName: string,
      videoState: VideoState,
      windspeed: number,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllVideosByGroupQueryVariables = {
  input: GetAllVideosByGroupInput,
};

export type GetAllVideosByGroupQuery = {
  getAllVideosByGroup:  {
    __typename: "PaginatedLeakFinderVideos",
    items:  Array< {
      __typename: "LeakFinderVideo",
      annotations?: string | null,
      clientId: string,
      customerId: string,
      fov?: number | null,
      geometry: string,
      groupId: string,
      id: string,
      isVideoProcessed?: boolean | null,
      modelOutputS3Key?: string | null,
      notes?: string | null,
      outputMeasurement?: number | null,
      sourceS3Key: string,
      videoMethod: VideoMethod,
      videoName: string,
      videoState: VideoState,
      windspeed: number,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllVideosByStateQueryVariables = {
  input: GetAllVideosByStateInput,
};

export type GetAllVideosByStateQuery = {
  getAllVideosByState:  {
    __typename: "PaginatedLeakFinderVideos",
    items:  Array< {
      __typename: "LeakFinderVideo",
      annotations?: string | null,
      clientId: string,
      customerId: string,
      fov?: number | null,
      geometry: string,
      groupId: string,
      id: string,
      isVideoProcessed?: boolean | null,
      modelOutputS3Key?: string | null,
      notes?: string | null,
      outputMeasurement?: number | null,
      sourceS3Key: string,
      videoMethod: VideoMethod,
      videoName: string,
      videoState: VideoState,
      windspeed: number,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllWildCardsForSubscriptionEntityQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllWildCardsForSubscriptionEntityQuery = {
  getAllWildCardsForSubscriptionEntity:  {
    __typename: "PaginatedWildCardsForSubscriptionEntity",
    items:  Array< {
      __typename: "AlertWildCardSubscription",
      email: string,
      entity: string,
      wildCard: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAnnotationsQueryVariables = {
  input: GetAnnotationsInput,
};

export type GetAnnotationsQuery = {
  getAnnotations:  {
    __typename: "PaginatedAnnotations",
    items:  Array< {
      __typename: "Annotation",
      annotateTanks?: boolean | null,
      annotationType: AnnotationType,
      bottomHidden?: number | null,
      deviceId?: string | null,
      equipmentType?: string | null,
      id: string,
      name: string,
      polygon?: string | null,
      serviceId: string,
      tankHeight?: number | null,
      tankNumber?: number | null,
      zoneId?: string | null,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetCategoriesByCustomerQueryVariables = {
  input: GetCategoriesByCustomerInput,
};

export type GetCategoriesByCustomerQuery = {
  getCategoriesByCustomer:  {
    __typename: "PaginatedCategories",
    items:  Array< {
      __typename: "Category",
      categoryName: string,
      id: string,
      isAlertEnabled: boolean,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetCategoryByIdQueryVariables = {
  input: GetCategoryInput,
};

export type GetCategoryByIdQuery = {
  getCategoryById?:  {
    __typename: "Category",
    categoryName: string,
    id: string,
    isAlertEnabled: boolean,
  } | null,
};

export type GetClientByIdQueryVariables = {
  input: GetClientInput,
};

export type GetClientByIdQuery = {
  getClientById?:  {
    __typename: "Client",
    clientName: string,
    customerId: string,
    id: string,
  } | null,
};

export type GetClientsByCustomerQueryVariables = {
  input: GetClientsByCustomerInput,
};

export type GetClientsByCustomerQuery = {
  getClientsByCustomer:  {
    __typename: "PaginatedClients",
    items:  Array< {
      __typename: "Client",
      clientName: string,
      customerId: string,
      id: string,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetCustomerQueryVariables = {
  customerExternalId: string,
  customerId: string,
};

export type GetCustomerQuery = {
  getCustomer:  {
    __typename: "Customer",
    aggAlertingData?: string | null,
    allowLeakFinder?: AllowLeakFinder | null,
    auditCustomers?: string | null,
    configuration?: string | null,
    domains?: Array< string > | null,
    externalId: string,
    id: string,
    name?: string | null,
    pointOfContact?: string | null,
    totalLeakFinderLimit?: number | null,
    usageLeakFinderCount?: number | null,
  },
};

export type GetCustomerCountersQueryVariables = {
  customerExternalId: string,
  customerId: string,
};

export type GetCustomerCountersQuery = {
  getCustomerCounters:  {
    __typename: "CustomerCountersType",
    allowLeakFinder?: AllowLeakFinder | null,
    id: string,
    totalLeakFinderLimit?: number | null,
    usageLeakFinderCount?: number | null,
  },
};

export type GetCustomerDTObjectQueryVariables = {
  customerId?: string | null,
  objectId: string,
};

export type GetCustomerDTObjectQuery = {
  getCustomerDTObject?:  {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null,
};

export type GetCustomerDTObjectsQueryVariables = {
  customerId?: string | null,
};

export type GetCustomerDTObjectsQuery = {
  getCustomerDTObjects?:  Array< {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null > | null,
};

export type GetCustomerHealthQueryVariables = {
  customerId?: string | null,
};

export type GetCustomerHealthQuery = {
  getCustomerHealth?: string | null,
};

export type GetCustomersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCustomersQuery = {
  getCustomers:  {
    __typename: "PaginatedCustomers",
    items:  Array< {
      __typename: "Customer",
      aggAlertingData?: string | null,
      allowLeakFinder?: AllowLeakFinder | null,
      auditCustomers?: string | null,
      configuration?: string | null,
      domains?: Array< string > | null,
      externalId: string,
      id: string,
      name?: string | null,
      pointOfContact?: string | null,
      totalLeakFinderLimit?: number | null,
      usageLeakFinderCount?: number | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetDTCustomerProjectsQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDTCustomerProjectsQuery = {
  getDTCustomerProjects?:  {
    __typename: "PaginatedDTProjects",
    items:  Array< {
      __typename: "DTProject",
      configURL?: string | null,
      customerId?: string | null,
      displayName?: string | null,
      id?: string | null,
      kmzLayerURL?: string | null,
      lastModificationDate?: string | null,
      lastModifierUserName?: string | null,
      measurementSystem?: string | null,
      projectId?: string | null,
      withKMZLayer?: boolean | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetDTFileQueryVariables = {
  key?: string | null,
};

export type GetDTFileQuery = {
  getDTFile?:  {
    __typename: "DTFile",
    key?: string | null,
    url?: string | null,
  } | null,
};

export type GetDTFilesQueryVariables = {
  keys?: Array< string | null > | null,
};

export type GetDTFilesQuery = {
  getDTFiles?:  Array< {
    __typename: "DTFile",
    key?: string | null,
    url?: string | null,
  } | null > | null,
};

export type GetDTObjectQueryVariables = {
  objectId: string,
};

export type GetDTObjectQuery = {
  getDTObject?:  {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null,
};

export type GetDTObjectsQuery = {
  getDTObjects?:  Array< {
    __typename: "DTObject",
    filesInfo:  Array< {
      __typename: "DTObjectFileInfo",
      contentType: string,
      id: string,
      key: string,
      originalFileName: string,
      useType: string,
    } >,
    id: string,
    isCustomerObject: boolean,
    objectId: string,
    objectManufacturerName: string,
    objectName: string,
    objectType: string,
    version?: number | null,
  } | null > | null,
};

export type GetDTProjectQueryVariables = {
  customerId: string,
  projectId: string,
};

export type GetDTProjectQuery = {
  getDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    kmzLayerURL?: string | null,
    lastModificationDate?: string | null,
    lastModifierUserName?: string | null,
    measurementSystem?: string | null,
    projectId?: string | null,
    withKMZLayer?: boolean | null,
  } | null,
};

export type GetDTProjectsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDTProjectsQuery = {
  getDTProjects?:  {
    __typename: "PaginatedDTProjects",
    items:  Array< {
      __typename: "DTProject",
      configURL?: string | null,
      customerId?: string | null,
      displayName?: string | null,
      id?: string | null,
      kmzLayerURL?: string | null,
      lastModificationDate?: string | null,
      lastModifierUserName?: string | null,
      measurementSystem?: string | null,
      projectId?: string | null,
      withKMZLayer?: boolean | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetDTProjectsByUsedDTObjectQueryVariables = {
  customerId: string,
  objectId: string,
};

export type GetDTProjectsByUsedDTObjectQuery = {
  getDTProjectsByUsedDTObject?:  Array< {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    kmzLayerURL?: string | null,
    lastModificationDate?: string | null,
    lastModifierUserName?: string | null,
    measurementSystem?: string | null,
    projectId?: string | null,
    withKMZLayer?: boolean | null,
  } | null > | null,
};

export type GetDeviceByIdQueryVariables = {
  customerId: string,
  deviceId: string,
};

export type GetDeviceByIdQuery = {
  getDeviceById?:  {
    __typename: "Device",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceData",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthData",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type GetDeviceMakeByIdQueryVariables = {
  makeModelId: string,
};

export type GetDeviceMakeByIdQuery = {
  getDeviceMakeById:  {
    __typename: "DeviceMake",
    defaultUserPwd?: string | null,
    deviceTypeShortName?: string | null,
    hasPanTilt: boolean,
    hasZoom?: boolean | null,
    id: string,
    model: string,
    modelsToRun: Array< string | null >,
    name: string,
    rtspTemplate: string,
    type: string,
  },
};

export type GetDeviceMakeByModelQueryVariables = {
  makeModelName: string,
};

export type GetDeviceMakeByModelQuery = {
  getDeviceMakeByModel:  {
    __typename: "DeviceMake",
    defaultUserPwd?: string | null,
    deviceTypeShortName?: string | null,
    hasPanTilt: boolean,
    hasZoom?: boolean | null,
    id: string,
    model: string,
    modelsToRun: Array< string | null >,
    name: string,
    rtspTemplate: string,
    type: string,
  },
};

export type GetDevicesQueryVariables = {
  customerId: string,
  limit?: number | null,
  locationId?: string | null,
  nextToken?: string | null,
  nodeId?: string | null,
};

export type GetDevicesQuery = {
  getDevices:  {
    __typename: "PaginatedDevices",
    items:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetDevicesByLocationQueryVariables = {
  locationId: string,
  nextToken?: string | null,
};

export type GetDevicesByLocationQuery = {
  getDevicesByLocation?:  {
    __typename: "PaginatedDevices",
    items:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetDevicesByNodeIdQueryVariables = {
  nextToken?: string | null,
  nodeId: string,
};

export type GetDevicesByNodeIdQuery = {
  getDevicesByNodeId?:  {
    __typename: "PaginatedDevices",
    items:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetDevicesMakesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDevicesMakesQuery = {
  getDevicesMakes:  {
    __typename: "PaginatedDevicesMakes",
    items:  Array< {
      __typename: "DeviceMake",
      defaultUserPwd?: string | null,
      deviceTypeShortName?: string | null,
      hasPanTilt: boolean,
      hasZoom?: boolean | null,
      id: string,
      model: string,
      modelsToRun: Array< string | null >,
      name: string,
      rtspTemplate: string,
      type: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetEventsPendingHumanValidationQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetEventsPendingHumanValidationQuery = {
  getEventsPendingHumanValidation:  {
    __typename: "PaginatedHumanValidationEvents",
    items:  Array< {
      __typename: "HumanValidationEvent",
      audited_by?: string | null,
      auditorsExplanation?: string | null,
      customerId: string,
      data?: string | null,
      eventTime: string,
      explanation?: string | null,
      id: string,
      locationId: string,
      nodeId: string,
      serviceId: string,
      transitionInfo?: string | null,
      validated_by?: string | null,
      validationState?: ValidationState | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetFormQueryVariables = {
  customerId: string,
  itemId: string,
};

export type GetFormQuery = {
  getForm:  {
    __typename: "Form",
    customerId?: string | null,
    fields?:  Array< {
      __typename: "Field",
      entries?: Array< string | null > | null,
      itemToQuery?: string | null,
      name: string,
      type: string,
    } | null > | null,
    id?: string | null,
  },
};

export type GetGroupByClientIdQueryVariables = {
  input: GetGroupByClientIdInput,
};

export type GetGroupByClientIdQuery = {
  getGroupByClientId?:  {
    __typename: "Group",
    clientId: string,
    createdAt: number,
    customerId: string,
    groupName: string,
    id: string,
  } | null,
};

export type GetGroupsByClientQueryVariables = {
  input: GetGroupsByClientInput,
};

export type GetGroupsByClientQuery = {
  getGroupsByClient:  {
    __typename: "PaginatedGroups",
    items:  Array< {
      __typename: "Group",
      clientId: string,
      createdAt: number,
      customerId: string,
      groupName: string,
      id: string,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetHlsUrlQueryVariables = {
  streamName: string,
};

export type GetHlsUrlQuery = {
  getHlsUrl?: string | null,
};

export type GetHumanValidatedEventsForAuditingQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetHumanValidatedEventsForAuditingQuery = {
  getHumanValidatedEventsForAuditing:  {
    __typename: "PaginatedHumanValidationEvents",
    items:  Array< {
      __typename: "HumanValidationEvent",
      audited_by?: string | null,
      auditorsExplanation?: string | null,
      customerId: string,
      data?: string | null,
      eventTime: string,
      explanation?: string | null,
      id: string,
      locationId: string,
      nodeId: string,
      serviceId: string,
      transitionInfo?: string | null,
      validated_by?: string | null,
      validationState?: ValidationState | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetLicensePlateQueryVariables = {
  categoryId: string,
  licensePlateId: string,
};

export type GetLicensePlateQuery = {
  getLicensePlate:  {
    __typename: "LicensePlate",
    categoryId: string,
    company?: string | null,
    driverName?: string | null,
    id: string,
    licensePlate: string,
  },
};

export type GetLicensePlatesByCustomerQueryVariables = {
  input: GetLicensePlatesByCustomerInput,
};

export type GetLicensePlatesByCustomerQuery = {
  getLicensePlatesByCustomer:  {
    __typename: "PaginatedLicensePlates",
    items:  Array< {
      __typename: "LicensePlate",
      categoryId: string,
      company?: string | null,
      driverName?: string | null,
      id: string,
      licensePlate: string,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetLocationByIdQueryVariables = {
  customerId: string,
  locationId: string,
};

export type GetLocationByIdQuery = {
  getLocationById:  {
    __typename: "Location",
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id: string,
    locationData?: string | null,
    name: string,
    referenceId?: string | null,
    tags?:  Array< {
      __typename: "TagOfAssociation",
      associationId: string,
      id: string,
      tagName: string,
    } > | null,
    timeZone?: string | null,
  },
};

export type GetLocationsQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetLocationsQuery = {
  getLocations:  {
    __typename: "PaginatedLocations",
    items:  Array< {
      __typename: "Location",
      devices?:  Array< {
        __typename: "Device",
        cameraIpAddress?: string | null,
        customerId: string,
        deviceData:  {
          __typename: "DeviceData",
          hasPanTilt: boolean,
          hasRecordVideo: boolean,
          hasZoom: boolean,
          keyFrame: string,
          pan: string,
          panTiltIP: string,
          protocol: string,
          recentFrame: string,
          rtspHost: string,
          sourceVideo: string,
          tilt: string,
          zoom: string,
        },
        healthData:  {
          __typename: "DeviceHealthData",
          isOnline: boolean,
          lastAlive: string,
          totalDowntime: string,
          totalUptime: string,
          uptimeDate: string,
          uptimePercent: number,
        },
        id: string,
        locationId: string,
        locationName?: string | null,
        makeModelId: string,
        name: string,
        nodeId: string,
        nodeName?: string | null,
        status?: string | null,
      } > | null,
      id: string,
      locationData?: string | null,
      name: string,
      referenceId?: string | null,
      tags?:  Array< {
        __typename: "TagOfAssociation",
        associationId: string,
        id: string,
        tagName: string,
      } > | null,
      timeZone?: string | null,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetLocationsByTagQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
  tag: string,
};

export type GetLocationsByTagQuery = {
  getLocationsByTag:  {
    __typename: "PaginatedLocations",
    items:  Array< {
      __typename: "Location",
      devices?:  Array< {
        __typename: "Device",
        cameraIpAddress?: string | null,
        customerId: string,
        deviceData:  {
          __typename: "DeviceData",
          hasPanTilt: boolean,
          hasRecordVideo: boolean,
          hasZoom: boolean,
          keyFrame: string,
          pan: string,
          panTiltIP: string,
          protocol: string,
          recentFrame: string,
          rtspHost: string,
          sourceVideo: string,
          tilt: string,
          zoom: string,
        },
        healthData:  {
          __typename: "DeviceHealthData",
          isOnline: boolean,
          lastAlive: string,
          totalDowntime: string,
          totalUptime: string,
          uptimeDate: string,
          uptimePercent: number,
        },
        id: string,
        locationId: string,
        locationName?: string | null,
        makeModelId: string,
        name: string,
        nodeId: string,
        nodeName?: string | null,
        status?: string | null,
      } > | null,
      id: string,
      locationData?: string | null,
      name: string,
      referenceId?: string | null,
      tags?:  Array< {
        __typename: "TagOfAssociation",
        associationId: string,
        id: string,
        tagName: string,
      } > | null,
      timeZone?: string | null,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetNodeByIdQueryVariables = {
  nodeId: string,
};

export type GetNodeByIdQuery = {
  getNodeById?:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  } | null,
};

export type GetNodesQueryVariables = {
  limit?: number | null,
  locationId: string,
  nextToken?: string | null,
};

export type GetNodesQuery = {
  getNodes:  {
    __typename: "PaginatedNodes",
    items:  Array< {
      __typename: "Node",
      customerId?: string | null,
      deviceData?: string | null,
      devices?:  Array< {
        __typename: "Device",
        cameraIpAddress?: string | null,
        customerId: string,
        deviceData:  {
          __typename: "DeviceData",
          hasPanTilt: boolean,
          hasRecordVideo: boolean,
          hasZoom: boolean,
          keyFrame: string,
          pan: string,
          panTiltIP: string,
          protocol: string,
          recentFrame: string,
          rtspHost: string,
          sourceVideo: string,
          tilt: string,
          zoom: string,
        },
        healthData:  {
          __typename: "DeviceHealthData",
          isOnline: boolean,
          lastAlive: string,
          totalDowntime: string,
          totalUptime: string,
          uptimeDate: string,
          uptimePercent: number,
        },
        id: string,
        locationId: string,
        locationName?: string | null,
        makeModelId: string,
        name: string,
        nodeId: string,
        nodeName?: string | null,
        status?: string | null,
      } > | null,
      id?: string | null,
      isOnline?: boolean | null,
      level?: NodeLevel | null,
      locationId?: string | null,
      nodeIP?: string | null,
      nodeId?: string | null,
      nodeName?: string | null,
      onboardCommand?: string | null,
      referenceId?: string | null,
      serviceData?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        id: string,
        tagName: string,
      } | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetNodesByCustomerQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetNodesByCustomerQuery = {
  getNodesByCustomer:  {
    __typename: "PaginatedNodes",
    items:  Array< {
      __typename: "Node",
      customerId?: string | null,
      deviceData?: string | null,
      devices?:  Array< {
        __typename: "Device",
        cameraIpAddress?: string | null,
        customerId: string,
        deviceData:  {
          __typename: "DeviceData",
          hasPanTilt: boolean,
          hasRecordVideo: boolean,
          hasZoom: boolean,
          keyFrame: string,
          pan: string,
          panTiltIP: string,
          protocol: string,
          recentFrame: string,
          rtspHost: string,
          sourceVideo: string,
          tilt: string,
          zoom: string,
        },
        healthData:  {
          __typename: "DeviceHealthData",
          isOnline: boolean,
          lastAlive: string,
          totalDowntime: string,
          totalUptime: string,
          uptimeDate: string,
          uptimePercent: number,
        },
        id: string,
        locationId: string,
        locationName?: string | null,
        makeModelId: string,
        name: string,
        nodeId: string,
        nodeName?: string | null,
        status?: string | null,
      } > | null,
      id?: string | null,
      isOnline?: boolean | null,
      level?: NodeLevel | null,
      locationId?: string | null,
      nodeIP?: string | null,
      nodeId?: string | null,
      nodeName?: string | null,
      onboardCommand?: string | null,
      referenceId?: string | null,
      serviceData?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        id: string,
        tagName: string,
      } | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetNodesByTagQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  tagId: string,
};

export type GetNodesByTagQuery = {
  getNodesByTag:  {
    __typename: "PaginatedNodes",
    items:  Array< {
      __typename: "Node",
      customerId?: string | null,
      deviceData?: string | null,
      devices?:  Array< {
        __typename: "Device",
        cameraIpAddress?: string | null,
        customerId: string,
        deviceData:  {
          __typename: "DeviceData",
          hasPanTilt: boolean,
          hasRecordVideo: boolean,
          hasZoom: boolean,
          keyFrame: string,
          pan: string,
          panTiltIP: string,
          protocol: string,
          recentFrame: string,
          rtspHost: string,
          sourceVideo: string,
          tilt: string,
          zoom: string,
        },
        healthData:  {
          __typename: "DeviceHealthData",
          isOnline: boolean,
          lastAlive: string,
          totalDowntime: string,
          totalUptime: string,
          uptimeDate: string,
          uptimePercent: number,
        },
        id: string,
        locationId: string,
        locationName?: string | null,
        makeModelId: string,
        name: string,
        nodeId: string,
        nodeName?: string | null,
        status?: string | null,
      } > | null,
      id?: string | null,
      isOnline?: boolean | null,
      level?: NodeLevel | null,
      locationId?: string | null,
      nodeIP?: string | null,
      nodeId?: string | null,
      nodeName?: string | null,
      onboardCommand?: string | null,
      referenceId?: string | null,
      serviceData?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        id: string,
        tagName: string,
      } | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    file?: string | null,
    id: string,
    name?: string | null,
  } | null,
};

export type GetRolesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRolesQuery = {
  getRoles:  {
    __typename: "PaginatedRoles",
    items:  Array< {
      __typename: "Role",
      id: string,
      name: string,
      userGroup?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetSendEmailQueryVariables = {
  email?: string | null,
};

export type GetSendEmailQuery = {
  getSendEmail?: string | null,
};

export type GetServiceByIdQueryVariables = {
  input: GetServiceInput,
};

export type GetServiceByIdQuery = {
  getServiceById:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  },
};

export type GetServicesQueryVariables = {
  customerId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetServicesQuery = {
  getServices:  {
    __typename: "PaginatedServices",
    items:  Array< {
      __typename: "Service",
      configuration?: string | null,
      customerId?: string | null,
      deviceId?: string | null,
      healthData?: string | null,
      id?: string | null,
      isRunning?: boolean | null,
      locationId?: string | null,
      nodeId?: string | null,
      serviceType?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetServicesByDeviceQueryVariables = {
  deviceId: string,
  nextToken?: string | null,
};

export type GetServicesByDeviceQuery = {
  getServicesByDevice:  {
    __typename: "PaginatedServices",
    items:  Array< {
      __typename: "Service",
      configuration?: string | null,
      customerId?: string | null,
      deviceId?: string | null,
      healthData?: string | null,
      id?: string | null,
      isRunning?: boolean | null,
      locationId?: string | null,
      nodeId?: string | null,
      serviceType?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetServicesByLocationQueryVariables = {
  locationId: string,
  nextToken?: string | null,
};

export type GetServicesByLocationQuery = {
  getServicesByLocation:  {
    __typename: "PaginatedServices",
    items:  Array< {
      __typename: "Service",
      configuration?: string | null,
      customerId?: string | null,
      deviceId?: string | null,
      healthData?: string | null,
      id?: string | null,
      isRunning?: boolean | null,
      locationId?: string | null,
      nodeId?: string | null,
      serviceType?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetShadowNodeQueryVariables = {
  shadowName?: string | null,
  thingName?: string | null,
};

export type GetShadowNodeQuery = {
  getShadowNode?:  {
    __typename: "Shadow",
    shadow?: string | null,
  } | null,
};

export type GetSignedUrlActionQueryVariables = {
  input: GetSignedUrlActionInput,
};

export type GetSignedUrlActionQuery = {
  getSignedUrlAction?: string | null,
};

export type GetStatisticsDataQueryVariables = {
  input?: StatisticsDataInput | null,
};

export type GetStatisticsDataQuery = {
  getStatisticsData?: string | null,
};

export type GetSubscriptionsForEmailQueryVariables = {
  email: string,
  entity: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSubscriptionsForEmailQuery = {
  getSubscriptionsForEmail:  {
    __typename: "PaginatedSubscriptionsForEmail",
    items:  Array< {
      __typename: "AlertSubscription",
      email: string,
      entity?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetTagAssociationsQueryVariables = {
  input: GetTagAssociationsInput,
};

export type GetTagAssociationsQuery = {
  getTagAssociations:  {
    __typename: "PaginatedTagAssociations",
    items:  Array< {
      __typename: "TagOfAssociation",
      associationId: string,
      id: string,
      tagName: string,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetTagsByCustomerQueryVariables = {
  input: GetTagsByCustomerInput,
};

export type GetTagsByCustomerQuery = {
  getTagsByCustomer:  {
    __typename: "PaginatedTags",
    items:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetTimestreamDataQueryVariables = {
  input?: TimestreamDataInput | null,
};

export type GetTimestreamDataQuery = {
  getTimestreamData?: string | null,
};

export type GetUserAlertsQueryVariables = {
  customerId: string,
  email: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserAlertsQuery = {
  getUserAlerts:  {
    __typename: "PaginatedAlerts",
    items:  Array< {
      __typename: "Alert",
      alertName?: string | null,
      alertType?: string | null,
      createdBy: string,
      customService?: string | null,
      email?: string | null,
      id?: string | null,
      jsonObject?: string | null,
      location?: string | null,
      locationTag?: Array< string | null > | null,
      model?: string | null,
      node?: string | null,
      notificationSettingsJson?: string | null,
      phoneNumber?: string | null,
      subscribedLevelEntity?: string | null,
      tag?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetUserByIdQuery = {
  getUserById:  {
    __typename: "User",
    customer_id: string,
    email: string,
    externalId: string,
    firstName: string,
    id: string,
    lastName: string,
    userSettings?: string | null,
    user_role: string,
  },
};

export type GetZoneQueryVariables = {
  customerId: string,
  deviceId: string,
  zoneId: string,
};

export type GetZoneQuery = {
  getZone:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  },
};

export type GetZoneThresholdByIdQueryVariables = {
  input: GetZoneThresholdInput,
};

export type GetZoneThresholdByIdQuery = {
  getZoneThresholdById?:  {
    __typename: "ZoneThreshold",
    id: string,
    name: string,
    value: number,
  } | null,
};

export type GetZonesQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
  serviceId: string,
};

export type GetZonesQuery = {
  getZones:  {
    __typename: "PaginatedZones",
    items:  Array< {
      __typename: "Zone",
      alertData?: string | null,
      deviceId: string,
      exclusions?: string | null,
      id: string,
      inspectTime?: string | null,
      keyFrame?: string | null,
      name: string,
      nodeId: string,
      pan: string,
      serviceId?: string | null,
      shouldNotify?: boolean | null,
      status: boolean,
      threshold?:  {
        __typename: "ZoneThreshold",
        id: string,
        name: string,
        value: number,
      } | null,
      tilt: string,
      zoom?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetZonesByDeviceQueryVariables = {
  customerId: string,
  deviceId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetZonesByDeviceQuery = {
  getZonesByDevice:  {
    __typename: "PaginatedZones",
    items:  Array< {
      __typename: "Zone",
      alertData?: string | null,
      deviceId: string,
      exclusions?: string | null,
      id: string,
      inspectTime?: string | null,
      keyFrame?: string | null,
      name: string,
      nodeId: string,
      pan: string,
      serviceId?: string | null,
      shouldNotify?: boolean | null,
      status: boolean,
      threshold?:  {
        __typename: "ZoneThreshold",
        id: string,
        name: string,
        value: number,
      } | null,
      tilt: string,
      zoom?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetZonesThresholdsByCustomerQueryVariables = {
  input: GetZonesThresholdsByCustomerInput,
};

export type GetZonesThresholdsByCustomerQuery = {
  getZonesThresholdsByCustomer:  {
    __typename: "PaginatedZonesThresholds",
    items:  Array< {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type ListenToAddZoneSubscriptionVariables = {
  customerId?: string | null,
};

export type ListenToAddZoneSubscription = {
  listenToAddZone?:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  } | null,
};

export type ListenToDeleteZoneSubscriptionVariables = {
  id?: string | null,
};

export type ListenToDeleteZoneSubscription = {
  listenToDeleteZone?:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  } | null,
};

export type ListenToDeviceDataSubscriptionVariables = {
  customerId: string,
};

export type ListenToDeviceDataSubscription = {
  listenToDeviceData?:  {
    __typename: "DeviceIam",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceDataIam",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthDataIam",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type ListenToDeviceHealthDataSubscriptionVariables = {
  customerId: string,
};

export type ListenToDeviceHealthDataSubscription = {
  listenToDeviceHealthData?:  {
    __typename: "DeviceIam",
    cameraIpAddress?: string | null,
    customerId: string,
    deviceData:  {
      __typename: "DeviceDataIam",
      hasPanTilt: boolean,
      hasRecordVideo: boolean,
      hasZoom: boolean,
      keyFrame: string,
      pan: string,
      panTiltIP: string,
      protocol: string,
      recentFrame: string,
      rtspHost: string,
      sourceVideo: string,
      tilt: string,
      zoom: string,
    },
    healthData:  {
      __typename: "DeviceHealthDataIam",
      isOnline: boolean,
      lastAlive: string,
      totalDowntime: string,
      totalUptime: string,
      uptimeDate: string,
      uptimePercent: number,
    },
    id: string,
    locationId: string,
    locationName?: string | null,
    makeModelId: string,
    name: string,
    nodeId: string,
    nodeName?: string | null,
    status?: string | null,
  } | null,
};

export type ListenToEdgeClientMessageSubscriptionVariables = {
  id?: string | null,
};

export type ListenToEdgeClientMessageSubscription = {
  listenToEdgeClientMessage:  {
    __typename: "EdgeClientMessageResponse",
    id: string,
    payload: string,
    payloadType: PayloadType,
    statusCode: number,
    timestamp: string,
    topic: string,
  },
};

export type ListenToHumanValidationEventsSubscription = {
  listenToHumanValidationEvents?:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  } | null,
};

export type ListenToNodeFilesSubscriptionVariables = {
  nodeId: string,
};

export type ListenToNodeFilesSubscription = {
  listenToNodeFiles?:  {
    __typename: "NodeFile",
    downloadURL: string,
    file?: string | null,
    internalNodeId: string,
  } | null,
};

export type ListenToNodeMessagesSubscriptionVariables = {
  nodeId: string,
};

export type ListenToNodeMessagesSubscription = {
  listenToNodeMessages?:  {
    __typename: "NodeMessage",
    internalNodeId: string,
    message?: string | null,
  } | null,
};

export type ListenToNodeOnlineStatusSubscriptionVariables = {
  customerId: string,
};

export type ListenToNodeOnlineStatusSubscription = {
  listenToNodeOnlineStatus?:  {
    __typename: "NodeIam",
    customerId?: string | null,
    deviceData?: string | null,
    devices?:  Array< {
      __typename: "Device",
      cameraIpAddress?: string | null,
      customerId: string,
      deviceData:  {
        __typename: "DeviceData",
        hasPanTilt: boolean,
        hasRecordVideo: boolean,
        hasZoom: boolean,
        keyFrame: string,
        pan: string,
        panTiltIP: string,
        protocol: string,
        recentFrame: string,
        rtspHost: string,
        sourceVideo: string,
        tilt: string,
        zoom: string,
      },
      healthData:  {
        __typename: "DeviceHealthData",
        isOnline: boolean,
        lastAlive: string,
        totalDowntime: string,
        totalUptime: string,
        uptimeDate: string,
        uptimePercent: number,
      },
      id: string,
      locationId: string,
      locationName?: string | null,
      makeModelId: string,
      name: string,
      nodeId: string,
      nodeName?: string | null,
      status?: string | null,
    } > | null,
    id?: string | null,
    isOnline?: boolean | null,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeIP?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    referenceId?: string | null,
    serviceData?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      id: string,
      tagName: string,
    } | null > | null,
  } | null,
};

export type ListenToServiceHealthDataSubscriptionVariables = {
  customerId: string,
};

export type ListenToServiceHealthDataSubscription = {
  listenToServiceHealthData?:  {
    __typename: "ServiceIam",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    healthData?: string | null,
    id?: string | null,
    isRunning?: boolean | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type ListenToUpdateZoneSubscriptionVariables = {
  id?: string | null,
};

export type ListenToUpdateZoneSubscription = {
  listenToUpdateZone?:  {
    __typename: "Zone",
    alertData?: string | null,
    deviceId: string,
    exclusions?: string | null,
    id: string,
    inspectTime?: string | null,
    keyFrame?: string | null,
    name: string,
    nodeId: string,
    pan: string,
    serviceId?: string | null,
    shouldNotify?: boolean | null,
    status: boolean,
    threshold?:  {
      __typename: "ZoneThreshold",
      id: string,
      name: string,
      value: number,
    } | null,
    tilt: string,
    zoom?: string | null,
  } | null,
};
