import { useBase64 } from "../../../../../common/hooks/useBase64";
import useLocationFromCache from "../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../common/utils/timestampUtils";
import { errorNotification } from "../../../../../common/variables/notification";
import useCSVDownloader from "../../../../../leak-finder/hooks/useCSVDownloader";
import useConvertArrayToCSV from "../../../../../leak-finder/hooks/useConvertArrayToCSV";
import { useGetFireEventsToExport } from "../fire/hooks/useGetFireEventsToExport";

const useExportFireSmokeHardhatData = () => {
  const { getBase64MediaViewerPageLink } = useBase64();

  const { downloadCSV } = useCSVDownloader();

  const { convertArrayToCSV } = useConvertArrayToCSV();

  const { getCachedLocation } = useLocationFromCache();

  const { getCsvDataToExport, loading } = useGetFireEventsToExport();

  const fireSmokeHHColumns = [
    { field: "location", headerName: "Location" },
    { field: "cameraId", headerName: "Device ID" },
    { field: "confidence", headerName: "Confidence" },
    { field: "timestamp", headerName: "Timestamp" },
    { field: "mediaOutput", headerName: "Media" },
  ];

  const exportFireSmokeHardhatData = async (model: string) => {
    try {
      const rows = await getCsvDataToExport();

      if (rows.length > 0) {
        const updatedRows = rows.map(row => {
          const newRow = { ...row };
          const locationParts = newRow.location.includes("#L#")
            ? getCachedLocation(newRow.location ?? "")?.name ?? ""
            : newRow.location;

          if (locationParts.length > 1) {
            newRow.location = locationParts;
          }

          newRow.timestamp = formatTimestamp(newRow.timestamp);

          if (newRow.mediaOutput) {
            newRow.mediaOutput = getBase64MediaViewerPageLink(
              newRow.mediaOutput
            );
          }

          return newRow;
        });

        const csvString = convertArrayToCSV(updatedRows, fireSmokeHHColumns);

        downloadCSV(csvString, `${model}_events.csv`);
      }
    } catch (error) {
      errorNotification("No data available to export");

      console.error("Error exporting fire, smoke, or hardhat data:", error);
    }
  };

  return { exportFireSmokeHardhatData, loading };
};

export default useExportFireSmokeHardhatData;
