import { useBase64 } from "../../../../../common/hooks/useBase64";
import useLocationFromCache from "../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../common/utils/timestampUtils";
import { errorNotification } from "../../../../../common/variables/notification";
import useCSVDownloader from "../../../../../leak-finder/hooks/useCSVDownloader";
import useConvertArrayToCSV from "../../../../../leak-finder/hooks/useConvertArrayToCSV";
import { useGetTlmEventsToExport } from "../tlm/hooks/useGetTlmEventsToExport";

const useExportTlmData = () => {
  const { getBase64MediaViewerPageLink } = useBase64();

  const { downloadCSV } = useCSVDownloader();

  const { convertArrayToCSV } = useConvertArrayToCSV();

  const { getCachedLocation } = useLocationFromCache();

  const { getCsvDataToExport, loading } = useGetTlmEventsToExport();

  const tlmColumns = [
    { field: "location", headerName: "Location" },
    { field: "timestamp", headerName: "Timestamp" },
    { field: "tanksData", headerName: "" },
    { field: "cameraId", headerName: "Device ID" },
    { field: "mediaOutput", headerName: "Media" },
  ];

  /**
   * Function to parse tanksData and format it as a single string
   * @param {string} tanksData
   * @return {string} formatted string
   */
  const formatTanksData = (tanksData: string): string => {
    try {
      const tanksArray = JSON.parse(tanksData);

      return tanksArray
        .map(
          (tank: {
            TankID: string;
            TankNumber: string;
            TankLevel: string;
            LevelPercent: string;
          }) => {
            return `TankID: ${tank.TankID}, TankNumber: ${tank.TankNumber}, TankLevel: ${tank.TankLevel}, LevelPercent: ${tank.LevelPercent}`;
          }
        )
        .join(" | ");
    } catch (error) {
      console.error("Error parsing tanksData:", error);

      return tanksData;
    }
  };

  const exportTlmData = async () => {
    try {
      const tlmRows = await getCsvDataToExport();

      if (tlmRows.length > 0) {
        const updatedRows = tlmRows.map(row => {
          const newRow = { ...row };
          const locationParts = newRow.location.includes("#L#")
            ? getCachedLocation(newRow.location ?? "")?.name ?? ""
            : newRow.location;

          if (locationParts.length > 1) {
            newRow.location = locationParts;
          }

          newRow.timestamp = formatTimestamp(newRow.timestamp);

          newRow.tanksData = formatTanksData(newRow.tanksData)
            .replace(/[\n\r]/g, "")
            .trim();

          if (newRow.mediaOutput) {
            newRow.mediaOutput = getBase64MediaViewerPageLink(
              newRow.mediaOutput
            );
          }

          return newRow;
        });

        const csvString = convertArrayToCSV(updatedRows, tlmColumns);

        downloadCSV(csvString, `tlm_events.csv`);
      }
    } catch (error) {
      errorNotification("No data available to export");

      console.error("Error exporting tank level data:", error);
    }
  };

  return { exportTlmData, loading };
};

export default useExportTlmData;
