import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { Stack } from "@mui/material";

import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { useStreamProcesses } from "../../../common/hooks/useStreamProcesses";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import CreateZoneContent from "./CreateZoneContent";
import CreateZonePageHeader from "./CreateZonePageHeader";

const CreateZonePage = (): JSX.Element => {
  const { state } = useLocation();

  const device = state?.deviceDatum as IDeviceTableRow;
  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );

  const { fetchDevice, getServicesLoading } = useGetDeviceByUrl();
  const { startStreaming, stopStreaming } = useStreamProcesses();

  useEffect((): void => {
    fetchDevice().then((device): void => {
      if (device) {
        setDeviceDetail(device);

        startStreaming(device);
      }
    });
  }, [getServicesLoading]);

  useEffect((): void => {
    if (deviceDetail) {
      stopStreaming(deviceDetail);
    }
  }, [deviceDetail]);

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: device?.name,
      path: "/device/" + encodeURIComponent(device.rowId),
    },
    { label: "Create Zone" },
  ];

  return (
    <Stack spacing={2} direction="column">
      <BreadcrumbNavigation items={breadcrumbItems} />
      <CreateZonePageHeader />
      <CreateZoneContent />
    </Stack>
  );
};

export default CreateZonePage;
