import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";
import { IGateGuardEventsTableRow } from "../../common/events.models";
import EventSnapshotTableCell from "../../common/EventSnapshotTableCell";
import TableCellTruncated from "../../../../../../common/components/table/TableCellTruncated";

export const useGetGateGuardEventsTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef<IGateGuardEventsTableRow>[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: "mediaOutput",
      headerName: "EVENT",
      flex: 1,
      minWidth: 120,
      width: 120,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: EventSnapshotTableCell,
    },
    {
      field: "licensePlate",
      headerName: "PLATE NUMBER",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row?.licensePlate}
            text={params?.row?.licensePlate}
            TypographyProps={{
              variant: "body2Bold",
            }}
          />
        );
      },
    },
    {
      field: "driver",
      headerName: "DRIVER",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row?.driver_name ?? ""}
            text={params?.row?.driver_name ?? "-"}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "company",
      headerName: "COMPANY",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row?.company_name ?? ""}
            text={params?.row?.company_name ?? "-"}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "category",
      headerName: "CATEGORY",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const categoryName =
          params?.row?.category_name === "Unknown"
            ? "-"
            : params?.row?.category_name;

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={categoryName}
            text={categoryName}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "location",
      headerName: "LOCATION",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (locationId?: string | null): string => {
        const locationName = getCachedLocationName(locationId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row?.location);

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={locationName}
            text={locationName}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row.cameraId}
            text={params?.row.cameraId}
            TypographyProps={{
              variant: "body2Bold",
            }}
          />
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "TIMESTAMP",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (timestamp: string): string => {
        return formatTimestamp(timestamp);
      },
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={formatTimestamp(params?.row.timestamp)}
            text={formatTimestamp(params?.row.timestamp)}
            TypographyProps={{
              variant: "body2",
            }}
          />
        ) : null,
    },
  ];

  return { columns };
};
