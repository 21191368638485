import { useState } from "react";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import ModelsToRunMultiselect from "../../../../../common/components/select/ServiceSelect/ModelsToRunMultiselect";
import StyledLoadingButton from "../../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { useCreateDeviceMake } from "../hooks/useCreateDeviceMake";
import DeviceHasPanTiltCheckboxInput from "../../../../devices/components/DynamicInputs/DeviceHasPanTiltCheckboxInput";

interface IDeviceMakeState {
  name: string;
  model: string;
  type: string;
  rtspTemplate: string;
  hasPanTilt: boolean;
  modelsToRun: string[];
  hasZoom: boolean;
}

const defaultState: IDeviceMakeState = {
  name: "",
  model: "",
  type: "",
  rtspTemplate: "",
  hasPanTilt: true,
  modelsToRun: [],
  hasZoom: true,
};

export const DEVICE_TYPES = [
  "License Plate Reader",
  "Optical",
  "Optical Multisensor",
  "Thermal",
  "CH4 OGI",
  "VOC OGI",
].sort();

const CreateDeviceMake = (): JSX.Element => {
  const { createDeviceMake, loading } = useCreateDeviceMake();

  const [newDeviceMake, setNewDeviceMake] =
    useState<IDeviceMakeState>(defaultState);

  const handleFormChange = (key: string, value: string): void => {
    setNewDeviceMake({
      ...newDeviceMake,
      [key]: value,
    });
  };

  const handleHasPanTiltChange = (key: string, value: boolean): void => {
    setNewDeviceMake({
      ...newDeviceMake,
      hasPanTilt: value,
      hasZoom: value,
      modelsToRun: [],
    });
  };

  const handleModelsToRunChange = (value: string[]): void => {
    setNewDeviceMake({
      ...newDeviceMake,
      modelsToRun: value,
    });
  };

  const handleCreateButtonClick = (): void => {
    createDeviceMake(newDeviceMake);

    setNewDeviceMake(defaultState);
  };

  const isCreateButtonDisabled =
    loading ||
    !newDeviceMake.model ||
    !newDeviceMake.name ||
    !newDeviceMake.type ||
    !newDeviceMake.rtspTemplate ||
    newDeviceMake.modelsToRun.length === 0;

  return (
    <Box>
      <Stack marginBottom="1.5em" spacing={2} direction="row">
        <TextField
          required
          fullWidth
          label="Name"
          value={newDeviceMake.name}
          onChange={(e): void => handleFormChange("name", e.target.value)}
        />
        <TextField
          required
          fullWidth
          label="Model"
          value={newDeviceMake.model}
          onChange={(e): void => handleFormChange("model", e.target.value)}
        />
        <FormControl required fullWidth>
          <InputLabel id="device-make-select-label">Type</InputLabel>
          <Select
            labelId="device-make-select-label"
            value={newDeviceMake.type}
            label="Type"
            onChange={(e): void => handleFormChange("type", e.target.value)}
          >
            {DEVICE_TYPES.map((type: string): JSX.Element => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          required
          fullWidth
          label="RTSP Template"
          value={newDeviceMake.rtspTemplate}
          onChange={(e): void =>
            handleFormChange("rtspTemplate", e.target.value)
          }
        />
      </Stack>

      <Box sx={{ marginBottom: "1em", width: "100%", maxWidth: "380px" }}>
        <DeviceHasPanTiltCheckboxInput
          field={"hasPanTilt"}
          label={"The device has Pan/Tilt functionality"}
          hasPanTilt={newDeviceMake.hasPanTilt}
          setPanTilt={handleHasPanTiltChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1.5em 1em",
        }}
      >
        <ModelsToRunMultiselect
          hasPanTilt={newDeviceMake.hasPanTilt}
          models={newDeviceMake.modelsToRun}
          setModels={handleModelsToRunChange}
        />

        <StyledLoadingButton
          disabled={isCreateButtonDisabled}
          variant="contained"
          onClick={handleCreateButtonClick}
        >
          Create
        </StyledLoadingButton>
      </Box>
    </Box>
  );
};

export default CreateDeviceMake;
