import { FC, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";
import { AllowLeakFinder } from "../../../../../API";
import StatusToggle from "../../../../../common/components/item/StatusToggle";

export interface ICustomerForm {
  id: string;
  allowLeakFinder: AllowLeakFinder;
  totalLeakFinderLimit: number;
}
interface ICustomerFormProps {
  control: Control<ICustomerForm, any>;
  errors: FieldErrors<ICustomerForm>;
  customer: ICustomerForm;
  setValue: UseFormSetValue<ICustomerForm>;
}
const EditCustomerForm: FC<ICustomerFormProps> = ({ control, customer }) => {
  const [leakFinderEnabled, setLeakFinderEnabled] = useState(false);
  const [leakFinderAccessValue, setLeakFinderAccessValue] = useState(
    customer?.allowLeakFinder || AllowLeakFinder.TRIAL_ACCESS
  );

  useEffect(() => {
    if (customer) {
      if (
        customer?.allowLeakFinder === AllowLeakFinder.FULL_ACCESS ||
        customer?.allowLeakFinder === AllowLeakFinder.TRIAL_ACCESS
      ) {
        setLeakFinderEnabled(true);
      } else {
        setLeakFinderEnabled(false);
      }

      setLeakFinderAccessValue(customer?.allowLeakFinder);
    }
  }, [customer]);

  const handleLeakFinderCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: any
  ) => {
    const isChecked = event.target.checked;

    setLeakFinderEnabled(isChecked);

    if (!isChecked) {
      setLeakFinderAccessValue(AllowLeakFinder.NONE);

      field.onChange(AllowLeakFinder.NONE);
    } else {
      setLeakFinderAccessValue(AllowLeakFinder.TRIAL_ACCESS);

      field.onChange(AllowLeakFinder.TRIAL_ACCESS);
    }
  };
  const handleLeakFinderAccessChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: any
  ) => {
    const newValue = event.target.value as AllowLeakFinder;

    setLeakFinderAccessValue(newValue);

    field.onChange(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ mt: 2 }}>
        <Typography>
          <strong>What apps are available for this customer? </strong>
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Leak Finder</Typography>
          <Controller
            name="allowLeakFinder"
            control={control}
            render={({ field }) => (
              <StatusToggle
                checked={leakFinderEnabled}
                handleChange={e => handleLeakFinderCheckedChange(e, field)}
                label={""}
              />
            )}
          />
        </Stack>
        {leakFinderEnabled && (
          <Controller
            name="allowLeakFinder"
            control={control}
            render={({ field }) => (
              <RadioGroup
                value={leakFinderAccessValue}
                onChange={e => handleLeakFinderAccessChange(e, field)}
              >
                <FormControlLabel
                  value={AllowLeakFinder.FULL_ACCESS}
                  control={<Radio />}
                  label="Full access"
                />
                <FormControlLabel
                  value={AllowLeakFinder.TRIAL_ACCESS}
                  control={<Radio />}
                  label="Trial"
                />
              </RadioGroup>
            )}
          />
        )}
        {leakFinderEnabled &&
          leakFinderAccessValue === AllowLeakFinder.TRIAL_ACCESS && (
            <Controller
              name="totalLeakFinderLimit"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? 0}
                  sx={{ height: "60px", marginTop: "20px" }}
                  size="medium"
                  label="Videos quantity the customer can upload within the trial"
                  fullWidth
                />
              )}
            />
          )}
      </Box>
    </Box>
  );
};

export default EditCustomerForm;
