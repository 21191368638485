import { CC_ADMIN_ROLES } from "../models/enums";
import { useSelectedCustomerId } from "../variables/selectedCustomer";
import { useAuthenticatedUser } from "./useAuthenticatedUser";

export const useCustomerIdGuard = () => {
  const selectedCustomerId = useSelectedCustomerId();

  const { customerId, role } = useAuthenticatedUser();

  const canRoleAccessMultipleCustomers = CC_ADMIN_ROLES.includes(role);

  // If the user has a role that allows them to access multiple customers
  // and a customer is selected, use the selected customer ID
  if (canRoleAccessMultipleCustomers && selectedCustomerId) {
    return selectedCustomerId as string;
  }

  // If the user can only access a single customer, or no customer is selected,
  // use the user's customer ID from claims
  return customerId as string;
};
