import { gql, useMutation } from "@apollo/client";

import {
  VideoInput,
  BatchAddVideosMutation,
  BatchAddVideosMutationVariables,
  CustomerCountersType,
} from "../../API";
import { BATCH_ADD_VIDEOS_DETAILS } from "../../common/operations/mutations";
import { errorNotification } from "../../common/variables/notification";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";

export const useAddBatchVideosDetails = () => {
  const customerId = useCustomerIdGuard();

  const [addVideos, { data, loading }] = useMutation<
    BatchAddVideosMutation,
    BatchAddVideosMutationVariables
  >(BATCH_ADD_VIDEOS_DETAILS);

  const addBatchVideosDetails = (videos: VideoInput[]) => {
    return addVideos({
      variables: {
        input: {
          videos,
          customerId,
        },
      },
      onError: err => {
        console.log("err", err);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getCustomerCounters(existing: CustomerCountersType) {
              const normalizedCustomerCountersCacheId =
                cache.identify({
                  id: customerId,
                  __typename: "CustomerCountersType",
                }) ?? "";

              const data = cache.extract();

              const record = data[normalizedCustomerCountersCacheId];

              const currentTotal = record?.totalLeakFinderLimit ?? 0;

              const newRecord: CustomerCountersType = {
                ...record,
                id: customerId,
                totalLeakFinderLimit:
                  currentTotal + response.data?.batchAddVideos?.length,
              };

              const newRef = cache.writeFragment({
                data: newRecord,
                fragment: gql`
                  fragment NewRef on CustomerCountersType {
                    id
                    totalLeakFinderLimit
                    allowLeakFinder
                    usageLeakFinderCount
                  }
                `,
              });

              return {
                ...existing,
                ...newRef,
              };
            },
          },
        });
      },
    }).catch(() => {
      errorNotification();
    });
  };

  return { addBatchVideosDetails, data, loading };
};
