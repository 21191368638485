import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  ILiquidLeakTableRow,
  IParsedLiquidLeakEvent,
} from "../../common/events.models";
import { useGetTimestreamDataToExport } from "../../common/hooks/useGetTimestreamDataToExport";

export const useGetLiquidLeakEventsToExport = () => {
  const { fetchTimestreamDataToExport, loading } =
    useGetTimestreamDataToExport();

  const getCsvDataToExport = async (): Promise<ILiquidLeakTableRow[]> => {
    const rows = await fetchTimestreamDataToExport(mapData);

    return rows;
  };

  const mapData = (
    parsedData: IParsedLiquidLeakEvent[]
  ): ILiquidLeakTableRow[] => {
    const mappedData: ILiquidLeakTableRow[] =
      parsedData?.map((item: IParsedLiquidLeakEvent, index: number) => ({
        rowIndex: index + 1,
        rowId: item?.id ?? "",
        location: item?.location_id ?? "",
        zone: item?.zone_id ?? "",
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        leaks: item?.leaks,
        volume: item?.leak_volume ?? "",
        mediaOutput: item?.mediaOutput ?? "",
        keyFrame: item?.keyFrame ?? "",
        cameraId: item?.camera_id ?? "",
      })) ?? [];

    return mappedData;
  };

  return { loading, getCsvDataToExport };
};
