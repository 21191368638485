import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ImageIconFilled(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44 "
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&": {
          cursor: "pointer",
          fill: "none",

          "#path1, #circle, #path2": {
            stroke: theme.palette.primary.main,
          },

          "#rect": {
            display: "none",
          },
        },
        "&:hover": {
          fill: theme.palette.primary.main,

          "#path1, #circle, #path2": {
            stroke: "white",
          },

          "#rect": {
            display: "block",
            fill: theme.palette.primary.main,
          },
        },
        ...props.sx,
      }}
      {...props}
    >
      <rect width="44" height="44" rx="22" />
      <g transform="translate(10, 10)">
        <path
          id="path1"
          d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
          stroke={theme.palette.primary.main}
          strokeWidth="1.5"
          offset={10}
        />
        <circle
          id="circle"
          cx="16"
          cy="8"
          r="2"
          stroke={theme.palette.primary.main}
          strokeWidth="1.5"
        />
        <path
          id="path2"
          d="M2 12.5001L3.75159 10.9675C4.66286 10.1702 6.03628 10.2159 6.89249 11.0721L11.1822 15.3618C11.8694 16.0491 12.9512 16.1428 13.7464 15.5839L14.0446 15.3744C15.1888 14.5702 16.7369 14.6634 17.7765 15.599L21 18.5001"
          stroke={theme.palette.primary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
}

function ImageIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&": {
          cursor: "pointer",
          fill: "none",
        },
        "&:hover": {
          fill: theme.palette.primary.main,
        },
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
      />
      <circle
        cx="16"
        cy="8"
        r="2"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
      />
      <path
        d="M2 12.5001L3.75159 10.9675C4.66286 10.1702 6.03628 10.2159 6.89249 11.0721L11.1822 15.3618C11.8694 16.0491 12.9512 16.1428 13.7464 15.5839L14.0446 15.3744C15.1888 14.5702 16.7369 14.6634 17.7765 15.599L21 18.5001"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

const TableCellWithPhotoIconOnHover = (props: SvgIconProps) => {
  return (
    <Box
      sx={{
        "&": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ".imageIcon": {
            display: "block",
          },
          ".imageIconFilled": {
            display: "none",
          },
        },
        "&:hover": {
          ".imageIcon": {
            display: "none",
          },
          ".imageIconFilled": {
            display: "block",
          },
        },
      }}
    >
      <ImageIcon {...props} className="imageIcon" />
      <ImageIconFilled {...props} className="imageIconFilled" />
    </Box>
  );
};

export default TableCellWithPhotoIconOnHover;
