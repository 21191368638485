import { useState } from "react";
import BaseLayout from "./BaseLayout";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { useGetCurrentUser } from "../../hooks/useGetUser";
import { LeftMenuItems, RoleEnum, RouteEnum } from "../../models/enums";
import { useLocation } from "react-router-dom";
import { RootItems } from "./LeftSideBar/MenuItems/RootItems";
import { CustomerOperatorForemanItems } from "./LeftSideBar/MenuItems/CustomerOperatorForemanItems";
import { CcOpsItems } from "./LeftSideBar/MenuItems/CcOpsItems";
import { CustomerAdminItems } from "./LeftSideBar/MenuItems/CustomerAdminItems";
import { PartnerAdminItems } from "./LeftSideBar/MenuItems/PartnerAdminItems";
import { PartnerUserItems } from "./LeftSideBar/MenuItems/PartnerUserItems";

export type LeftSideBarItem = {
  name: LeftMenuItems;
  path: RouteEnum;
  showDivider?: boolean;
  isSeparatorOfBottomSection?: boolean;
};

export type LeftSideBarSettingsType = {
  showAllLocationsDropdown: boolean;
  showLeftSideBar: boolean;
  leftSideBarItems: LeftSideBarItem[];
};

export default function UnifiedLayout() {
  const [isLeftSideBarOpen, setIsLeftSideBarOpen] = useState<boolean>(true);

  const { role } = useAuthenticatedUser();
  const { pathname } = useLocation();

  useGetCurrentUser();

  const onSetIsLeftSideBarOpen = () => setIsLeftSideBarOpen(prev => !prev);

  let showLeakFinderSettings = false;
  let showCustomerSelect = false;
  let showLeftSideBar = false;
  let showAllLocationsDropdown = false;
  let leftSideBarItems: LeftSideBarItem[] = [];

  /**
   * Check table with actual roles and permissions here: https://cleanconnect.atlassian.net/wiki/spaces/ENG/pages/133693441/AC+-+Roles+permissions
   */
  switch (role) {
    case RoleEnum.ROOT:
      showAllLocationsDropdown = true;

      showLeftSideBar = true;

      showCustomerSelect = true;

      leftSideBarItems = RootItems;
      break;
    // case RoleEnum.CC_OPS_ADMIN:

    case RoleEnum.PARTNER_ADMIN:
      leftSideBarItems = PartnerAdminItems;

      showLeftSideBar = true;

      break;

    case RoleEnum.CUSTOMER_OPERATOR_FOREMAN:
      leftSideBarItems = CustomerOperatorForemanItems;

      showLeftSideBar = true;
      break;

    case RoleEnum.CUSTOMER_ADMIN:
      showAllLocationsDropdown = true;

      showLeftSideBar = true;

      leftSideBarItems = CustomerAdminItems;

      break;

    case RoleEnum.CC_OPS:
      showCustomerSelect = true;

      showLeftSideBar = true;

      showAllLocationsDropdown = true;

      leftSideBarItems = CcOpsItems;
      break;

    case RoleEnum.PARTNER_USER:
      leftSideBarItems = PartnerUserItems;

      showCustomerSelect = true;

      showLeftSideBar = true;

      break;

    case RoleEnum.LEAKFINDER_DIRECTOR:
      showLeftSideBar = true;
      break;

    case RoleEnum.CC_PROCTORS:
      break;

    default:
      showLeftSideBar = false;
      break;
  }

  switch (true) {
    case pathname.startsWith(RouteEnum.LeakFinder):
      showLeakFinderSettings = true;

      showLeftSideBar = false;

      break;

    case pathname.startsWith(RouteEnum.HumanValidator):
    case pathname.startsWith(RouteEnum.HVReview):
    case pathname.startsWith("/media"):
      showCustomerSelect = false;

      showLeftSideBar = false;
      break;

    case pathname.startsWith(RouteEnum.Unauthorized):
      showLeftSideBar = false;
      break;

    default:
      break;
  }

  const leftSideBarSettings: LeftSideBarSettingsType = {
    showAllLocationsDropdown,
    leftSideBarItems,
    showLeftSideBar,
  };

  return (
    <BaseLayout
      leftSideBarSettings={leftSideBarSettings}
      open={isLeftSideBarOpen}
      onSetIsLeftSideBarOpen={onSetIsLeftSideBarOpen}
      showLeakFinderSettings={showLeakFinderSettings}
      showCustomerSelect={showCustomerSelect}
    />
  );
}
