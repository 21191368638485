import { useParams } from "react-router-dom";

import { NetworkStatus, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";

import {
  GetTimestreamDataQuery,
  GetTimestreamDataQueryVariables,
} from "../../../../../../API";
import { useDateRange } from "../../../../../../common/components/datePicker/modelsDatePickers";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { GET_TIMESTREAM_DATA } from "../../../../../../common/operations/queries";
import { convertDateToUTC } from "../../../../../../common/utils/timestampUtils";
import { parseQueryResult } from "../../../../../../common/utils/timestreamHelper";
import { errorNotification } from "../../../../../../common/variables/notification";
import { EVENTS_DATE_FORMAT, EVENTS_EXPORT_LIMIT } from "../events.constants";
import { useEventsFilters } from "./useEventsFilters";

export const useGetTimestreamDataToExport = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const { model } = useParams();

  const dates = useDateRange();

  const startDate = dates?.from
    ? dayjs(convertDateToUTC(dates.from)).format(EVENTS_DATE_FORMAT)
    : null;

  const endDate = dates?.to
    ? dayjs(convertDateToUTC(dates.to)).format(EVENTS_DATE_FORMAT)
    : null;

  const { locationVariable, tagLocationsVariable } = useEventsFilters();

  const locationId = locationVariable?.value ?? "";

  let locations: Array<string> = [];

  if (locationId) {
    locations = [locationId ?? ""];
  } else if (tagLocationsVariable) {
    locations = tagLocationsVariable
      .map(l => l?.value ?? "")
      .filter(f => f !== "");
  }

  const [getTimestreamData, { loading, fetchMore, networkStatus }] =
    useLazyQuery<GetTimestreamDataQuery, GetTimestreamDataQueryVariables>(
      GET_TIMESTREAM_DATA,
      {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
      }
    );

  const fetchTimestreamDataToExport = async <T>(
    mapData: (data: any) => T[],
    offset?: number,
    limit?: number,
    nextToken?: string
  ): Promise<T[]> => {
    try {
      let allResults: T[];
      let nextPageToken: string | null | undefined = nextToken;

      const response = await getTimestreamData({
        variables: {
          input: {
            customerId: selectedCustomerId,
            model: model ?? "",
            dateRange: JSON.stringify({ startDate, endDate }),
            locations,
            offset: offset ?? 0,
            limit: limit ?? EVENTS_EXPORT_LIMIT,
            nextToken,
          },
        },
      });

      const parsedData = JSON.parse(
        response?.data?.getTimestreamData as string
      );

      const parsedResult = parseQueryResult(parsedData);

      allResults = mapData(parsedResult);

      if (parsedData?.NextToken) {
        nextPageToken = parsedData.NextToken;

        while (nextPageToken) {
          const fetchMoreResults = await fetchMore({
            variables: {
              input: {
                customerId: selectedCustomerId,
                model: model ?? "",
                dateRange: JSON.stringify({ startDate, endDate }),
                locations,
                offset: (offset ?? 0) + allResults.length,
                limit: limit ?? EVENTS_EXPORT_LIMIT,
                nextToken: nextPageToken,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;

              return { getTimestreamData: fetchMoreResult.getTimestreamData };
            },
          });

          if (fetchMoreResults?.data?.getTimestreamData) {
            const newDataParsed = JSON.parse(
              fetchMoreResults?.data?.getTimestreamData as string
            );

            const parsedMoreResults = parseQueryResult(newDataParsed);

            allResults = allResults.concat(mapData(parsedMoreResults));

            nextPageToken = newDataParsed.NextToken;
          } else {
            nextPageToken = null;
          }
        }
      }

      return allResults;
    } catch (error) {
      errorNotification();

      return [];
    }
  };

  const networkStatusChanged = [NetworkStatus.fetchMore].includes(
    networkStatus
  );

  const isLoading = loading || networkStatusChanged;

  return {
    fetchTimestreamDataToExport,
    loading: isLoading,
  };
};
