import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import uniqBy from "lodash/uniqBy";
import moment from "moment";

import {
  GetTimestreamDataQuery,
  GetTimestreamDataQueryVariables,
} from "../../../../../../API";
import { useDateRange } from "../../../../../../common/components/datePicker/modelsDatePickers";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { GET_TIMESTREAM_DATA } from "../../../../../../common/operations/queries";
import { convertDateToUTC } from "../../../../../../common/utils/timestampUtils";
import { parseQueryResult } from "../../../../../../common/utils/timestreamHelper";
import { errorNotification } from "../../../../../../common/variables/notification";
import {
  EVENTS_DATE_FORMAT,
  EVENTS_EXPORT_LIMIT,
} from "../../common/events.constants";
import { useEventsFilters } from "../../common/hooks/useEventsFilters";
import type {
  IEventsTableRow,
  ParsedResultItem,
} from "../../common/hooks/useGetEventsTableRows";

export const useGetGateGuardEventsToExport = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const { model } = useParams();
  const dates = useDateRange();

  const startDate = dates?.from
    ? dayjs(convertDateToUTC(dates.from)).format(EVENTS_DATE_FORMAT)
    : null;
  const endDate = dates?.to
    ? dayjs(convertDateToUTC(dates.to)).format(EVENTS_DATE_FORMAT)
    : null;

  const {
    categoryVariable,
    companyVariable,
    locationVariable,
    tagLocationsVariable,
    licensePlateVariable,
  } = useEventsFilters();

  const locationId = locationVariable?.value ?? "";
  const companyName = companyVariable?.value ?? "";
  const categoryName = categoryVariable?.title;
  const licensePlateValue = licensePlateVariable?.value ?? "";

  let locations: Array<string> = [];

  if (locationId) {
    locations = [locationId ?? ""];
  } else if (tagLocationsVariable) {
    locations = tagLocationsVariable
      .map(l => l?.value ?? "")
      .filter(f => f !== "");
  }

  const [getTimestreamData, { loading, fetchMore }] = useLazyQuery<
    GetTimestreamDataQuery,
    GetTimestreamDataQueryVariables
  >(GET_TIMESTREAM_DATA, {
    fetchPolicy: "network-only",
  });

  const fetchTimestreamDataToExport = async (
    offset?: number,
    limit?: number,
    nextToken?: string
  ): Promise<IEventsTableRow[]> => {
    try {
      let allResults: IEventsTableRow[] = [];
      let nextPageToken: string | null | undefined = nextToken;

      const response = await getTimestreamData({
        variables: {
          input: {
            customerId: selectedCustomerId,
            model: model ?? "",
            dateRange: JSON.stringify({ startDate, endDate }),
            locations,
            categoryName,
            companyName,
            licensePlate: licensePlateValue,
            offset: offset ?? 0,
            limit: limit ?? EVENTS_EXPORT_LIMIT,
            nextToken,
          },
        },
      });

      const parsedData = JSON.parse(
        response?.data?.getTimestreamData as string
      );

      const parsedResult = parseQueryResult(parsedData);

      allResults = mapData(parsedResult);

      if (parsedData?.NextToken) {
        nextPageToken = parsedData.NextToken;

        while (nextPageToken) {
          const fetchMoreResults = await fetchMore({
            variables: {
              input: {
                customerId: selectedCustomerId,
                model: model ?? "",
                dateRange: JSON.stringify({ startDate, endDate }),
                locations,
                categoryName,
                companyName,
                licensePlate: licensePlateValue,
                offset: (offset ?? 0) + allResults.length,
                limit: limit ?? EVENTS_EXPORT_LIMIT,
                nextToken: nextPageToken,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;

              return { getTimestreamData: fetchMoreResult.getTimestreamData };
            },
          });

          if (fetchMoreResults?.data?.getTimestreamData) {
            const newDataParsed = JSON.parse(
              fetchMoreResults?.data?.getTimestreamData as string
            );

            const parsedMoreResults = parseQueryResult(newDataParsed);

            allResults = allResults.concat(mapData(parsedMoreResults));

            nextPageToken = newDataParsed.NextToken;
          } else {
            nextPageToken = null;
          }
        }
      }

      return allResults;
    } catch (error) {
      errorNotification();

      return [];
    }
  };

  const mapData = (parsedData: any): IEventsTableRow[] => {
    const mappedData: IEventsTableRow[] = parsedData?.map(
      (item: ParsedResultItem, index: number) => ({
        rowId: item?.id ?? "",
        location: item?.location_id,
        timestamp: item?.time
          ? moment.utc(item.time).local().format(EVENTS_DATE_FORMAT)
          : "",
        keyFrame: item?.keyFrame,
        mediaOutput: item?.mediaOutput,
        licensePlate: item?.license_plate,
        confidence: item?.confidence,
        driver_name: item?.driver_name,
        company_name: item?.company_name,
        category_name: item?.category_name ?? "Unknown",
        cameraId: item?.camera_id,
        rowIndex: index + 1,
      })
    );

    const uniqFilteredData = uniqBy(mappedData, item => item.rowId);

    return uniqFilteredData;
  };

  return { loading, fetchAggTimestreamData: fetchTimestreamDataToExport };
};
