import React from "react";

import { Box, Stack } from "@mui/material";

import ExportDataButton from "../../../common/components/button/ExportDataButton";
import ReactDatePicker from "../../../common/components/datePicker/ReactDatePicker";
import AllLocationsEventsFilterDropdownContainer from "../../../common/components/locations/recent-events/AllLocationsEventsFilterDropdownContainer";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";

interface DefaultFiltersProps {
  locationVariable: AutocompleteOptionType;
  handleLocationChange: (locationValue: AutocompleteOptionType) => void;
  handleTagLocationsChange: (
    tagLocations: AutocompleteOptionType[] | null
  ) => void;
  handleDownloadClick: () => Promise<void>;
  loading: boolean;
}

const DefaultFilters: React.FC<DefaultFiltersProps> = ({
  handleLocationChange,
  handleTagLocationsChange,
  handleDownloadClick,
  loading,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Box sx={{ minWidth: "180px" }}>
          <ReactDatePicker placeholderText="All time" />
        </Box>
        <Box sx={{ minWidth: "180px" }}>
          <AllLocationsEventsFilterDropdownContainer
            enhancedCapabilities
            setLocation={handleLocationChange}
            setTagLocations={handleTagLocationsChange}
            ignoreDefaultValue
          />
        </Box>
      </Stack>
      <ExportDataButton
        sx={{
          color: theme => theme.palette.text.primary,
          marginLeft: "26px",
          "&.Mui-disabled": {
            color: "inherit",
            backgroundColor: "transparent",
          },
        }}
        onClick={handleDownloadClick}
        loading={loading}
      >
        Export Data
      </ExportDataButton>
    </Stack>
  );
};

export default DefaultFilters;
