import { Fragment, useEffect } from "react";

import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";

import { GoToPTHandlerType } from "../../../../../../../common/hooks/usePTController";
import { selectedKeyframeVariable } from "../../../../../../../common/variables/selectedKeyframe";
import S3Image from "./../../../../../../../common/components/media/S3Image";
import { IZonesByDeviceTableRow } from "./hooks/usePTGetZonesByDeviceTableRow";
import {
  firstZoneVariable,
  selectedZoneVariable,
  useSelectedZoneVariable,
} from "./zones.variable";

interface IZoneListTableProps {
  rows: IZonesByDeviceTableRow[];
  loading: boolean;
  hasZoom?: boolean;
  disableRowClick?: boolean;
  handleGoToPt?: GoToPTHandlerType;
}

const PTZoneListTable = ({
  rows,
  loading,
  hasZoom,
  disableRowClick = false,
  handleGoToPt,
}: IZoneListTableProps): JSX.Element => {
  const selectedZone = useSelectedZoneVariable();

  useEffect(() => {
    if (rows.length === 0) {
      return;
    }

    const row = rows[0];

    if (selectedZone.pan === row.pan && selectedZone.tilt === row.tilt) {
      return;
    }

    selectedKeyframeVariable(row?.keyFrame ?? "");

    const firstZone = {
      zoneName: row?.name,
      pan: row?.pan,
      tilt: row?.tilt,
      zoom: row?.zoom,
    };

    firstZoneVariable(firstZone);
  }, [rows.length, selectedZone]);

  const handleRowClick = (params: IZonesByDeviceTableRow): void => {
    if (disableRowClick) {
      return;
    }

    selectedKeyframeVariable(params?.keyFrame ?? "");

    selectedZoneVariable({
      zoneName: params?.name,
      pan: params?.pan,
      tilt: params?.tilt,
      zoom: params?.zoom,
    });

    if (handleGoToPt) {
      handleGoToPt(params.pan, params.tilt, params.zoom, params.name);
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
      }}
    >
      <Table size="small">
        <TableBody>
          {rows.map((row): JSX.Element | null => {
            if (!row.status) {
              return null;
            }

            const isActive =
              selectedZone.pan === row.pan &&
              selectedZone.tilt === row.tilt &&
              selectedZone.zoom === row.zoom;

            const disabledStylesOrDefualt = {
              pointerEvents: disableRowClick ? "none" : "auto",
              opacity: disableRowClick ? 0.5 : 1,
              cursor: disableRowClick ? "not-allowed" : "pointer",
            };

            return (
              <Fragment key={row.id}>
                <TableRow
                  sx={{
                    ...disabledStylesOrDefualt,
                    height: "auto",
                    "& td": {
                      border: 0,
                      padding: 0,
                    },
                    "& .small": {
                      width: "96px",
                      padding: "0.25em 0",
                    },
                  }}
                  onClick={(): void => handleRowClick(row)}
                >
                  <TableCell className="small" rowSpan={3}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: (theme: Theme): string =>
                          isActive
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                      }}
                    >
                      <S3Image
                        s3Key={row.keyFrame}
                        width={96}
                        height={64}
                        sx={{
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                  </TableCell>

                  <TableCell
                    colSpan={3}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingLeft: "0.85em",
                      }}
                      variant="buttonMedium"
                    >
                      {row.name}
                    </Typography>
                    {isActive && (
                      <Typography
                        variant="tag"
                        sx={{
                          position: "absolute",
                          color: "primary.dark",
                          paddingLeft: "0.5em",
                          top: "4px",
                        }}
                      >
                        Active
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>

                <TableRow
                  sx={{
                    ...disabledStylesOrDefualt,
                    height: "auto",
                    "& td": {
                      border: 0,
                      padding: 0,
                    },
                    "& .small": {
                      width: "96px",
                    },
                  }}
                  onClick={(): void => handleRowClick(row)}
                >
                  <TableCell colSpan={3}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        padding: "0.5em 0.85em",
                      }}
                    >
                      <Typography variant="inputLabel">Pan:</Typography>
                      <Typography variant="body2Regular">{row.pan}</Typography>
                      <Typography variant="inputLabel">Tilt:</Typography>
                      <Typography variant="body2Regular">{row.tilt}</Typography>
                      {hasZoom && (
                        <>
                          <Typography variant="inputLabel">Zoom:</Typography>
                          <Typography variant="body2Regular">
                            {row.zoom}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    disabledStylesOrDefualt,
                    height: "22px",
                    "& td": {
                      border: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </Fragment>
            );
          })}

          {loading && (
            <TableRow>
              <TableCell colSpan={4}>
                {[...Array(4)].map(
                  (_, index): JSX.Element => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ my: 3 }}
                    />
                  )
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PTZoneListTable;
