import Box from "@mui/material/Box";

import { isVideoFile } from "../../../../../common/components/media/fileHelper";
import S3Image from "../../../../../common/components/media/S3Image";
import TableCellWithPhotoIconOnHover from "../../../../../common/components/table/TableCellWithPhotoIconOnHover";
import TableCellWithVideocamIconOnHover from "../../../../../common/components/table/TableCellWithVideocamIconOnHover";

interface RecentEventsHoverIconsProps {
  keyFrame: string;
  mediaOutput: string;
  onClick?: () => void;
}

const RecentEventsHoverIcons = ({
  keyFrame,
  mediaOutput,
  onClick,
}: RecentEventsHoverIconsProps) => {
  const isVideo = isVideoFile(mediaOutput);

  const s3Key = keyFrame ?? mediaOutput;

  return (
    <Box
      sx={{
        "& .hoverIcons": {
          display: "none",
          flex: 1,
          cursor: "pointer",
        },
        "&:hover .hoverIcons": {
          display: "flex",
          flex: 1,
          gap: "0.75em",
          position: "absolute",

          top: "33px",
          left: isVideo ? "32px" : "48px",
        },
        "& .videocamIcon": {
          zIndex: 1,
        },
      }}
    >
      <Box className="hoverIcons">
        <TableCellWithPhotoIconOnHover
          className="photoIcon"
          onClick={onClick}
        />
        {isVideo && (
          <TableCellWithVideocamIconOnHover className="videocamIcon" />
        )}
      </Box>
      <Box onClick={onClick}>
        <S3Image
          sx={{
            borderRadius: "4px",
          }}
          s3Key={s3Key}
          width={120}
          height={90}
        />
      </Box>
    </Box>
  );
};

export default RecentEventsHoverIcons;
