import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";

import TableCellTruncated from "../../../../../../common/components/table/TableCellTruncated";
import useLocationFromCache from "../../../../../../common/hooks/useLocationFromCache";
import { formatTimestamp } from "../../../../../../common/utils/timestampUtils";
import { IGasLeakTableRow } from "../../common/events.models";
import EventSnapshotTableCell from "../../common/EventSnapshotTableCell";

export const useGetGasLeakEventsTableColumns = () => {
  const { getCachedLocationName } = useLocationFromCache();

  const columns: GridColDef<IGasLeakTableRow>[] = [
    {
      field: "rowIndex",
      headerName: "#",
      width: 50,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        return <Typography variant="body2">{params.row.rowIndex}</Typography>;
      },
    },
    {
      field: "keyFrame",
      headerName: "Snapshot",
      flex: 1,
      minWidth: 160,
      width: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: EventSnapshotTableCell,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (locationId: string): string => {
        const locationName = getCachedLocationName(locationId);

        return locationName;
      },
      renderCell: (params): JSX.Element | null => {
        const locationName = getCachedLocationName(params?.row.location);

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={locationName}
            text={locationName}
            TypographyProps={{
              variant: "body2",
            }}
          />
        );
      },
    },
    {
      field: "zone",
      headerName: "Zone",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row.zone}
            text={params?.row.zone}
            TypographyProps={{
              variant: "body2",
            }}
          />
        ) : null,
    },
    {
      field: "cameraId",
      headerName: "DEVICE ID",
      flex: 1,
      minWidth: 160,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <TableCellTruncated
            clickableCursor
            tooltipText={params?.row.cameraId}
            text={params?.row.cameraId}
            TypographyProps={{
              variant: "body2Bold",
            }}
          />
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      minWidth: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (timestamp: string): string => {
        return formatTimestamp(timestamp);
      },
      renderCell: (params): JSX.Element | null => {
        const date = formatTimestamp(params?.row.timestamp);

        return (
          <TableCellTruncated clickableCursor tooltipText={date} text={date} />
        );
      },
    },
    {
      field: "rate",
      headerName: "Rate (MCF/d)",
      flex: 1,
      minWidth: 140,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      valueGetter: (rate?: number | null): string | null => {
        if (!rate) {
          return null;
        }

        const rateValue = rate * 86400;

        return rateValue.toFixed(2);
      },
      renderCell: (params): JSX.Element | null => {
        if (!params?.row?.rate) {
          return null;
        }

        const rate = parseFloat(params?.row.rate) * 86400;

        return (
          <TableCellTruncated
            clickableCursor
            tooltipText={""}
            text={rate.toFixed(2)}
          />
        );
      },
    },
    {
      field: "volume",
      headerName: "Volume (MCF)",
      flex: 1,
      minWidth: 140,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      valueGetter: (volume?: string | null): string | null => {
        if (!volume) {
          return null;
        }

        const volumeValue = +volume * 1; // multiply by 1 to convert type to number

        return volumeValue.toFixed(2);
      },
      renderCell: (params): JSX.Element | null => {
        if (!params?.row?.volume) {
          return null;
        }

        const volume = parseFloat(params?.row?.volume)?.toFixed(2);

        return (
          <TableCellTruncated clickableCursor tooltipText={""} text={volume} />
        );
      },
    },
  ];

  return { columns };
};
