import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { Button, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import FullPageLoader from "../../../common/components/item/FullPageLoader";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import LiveViewContainer, {
  ViewMode,
  ViewModeEnum,
} from "../../../common/components/live-view/LiveViewContainer";
import { captureKeyframe } from "../../../common/helpers/captureKeyframe";
import { useStreamProcesses } from "../../../common/hooks/useStreamProcesses";
import useWindowDimensions from "../../../common/hooks/useWindowDimensions";
import { useGetZonesByDevice } from "../../data-hub/components/liveview/components/zonelist/hooks/useGetZonesByDevice";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import DeviceDetailPageHeader from "../components/DeviceDetailPageHeader";
import DeviceZones from "../components/DeviceZones";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import { usePublishNode } from "../hooks/usePublishNode";
import { deviceDataVariable, useDeviceDataState } from "../variables/devices";
import { useListenToZoneUpdates } from "../zones-setup-page/hooks/useListenToZoneChanges";
import DeviceDetailViewMode from "./components/DeviceDetailViewMode";
import DeviceModelsContainer from "./components/DeviceModelsContainer";

const DeviceDetailPage = (): JSX.Element => {
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const { publishNode } = usePublishNode();

  const { deviceId: deviceIdSearchParameter = "" } = useParams();

  const [currentViewMode, setCurrentViewMode] = useState<ViewMode>(
    ViewModeEnum.KEYFRAME
  );

  const memoizedSetCurrentViewModeCallback = useCallback((mode: ViewMode) => {
    setCurrentViewMode(mode);
  }, []);

  const { fetchDevice, getServicesLoading, loading, isServiceLoaded } =
    useGetDeviceByUrl();

  const { data, loading: getZonesByDeviceLoading } = useGetZonesByDevice();

  const zones = data?.getZonesByDevice.items ?? [];

  const device = useDeviceDataState();

  useListenToZoneUpdates();

  const { streamLoading, stopStreaming } = useStreamProcesses();

  useEffect((): void => {
    if (!isServiceLoaded) return;

    if (deviceIdSearchParameter) {
      fetchDevice().then((result): void => {
        const deviceResponse = result as IDeviceTableRow;

        deviceDataVariable(deviceResponse);
      });
    }
  }, [deviceIdSearchParameter, isServiceLoaded]);

  useEffect(() => {
    return () => {
      deviceDataVariable(null);

      if (device) {
        stopStreaming(device);
      }
    };
  }, []);

  const handleCaptureKeyframe = (): void => {
    if (device) {
      captureKeyframe(device, publishNode);
    }
  };

  if (
    !device ||
    streamLoading ||
    getServicesLoading ||
    loading ||
    getZonesByDeviceLoading
  ) {
    return <FullPageLoader />;
  }

  const hasGasLeakServiceBeenCreated = device.models.some(
    (el): boolean =>
      el.serviceName === ServiceTypeEnum.GAS_LEAK ||
      el.serviceName === ServiceTypeEnum.LEAK ||
      el.serviceName === ServiceTypeEnum.LIQUID_LEAK
  );

  const showZonesSection = device.deviceData.hasPanTilt;

  const zonesPrecondition = hasGasLeakServiceBeenCreated;

  const tooltipTitle =
    "The model will be stopped while the key image is capturing";

  const isCaptureKeyFramesDisabled =
    !device?.isOnline ||
    !device?.deviceData.recentFrame ||
    currentViewMode === ViewModeEnum.LIVE;

  return (
    <Box
      sx={{
        display: "grid",
        gap: "1.5em",
      }}
    >
      <DeviceDetailPageHeader deviceDetails={device} />
      <MainPaperWrapper>
        <Box
          sx={{
            "& > .MuiBox-root": {
              maxWidth:
                width > theme.breakpoints.values.xxl
                  ? theme.breakpoints.values.sm
                  : "100%",
            },
            "@media (min-width: 1440px)": {
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <DeviceDetailViewMode deviceDatum={device} />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Tooltip title={tooltipTitle} placement="top">
              <Box sx={{ marginRight: "0.5em" }}>
                {!device?.deviceData.hasPanTilt && (
                  <Button
                    disabled={isCaptureKeyFramesDisabled}
                    variant="text"
                    size="small"
                    startIcon={<PhotoCameraOutlinedIcon />}
                    color="inherit"
                    onClick={handleCaptureKeyframe}
                    sx={{
                      "&.Mui-disabled": {
                        backgroundColor: "transparent",
                        color: theme.palette.text.disabled,
                      },
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Capture a new key image
                  </Button>
                )}
              </Box>
            </Tooltip>
            <LiveViewContainer
              device={device}
              showPtButton={device?.deviceData.hasPanTilt}
              s3Key={device?.deviceData.recentFrame}
              setCurrentViewMode={memoizedSetCurrentViewModeCallback}
            />
          </Stack>
        </Box>
      </MainPaperWrapper>

      <DeviceModelsContainer device={device} />

      {showZonesSection && (
        <DeviceZones
          device={device}
          hasPanTilt={device?.deviceData.hasPanTilt}
          currentViewMode={currentViewMode}
          zones={zones}
          loading={getZonesByDeviceLoading}
          precondition={zonesPrecondition}
        />
      )}
    </Box>
  );
};

export default DeviceDetailPage;
