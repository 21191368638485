import { FC, ReactNode } from "react";

import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";

interface ITableCellTruncatedProps {
  tooltipText: ReactNode;
  text?: string;
  clickableCursor?: boolean;
  TypographyProps?: TypographyProps;
  children?: ReactNode;
}

const TableCellTruncated: FC<ITableCellTruncatedProps> = ({
  tooltipText,
  text,
  clickableCursor = false,
  TypographyProps = {},
  children,
}) => {
  return (
    <Tooltip title={tooltipText}>
      <Typography
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: clickableCursor ? "pointer" : "default",
          ...TypographyProps.sx,
        }}
        variant="body2Regular"
        {...TypographyProps}
      >
        {children ?? text}
      </Typography>
    </Tooltip>
  );
};

export default TableCellTruncated;
