import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ApolloError } from "@apollo/client";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import ControlTable from "../../../common/components/stream/ControlTable";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import createS3KeyForZone from "../../../common/helpers/createS3KeyForZone";
import { validateZoneName } from "../../../common/helpers/validateZoneName";
import useBeforeUnload from "../../../common/hooks/useBeforeUnload";
import { usePTController } from "../../../common/hooks/usePTController";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import HLSStreamPlayer from "../../data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { usePublishNode } from "../hooks/usePublishNode";
import { useCreateZone } from "./hooks/useCreateZone";
import { ICreateZoneValidationState } from "./hooks/useZoneFormValidation";

const CreateZoneContent = (): JSX.Element => {
  const confirm = useConfirm();
  const { state } = useLocation();
  const navigate = useNavigate();

  const device = state?.deviceDatum as IDeviceTableRow;
  const { deviceId = "" } = useParams<{ deviceId: string }>();

  const { publishNode } = usePublishNode();
  const { createZone, loading } = useCreateZone();

  const [zoneName, setZoneName] = useState("");

  const deviceName = device?.name;

  // We determine the serviceId by looking at the first model assigned to the device.
  // This serviceId is used when pausing and resuming models when editing a zone/moving pt
  // This will lead to error if a device has multiple models
  // But typically ogi's only have one model running so it is fine for PROD but buggy for testing on DEV
  const serviceId = device?.models[0]?.serviceId;
  const nodeId = device?.node.id;

  const {
    pan,
    tilt,
    zoom,
    disabled,
    movePtUp,
    movePtDown,
    movePtLeft,
    movePtRight,
    handleGoToPt,
    handlePanInputChange,
    handleTiltInputChange,
    handleZoomInputChange,
    validationState,
    setValidationState,
    validateCreateZoneForm,
  } = usePTController({
    deviceId,
    deviceName: deviceName,
    nodeId: device?.node.id,
    deviceData: device?.deviceData,
  });

  const saveZone = (): void => {
    const isValid = validateCreateZoneForm(zoneName, pan, tilt, zoom);

    if (!isValid) {
      return;
    }

    const deviceName = device?.name.replace("DE#", "");

    if (!deviceName) {
      errorNotification("Error with device name. Please try again.");

      return;
    }

    const validName = validateZoneName(zoneName);

    if (!validName) {
      return;
    }

    createZone({
      deviceId: device.rowId ?? "TODO",
      name: zoneName,
      pan: String(pan),
      tilt: String(tilt),
      zoom: String(zoom),
      status: true,
      shouldNotify: true,
      serviceId: serviceId ?? "TODO",
      nodeId: nodeId,
      keyFrame: createS3KeyForZone(nodeId, zoneName, deviceName),
    })
      .then((response): void => {
        if (response?.data) {
          // save image to s3
          saveImage();

          successNotification(`${zoneName} has been created successfully`);

          navigate(`/device/${encodeURIComponent(deviceId)}`);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        if (
          (error as ApolloError)?.message.startsWith(
            "The conditional request failed"
          )
        ) {
          errorNotification("Zone name already exists");

          return;
        }

        errorNotification();

        console.error(error);
      });
  };

  const saveImage = (): void => {
    const deviceName = device?.name.replace("DE#", "");

    publishNode({
      message: JSON.stringify({
        ACTION: "SAVE_IMAGE",
        s3_key: createS3KeyForZone(nodeId, zoneName, deviceName), // generate s3_key e.g C_cleanconnect/C_cleanconnect_L_tp_N_solartrailer-agx4/keyframes/tp-ch4_keyframe.jpg // also need to save it to zone item
        TARGET: "CAMERA_MANAGER",
        device_id: deviceName,
      }),
      nodeId,
    }).catch((error): void => {
      errorNotification("Something went wrong when saving image to S3");

      console.error(error);
    });
  };

  const handleZoneNameInputChange = (zoneName?: string): void => {
    setValidationState(
      (prev): ICreateZoneValidationState => ({
        ...prev,
        zoneName: {
          hasError: false,
          errorMessage: "",
        },
      })
    );

    setZoneName(zoneName ?? "");
  };

  const confirmCancel = (): void => {
    confirm({
      title: "Are you sure you want to cancel Zone creation?",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
        color: "primary",
      },
    }).then(async (): Promise<void> => {
      navigate(`/device/${encodeURIComponent(deviceId)}`);
    });
  };

  // TODO: implement another way to handle url change
  // const confirmUrlChange = (path: string): void => {
  //   confirm({
  //     title: "If you leave, the new zone won't be saved.",
  //     content: "Go back to save the changes.",
  //     confirmationText: "Leave",
  //     cancellationText: "Cancel",
  //     ...confirmDialogStyleOptions,
  //     confirmationButtonProps: {
  //       ...confirmDialogStyleOptions.confirmationButtonProps,
  //       color: "primary",
  //     },
  //   }).then((): void => {
  //     resetValidationState();
  //     navigate(path);
  //   });
  // };

  // useUrlChange(confirmUrlChange);

  useBeforeUnload();

  return (
    <>
      <Paper
        sx={{
          padding: "2em",
          borderRadius: "12px",
          border: (theme): string =>
            `1px solid ${theme.palette.otherBackgroundLight.main}`,
        }}
        elevation={0}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Stack direction="column" spacing={2}>
              <TextField
                margin="dense"
                label="Zone name"
                value={zoneName}
                error={validationState.zoneName.hasError}
                helperText={validationState.zoneName.errorMessage}
                onChange={(e): void =>
                  handleZoneNameInputChange(e.target.value)
                }
              />

              <Typography variant="subtitle1">
                Set up the camera position
              </Typography>

              <Box>
                <ControlTable
                  pan={pan}
                  tilt={tilt}
                  zoom={zoom}
                  ptDisabled={disabled}
                  validation={validationState}
                  showValidationAlert
                  handlePanInputChange={handlePanInputChange}
                  handleGoToPt={handleGoToPt}
                  handleTiltInputChange={handleTiltInputChange}
                  handleZoomInputChange={handleZoomInputChange}
                  movePtLeft={movePtLeft}
                  movePtRight={movePtRight}
                  movePtUp={movePtUp}
                  movePtDown={movePtDown}
                  hasZoom={device?.deviceData?.hasZoom}
                />
              </Box>

              <Divider flexItem />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <HLSStreamPlayer />
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          color="inherit"
          onClick={confirmCancel}
        >
          Cancel
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          variant="contained"
          color="primary"
          onClick={saveZone}
        >
          Create zone
        </StyledLoadingButton>
      </Box>
    </>
  );
};

export default CreateZoneContent;
