import React, { useEffect, useMemo, useState } from "react";

import DeviceModelChipDropdown from "../../../common/components/chip/DeviceModelChipDropdown";
import { ServiceTypeEnum } from "../../model-manager/variables/modelManager";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import { useServicesByLocationVariable } from "../variables/devices";

type Props = {
  device: IDeviceTableRow;
};

const DeviceModelContainer: React.FC<Props> = ({ device }): JSX.Element => {
  const servicesByLocation = useServicesByLocationVariable();

  const models = useMemo(() => {
    if (servicesByLocation.length === 0) {
      return device.models;
    }

    const servicesByDevice = servicesByLocation.filter(service => {
      const deviceName = service.deviceId?.split("#DE")[1];

      const deviceId = deviceName ? `DE${deviceName}` : "";

      return deviceId === device.rowId;
    });

    // convert to IModel
    const servicesByDeviceModels = servicesByDevice.map(service => {
      return {
        serviceId: service.id ?? "",
        serviceName: service.serviceType as ServiceTypeEnum,
        isRunning: service.isRunning ?? false,
        nodeId: service.nodeId ?? "",
        configuration: service?.configuration ?? "",
      };
    });

    return servicesByDeviceModels;
  }, [servicesByLocation, device]);

  const [deviceModels, setDeviceModels] = useState(device.models);

  useEffect(() => {
    setDeviceModels(models);
  }, [models]);

  const setModelsHandler = (modelId: string): void => {
    const filteredModels = deviceModels.filter(
      (model): boolean =>
        model.serviceId !== modelId && model.serviceId !== undefined
    );

    setDeviceModels(filteredModels);
  };

  const memoizedRunningLeakModel = useMemo(() => {
    const runningLeakModel = deviceModels.find(
      m =>
        m.isRunning &&
        (m.serviceName === ServiceTypeEnum.LEAK ||
          m.serviceName === ServiceTypeEnum.GAS_LEAK)
    );

    return runningLeakModel;
  }, [deviceModels]);

  const memoizedRunningLiquidLeakModel = useMemo(() => {
    const runningLiquidLeakModel = deviceModels.find(
      m => m.isRunning && m.serviceName === ServiceTypeEnum.LIQUID_LEAK
    );

    return runningLiquidLeakModel;
  }, [deviceModels]);

  const memoizedRunningLeakModels = useMemo(() => {
    let runningLeakModels: string[] = [];

    if (memoizedRunningLeakModel) {
      runningLeakModels = [memoizedRunningLeakModel.serviceName];
    }

    if (memoizedRunningLiquidLeakModel) {
      runningLeakModels = [memoizedRunningLiquidLeakModel.serviceName];
    }

    if (memoizedRunningLeakModel && memoizedRunningLiquidLeakModel) {
      runningLeakModels = [
        memoizedRunningLeakModel.serviceName,
        memoizedRunningLiquidLeakModel.serviceName,
      ];
    }

    return runningLeakModels;
  }, [memoizedRunningLeakModel, memoizedRunningLiquidLeakModel]);

  return (
    <>
      {deviceModels.map((model): JSX.Element => {
        return (
          <DeviceModelChipDropdown
            key={model.serviceId}
            onDeleteModelHandler={setModelsHandler}
            data={device}
            model={model}
            runningLeakModels={memoizedRunningLeakModels}
          />
        );
      })}
    </>
  );
};

export default DeviceModelContainer;
