import type {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  NetworkStatus,
  OperationVariables,
} from "@apollo/client";
import { useMemo } from "react";

import {
  ValidationState,
  type GetEventsPendingHumanValidationQuery,
  type GetEventsPendingHumanValidationQueryVariables,
  type HumanValidationEvent,
} from "../../../API";
import { useGetHumanValidatorTable } from "./useGetHumanValidatorTable";
import { useListenToHumanValidationEvents } from "./useListenToHumanValidationEvents";

export interface PendingEventRow {
  rowId: string;
  customerId: string;
  locationId: string;
  nodeId: string;
  serviceId: string;
  validationState: ValidationState | null | undefined;
  data: string | null;

  date: string;
  eventId: string;
  eventTime: string;
  model: string;
  cameraName: string;
  mediaOutput: string;
  location: string;
}

interface GetHumanValidatorTableDataInterface {
  rows: PendingEventRow[];
  nextToken?: string | null;
  loading: boolean;
  networkStatus: NetworkStatus;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<
      GetEventsPendingHumanValidationQueryVariables,
      GetEventsPendingHumanValidationQuery
    > & {
      updateQuery?: (
        previousQueryResult: GetEventsPendingHumanValidationQuery,
        options: {
          fetchMoreResult: GetEventsPendingHumanValidationQuery;
          variables: OperationVariables;
        }
      ) => GetEventsPendingHumanValidationQuery;
    }
  ) => Promise<ApolloQueryResult<GetEventsPendingHumanValidationQuery>>;
}

export const useGetHumanValidatorTableData =
  (): GetHumanValidatorTableDataInterface => {
    const { data } = useListenToHumanValidationEvents();

    const { pendingEvents, nextToken, loading, networkStatus, fetchMore } =
      useGetHumanValidatorTable();

    const rows = useMemo((): PendingEventRow[] => {
      return (
        pendingEvents
          ?.map((item: HumanValidationEvent): PendingEventRow => {
            let eventData = null;

            if (item.data) {
              eventData = JSON.parse(item.data);
            }

            const validationState =
              data && data?.listenToHumanValidationEvents?.id === item.id
                ? data?.listenToHumanValidationEvents?.validationState
                : item.validationState;

            return {
              rowId: item.id,
              customerId: item.customerId,
              locationId: item.locationId,
              nodeId: item.nodeId,
              serviceId: item.serviceId,
              validationState,
              data: eventData,

              date: eventData?.Timestamp,
              eventId: item.id,
              eventTime: item?.eventTime,
              model: item.serviceId,
              cameraName: eventData.camera_id,
              mediaOutput: eventData.mediaOutput,
              location: eventData.location,
            };
          })
          .filter((item: PendingEventRow): boolean => {
            // removes events that are already being validated
            return data
              ? item.validationState === ValidationState.PENDING
              : !!item;
          }) ?? []
      );
    }, [pendingEvents, data]);

    const memoizedSortedItems = useMemo(() => {
      // Sort items to move fire service events to the top
      const fireItems = rows.filter(item => item?.serviceId === "fire");

      const otherItems = rows
        .filter(item => item?.serviceId !== "fire")
        .sort((a, b) => {
          return (a?.serviceId ?? "").localeCompare(b?.serviceId ?? "");
        });

      const sortedItems = fireItems.concat(otherItems);

      return sortedItems;
    }, [pendingEvents]);

    return {
      rows: memoizedSortedItems,
      nextToken,
      loading,
      networkStatus,
      fetchMore,
    };
  };
