import { useNavigate } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import StyledLoadingButton from "../../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import client from "../../../../configs/apolloClient";
import LocationForm from "./components/LocationForm";
import { useSaveLocation } from "./hooks/useSaveLocation";
import {
  defaultLocationFormValidationState,
  defaultLocationFormVariables,
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "./variables/locationData";
import { RouteEnum } from "../../../../common/models/enums";
import BreadcrumbNavigation from "../../../../common/components/tabs/BreadcrumbNavigation";
import PaperWrapperCentered from "../../../../common/components/item/PaperWrapperCentered";
import { useCustomerExternalIdGuard } from "../../../../common/hooks/useCustomerExternalIdGuard";

const CreateLocationPage = (): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();
  const customerExternalId = useCustomerExternalIdGuard();

  console.log("selectedCustomerId", selectedCustomerId);
  const form = useLocationFormVariables();
  const validation = useLocationFormValidationState();

  const navigate = useNavigate();

  const [saveLocationItem, { loading }] = useSaveLocation();

  const locationPath = location.pathname.includes("sensory")
    ? RouteEnum.SensorySystemSettings
    : RouteEnum.CustomerSettings;

  const saveLocation = (): void => {
    let validationState = {
      ...(validation ?? defaultLocationFormValidationState),
    };

    const key = form.locationName.toLowerCase().replace(/\s+/g, "_");
    const locationId = `${selectedCustomerId}#L#${key}`;

    const normalizedId =
      client.cache.identify({
        id: locationId,
        __typename: "Location",
      }) ?? "";

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "Location name already exists",
        },
      };
    }

    if (!form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (form.locationName.length > locationFormRules.locationName.maxLength) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: `Location name cannot be longer than ${locationFormRules.locationName.maxLength} characters`,
        },
      };
    }

    if (!form.latitude) {
      validationState = {
        ...validationState,
        latitude: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (!form.longitude) {
      validationState = {
        ...validationState,
        longitude: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (!form.timeZone) {
      validationState = {
        ...validationState,
        timeZone: {
          hasError: true,
          errorMessage: "The field is required",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      locationFormValidationStateVariable(validationState);

      return;
    }

    saveLocationItem({
      input: {
        customerExternalId,
        customerId: selectedCustomerId,
        id: form.locationName,
        referenceId: form.referenceId,
        name: form.locationName,
        tags: form.tags.map(({ tagName, id }) => ({
          tagName,
          id,
        })),
        locationData: JSON.stringify({
          lat: form.latitude,
          lon: form.longitude,
        }),
        timeZone: form.timeZone,
      },
    })
      .then((response): void => {
        if (!response.data?.addLocation?.id) return;

        successNotification(`${response.data.addLocation?.name} is added`);

        locationFormVariables(defaultLocationFormVariables);

        locationFormValidationStateVariable(defaultLocationFormValidationState);

        navigate(locationPath);
      })
      .catch((error): void => {
        errorNotification((error as Error).message);

        console.error(error);
      });
  };

  const handleCancel = (): void => {
    locationFormVariables(defaultLocationFormVariables);

    locationFormValidationStateVariable(defaultLocationFormValidationState);

    navigate(locationPath);
  };

  const breadcrumbItems = [
    { label: "Customer settings", path: locationPath },
    {
      label: "Add new location",
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: "1em" }}>
        <BreadcrumbNavigation items={breadcrumbItems} />
      </Box>

      <Typography variant="h3" sx={{ paddingBottom: "1em" }}>
        Add new location
      </Typography>
      <PaperWrapperCentered>
        <LocationForm />
      </PaperWrapperCentered>
      <Stack direction="row-reverse" spacing={2} sx={{ paddingTop: 3 }}>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          startIcon={<></>}
          variant="contained"
          color="primary"
          onClick={saveLocation}
        >
          Add new location
        </StyledLoadingButton>
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          loadingPosition="start"
          startIcon={<></>}
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Cancel
        </StyledLoadingButton>
      </Stack>
    </>
  );
};

export default CreateLocationPage;
