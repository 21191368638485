import { useMutation, type FetchResult } from "@apollo/client";

import type {
  CreateSecretsManagerSecretMutation,
  createSecretsManagerSecretInput,
  CreateSecretsManagerSecretMutationVariables,
} from "./../../../API";
import { CREATE_SECRETS_MANAGER_SECRET } from "./../../../common/operations/mutations";

interface CreateSecretInterface {
  createSecret: (
    input: createSecretsManagerSecretInput
  ) => Promise<FetchResult<CreateSecretsManagerSecretMutation>>;
  data?: CreateSecretsManagerSecretMutation | null;
  loading: boolean;
}

export const useCreateSecret = (): CreateSecretInterface => {
  const [saveSecret, { data, loading }] = useMutation<
    CreateSecretsManagerSecretMutation,
    CreateSecretsManagerSecretMutationVariables
  >(CREATE_SECRETS_MANAGER_SECRET);

  const createSecret = async (
    input: createSecretsManagerSecretInput
  ): Promise<FetchResult<CreateSecretsManagerSecretMutation>> => {
    return await saveSecret({
      variables: {
        input,
      },
    });
  };

  return { createSecret: createSecret, data, loading };
};
