import EventsTableServer from "../common/EventsTableServer";
import { useGetGateGuardEventsTableColumns } from "./hooks/useGetGateGuardEventsTableColumns";
import { useGetGateGuardEventsTableRows } from "./hooks/useGetGateGuardEventsTableRows";

const GateGuardServerTableContainer = () => {
  const { columns } = useGetGateGuardEventsTableColumns();

  const {
    rows,
    loading,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
    paginationModel,
  } = useGetGateGuardEventsTableRows();

  return (
    <EventsTableServer
      mediaOutputClick
      columns={columns}
      rows={rows}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      hasNextPage={hasNextPage}
      isActivePageChange={isActivePageChange}
      paginationModel={paginationModel}
    />
  );
};

export default GateGuardServerTableContainer;
