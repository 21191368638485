import { ApolloError, gql, Reference, useMutation } from "@apollo/client";

import {
  UpdateZoneThresholdValueMutation,
  UpdateZoneThresholdValueMutationVariables,
} from "../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { UPDATE_ZONE_THRESHOLD } from "../../../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";

export const useUpdateZoneThresholdValue = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [updateZoneThresholdValue, { data, loading }] = useMutation<
    UpdateZoneThresholdValueMutation,
    UpdateZoneThresholdValueMutationVariables
  >(UPDATE_ZONE_THRESHOLD);

  const updateZoneThreshold = (
    deviceId: string,
    zoneId: string,
    zoneName: string,
    value?: string,
    addMode?: boolean
  ) => {
    return updateZoneThresholdValue({
      variables: {
        input: {
          customerId: selectedCustomerId,
          deviceId,
          zoneId,
          value,
        },
      },
      onCompleted: response => {
        if (response.updateZoneThresholdValue) {
          if (addMode) {
            successNotification(`Threshold for ${zoneName} zone is added`);
          } else if (value === "0") {
            successNotification(`Threshold for ${zoneName} zone is deleted`);
          } else {
            successNotification("Changes are saved");
          }
        }
      },
      onError: (error: ApolloError): void => {
        if (addMode) {
          errorNotification(
            `Something went wrong, threshold for ${zoneName} zone isn't added`
          );
        } else if (value === "0") {
          errorNotification(
            `Something went wrong, threshold for ${zoneName} zone isn't deleted`
          );
        } else {
          errorNotification("Something went wrong, changes aren't saved");
        }

        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getZonesByDevice(existing) {
              const newZoneRef = cache.writeFragment({
                data: response.data?.updateZoneThresholdValue,
                fragment: gql`
                  fragment NewZone on Zone {
                    alertData
                    deviceId
                    exclusions
                    id
                    keyFrame
                    name
                    nodeId
                    pan
                    serviceId
                    status
                    threshold
                    tilt
                  }
                `,
              });

              return {
                ...existing,
                items: existing.items.map((item: Reference) => {
                  if (item.__ref.includes(zoneId)) {
                    return newZoneRef;
                  }

                  return item;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateZoneThreshold, data, loading };
};
