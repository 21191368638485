import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SIGNED_URL_ACTION } from "../../common/operations/queries";

import {
  GetSignedUrlActionInput,
  GetSignedUrlActionQuery,
  GetSignedUrlActionQueryVariables,
} from "./../../API";

export const useGetSignedUrlAction = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [signedUrl, setSignedUrl] = useState("");

  const [getSignedUrlAction, { data, loading }] = useLazyQuery<
    GetSignedUrlActionQuery,
    GetSignedUrlActionQueryVariables
  >(GET_SIGNED_URL_ACTION, {});

  const fetchSignedUrlAction = async (input: GetSignedUrlActionInput) => {
    await getSignedUrlAction({
      variables: {
        input: {
          contentType: input.contentType,
          objectKey: input.objectKey,
          operation: input.operation,
        },
      },
    });

    setIsLoading(false);
  };

  useEffect(() => {
    let parsedData;
    let parsedBody;

    if (data) {
      parsedData = JSON.parse(data?.getSignedUrlAction ?? "{}");
    }

    if (parsedData) {
      parsedBody = JSON.parse(parsedData?.body);

      setSignedUrl(parsedBody?.signedUrl ?? "");
    }
  }, [data?.getSignedUrlAction]);

  return {
    fetchSignedUrlAction,
    data: signedUrl,
    loading: isLoading || loading,
  };
};
