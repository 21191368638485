import { FC, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Box, Grid, TextField, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import FullPageLoader from "../../../common/components/item/FullPageLoader";
import ItemDetailHeader from "../../../common/components/item/ItemDetailHeader";
import MainPaperWrapper from "../../../common/components/item/MainPaperWrapper";
import ControlTable from "../../../common/components/stream/ControlTable";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import createS3KeyForZone from "../../../common/helpers/createS3KeyForZone";
import { validateZoneName } from "../../../common/helpers/validateZoneName";
import useBeforeUnload from "../../../common/hooks/useBeforeUnload";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import {
  PAN_DEFAULT,
  TILT_DEFAULT,
  usePTController,
  ZOOM_DEFAULT,
} from "../../../common/hooks/usePTController";
import { useStreamProcesses } from "../../../common/hooks/useStreamProcesses";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { errorNotification } from "../../../common/variables/notification";
import HLSStreamPlayer from "../../data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import { IDeviceTableRow } from "../hooks/useDeviceTableRows";
import useGetDeviceByUrl from "../hooks/useGetDeviceByUrl";
import { useGetZone } from "../hooks/useGetZone";
import { usePublishNode } from "../hooks/usePublishNode";
import { useListenToDeleteZone } from "../zones-setup-page/hooks/useListenToDeleteZone";
import { useUpdateZone } from "../zones-setup-page/hooks/useUpdateZone";

const EditZonePage: FC = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const confirm = useConfirm();

  const customerId = useCustomerIdGuard();

  const { updateZone, loading } = useUpdateZone(true);

  const { publishNode } = usePublishNode();

  // TODO: deviceId from params is DE# without C#
  const param = useParams<{ deviceId: string; zoneId: string }>();
  const { deviceId = "", zoneId = "" } = param;
  const {
    data,
    getZone,
    loading: getZoneLoading,
  } = useGetZone(deviceId, zoneId);
  const [searchParams] = useSearchParams();

  const deviceNameFromUrl =
    location.state?.deviceName ?? searchParams.get("deviceName");
  const nodeId = location.state?.nodeId ?? searchParams.get("nodeId");

  const [fetchingDeviceData, setFetchingDeviceData] = useState(false);

  const [ptStarted, setPtStarted] = useState(false);

  const [deviceDetail, setDeviceDetail] = useState<IDeviceTableRow | null>(
    null
  );
  const [status, setStatus] = useState(location.state?.status ?? {});
  const [shouldNotify, setShouldNotify] = useState(
    location.state?.shouldNotify ?? true
  );
  const [zoneName, setZoneName] = useState(location.state?.name ?? "");

  const { fetchDevice, getServicesLoading } = useGetDeviceByUrl();
  const { startStreaming, stopStreaming, isVideoLoaded } = useStreamProcesses();

  const { data: deletedZoneData } = useListenToDeleteZone(zoneId);

  // store deviceDetail in ref to stop streaming and PT control when leaving the page
  const deviceDetailsRef = useRef(deviceDetail);

  const {
    pan,
    tilt,
    zoom,
    disabled,
    movePtUp,
    movePtDown,
    movePtLeft,
    movePtRight,
    handleGoToPt,
    handlePanInputChange,
    handleTiltInputChange,
    handleZoomInputChange,
    validationState,
    resetValidationState,
    validateCreateZoneForm,
  } = usePTController({
    deviceId,
    deviceName: deviceNameFromUrl,
    nodeId,
    deviceData: deviceDetail?.deviceData,
    zoneName: data?.getZone?.name ?? zoneName,
  });

  const stopStreamingAndPTControl = () => {
    if (deviceDetailsRef.current) {
      stopStreaming(deviceDetailsRef.current);

      publishNode({
        message: JSON.stringify({
          TARGET: "PANTILT",
          ACTION: "STOP_PT_CONTROL",
          device_name: deviceDetailsRef.current.name,
        }),
        nodeId,
      }).then(() => {
        setPtStarted(false);

        deviceDetailsRef.current = null;
      });
    }

    resetValidationState();
  };

  useEffect(() => {
    return () => {
      // stop streaming and PT control when leaving the page
      stopStreamingAndPTControl();
    };
  }, []);

  useEffect(() => {
    if (!deviceDetail) return;

    // start PT control if device has PT and it hasn't started yet
    if (deviceDetail.deviceData?.hasPanTilt && !ptStarted) {
      publishNode({
        message: JSON.stringify({
          TARGET: "PANTILT",
          ACTION: "START_PT_CONTROL",
          device_name: deviceDetail.name,
        }),
        nodeId: nodeId,
      }).then(() => {
        setPtStarted(true);
      });
    }
  }, [deviceDetail, ptStarted]);

  useEffect((): void => {
    if (getServicesLoading) return;

    setFetchingDeviceData(true);

    if (deviceId && customerId) {
      fetchDevice().then((device): void => {
        if (device) {
          setDeviceDetail(device);

          deviceDetailsRef.current = device;

          if (!isVideoLoaded) {
            startStreaming(device);
          }

          setFetchingDeviceData(false);
        }
      });
    }
  }, [deviceId, customerId, getServicesLoading]);

  useBeforeUnload();

  const saveImage = (): void => {
    const deviceName = deviceNameFromUrl.replace("DE#", "");

    publishNode({
      message: JSON.stringify({
        ACTION: "SAVE_IMAGE",
        s3_key: createS3KeyForZone(nodeId, zoneName, deviceName), // generate s3_key e.g C_cleanconnect/C_cleanconnect_L_tp_N_solartrailer-agx4/keyframes/tp-ch4_keyframe.jpg // also need to save it to zone item
        TARGET: "CAMERA_MANAGER",
        device_id: deviceName,
      }),
      nodeId,
    }).catch((error): void => {
      errorNotification("Something went wrong when saving image to S3");

      console.error(error);
    });
  };

  // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
  // note: there is a concern that when we update a zone to share the same name as an existing zone.
  // This will lead to a conflict of keyframe names in S3.
  // However there is preliminary steps to make sure zone names are unique, so there is less urgency to fix it from a S3 key perspective.
  const updateZoneHandler = (): void => {
    const isValid = validateCreateZoneForm(zoneName, pan, tilt, zoom);

    if (!isValid) return;

    const input = {
      deviceId: customerId + "#" + deviceId,
      zoneId: zoneId,
      name: zoneName,
      status,
      pan,
      tilt,
      zoom,
      shouldNotify,
    };

    const validName = validateZoneName(zoneName);

    if (!validName) {
      return;
    }

    updateZone(input).then((): void => {
      saveImage();

      stopStreamingAndPTControl();

      navigate(
        `/device/${encodeURIComponent(deviceId)}/${encodeURIComponent(
          zoneId
        )}?deviceName=${deviceNameFromUrl}&nodeId=${encodeURIComponent(nodeId)}`
      );
    });
  };

  const zoneLink = `/device/${encodeURIComponent(
    deviceId
  )}/${encodeURIComponent(
    zoneId
  )}?deviceName=${deviceNameFromUrl}&nodeId=${encodeURIComponent(nodeId)}`;

  const confirmCancel = (): void => {
    confirm({
      title: "Are you sure you want to cancel Zone editing?",
      confirmationText: "Cancel",
      cancellationText: "Back",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        disabled: loading,
        color: "primary",
      },
    }).then(async (): Promise<void> => {
      stopStreamingAndPTControl();

      navigate(zoneLink);
    });
  };

  useEffect((): void => {
    if (!location.state && customerId) {
      getZone();
    }
  }, [customerId]);

  useEffect(() => {
    if (deletedZoneData?.listenToDeleteZone?.id === zoneId) {
      stopStreamingAndPTControl();

      navigate(`/device/${encodeURIComponent(deviceId)}`);
    }
  }, [deletedZoneData]);

  useEffect((): void => {
    if (data?.getZone && ptStarted) {
      const fetchedZoned = data.getZone;

      const panValue = fetchedZoned.pan ?? PAN_DEFAULT;
      const tiltValue = fetchedZoned.tilt ?? TILT_DEFAULT;
      const zoomValue = fetchedZoned.zoom ?? ZOOM_DEFAULT;
      const zoneNameValue = fetchedZoned.name;

      setStatus(fetchedZoned.status);

      setShouldNotify(fetchedZoned.shouldNotify ?? true);

      setZoneName(zoneNameValue);

      handleGoToPt(panValue, tiltValue, zoomValue, zoneNameValue);
    }
  }, [data, ptStarted]);

  const mainZoneName = data?.getZone?.name ?? "";

  const breadcrumbItems = [
    { label: "Devices", path: "/devices" },
    {
      label: deviceNameFromUrl,
      path: "/device/" + encodeURIComponent(deviceId),
    },
    { label: mainZoneName, path: zoneLink },
    { label: `Edit ${zoneName}` },
  ];

  if (getZoneLoading || !data || fetchingDeviceData) {
    return <FullPageLoader />;
  }

  return (
    <>
      <BreadcrumbNavigation items={breadcrumbItems} />

      <ItemDetailHeader name={"Edit " + mainZoneName} />

      <MainPaperWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                label="Zone name"
                value={zoneName}
                error={validationState.zoneName.hasError}
                helperText={validationState.zoneName.errorMessage}
                onChange={(e): void => setZoneName(e.target.value)}
              />
              <Typography sx={{ margin: "32px 0 8px 0" }} variant="subtitle1">
                Set up the camera position
              </Typography>

              <Box>
                <ControlTable
                  pan={pan}
                  tilt={tilt}
                  zoom={zoom}
                  ptDisabled={disabled}
                  validation={validationState}
                  showValidationAlert
                  handlePanInputChange={handlePanInputChange}
                  handleGoToPt={handleGoToPt}
                  handleTiltInputChange={handleTiltInputChange}
                  handleZoomInputChange={handleZoomInputChange}
                  movePtLeft={movePtLeft}
                  movePtRight={movePtRight}
                  movePtUp={movePtUp}
                  movePtDown={movePtDown}
                  hasZoom={deviceDetail?.deviceData?.hasZoom}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: "100%",
              }}
            >
              <HLSStreamPlayer />
            </Box>
          </Grid>
        </Grid>
      </MainPaperWrapper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          variant="outlined"
          color="inherit"
          onClick={confirmCancel}
        >
          Cancel
        </StyledLoadingButton>

        <StyledLoadingButton
          sx={{
            marginTop: "1.5em",
          }}
          loading={loading}
          variant="contained"
          color="primary"
          onClick={updateZoneHandler}
        >
          Save changes
        </StyledLoadingButton>
      </Box>
    </>
  );
};

export default EditZonePage;
