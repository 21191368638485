import React from "react";

import { type PopupState as PopupStateType } from "material-ui-popup-state/hooks";

import { InfoOutlined } from "@mui/icons-material";
import { Box, Menu, MenuItem, Typography, styled } from "@mui/material";
import { CSSObject } from "@mui/styled-engine";

import { IModel } from "../../../pages/devices/hooks/useDeviceTableRows";
import { ServiceTypeEnum } from "../../../pages/model-manager/variables/modelManager";

const StyledMenu = styled(Menu)(
  ({ theme }): CSSObject => ({
    "& .MuiPaper-root": {
      border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
      borderRadius: "8px",
      marginTop: "5px",
      boxShadow: "0px 4px 20px 0px #0816331A",
    },
  })
);

const PauseMenuItem = styled(MenuItem)(
  ({ theme }): CSSObject => ({
    minWidth: "100px",
    width: "auto",
    display: "flex",
    alignContent: "center",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
  })
);

const DeleteMenuItem = styled(MenuItem)(
  ({ theme }): CSSObject => ({
    color: theme.palette.error.dark,
    display: "flex",
    alignContent: "center",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.error.light,
    },
  })
);

type StyledModelMenuComponentProps = {
  currentModel: IModel;
  popupState: PopupStateType;
  anchorEl: null | HTMLElement;
  isRunning?: boolean;
  runningLeakModels?: string[];
  handleClose: () => void;
  onDeleteHandler: () => void;
  onChangeModelStatus: () => void;
};

const StyledModelMenuComponent: React.FC<StyledModelMenuComponentProps> =
  React.memo(
    ({
      currentModel,
      popupState,
      handleClose,
      anchorEl,
      isRunning,
      runningLeakModels,
      onDeleteHandler,
      onChangeModelStatus,
    }): JSX.Element => {
      const isLeakOrLiquidLeakRunning =
        runningLeakModels && runningLeakModels.length > 0;

      const disableLeakOrLiquidLeakRunning =
        isLeakOrLiquidLeakRunning &&
        !isRunning &&
        currentModel?.serviceName.includes(ServiceTypeEnum.LEAK);

      const handleMenuItemClick = (): void => {
        if (disableLeakOrLiquidLeakRunning) {
          popupState.open();

          return;
        }

        handleClose();
      };

      return (
        <StyledMenu
          anchorEl={anchorEl}
          open={true}
          onClose={handleClose}
          onClick={handleMenuItemClick}
          anchorOrigin={{
            horizontal: disableLeakOrLiquidLeakRunning ? "center" : "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: disableLeakOrLiquidLeakRunning ? "center" : "right",
            vertical: "top",
          }}
        >
          <PauseMenuItem
            sx={{
              "&": {
                color: theme =>
                  disableLeakOrLiquidLeakRunning
                    ? theme.palette.otherTextTertiary.main
                    : "inherit",
              },
              "&:hover": {
                color: theme =>
                  disableLeakOrLiquidLeakRunning
                    ? theme.palette.otherTextTertiary.main
                    : theme.palette.primary.main,
              },
            }}
            key="pauseMenuItem"
            onClick={(): void => {
              if (disableLeakOrLiquidLeakRunning) {
                popupState.open();

                return;
              }

              onChangeModelStatus();

              popupState.close();
            }}
          >
            <Box
              sx={{
                cursor: disableLeakOrLiquidLeakRunning
                  ? "not-allowed"
                  : "pointer",
              }}
            >
              <Typography variant="body2Regular">
                {isRunning ? "Disable" : "Enable"}
              </Typography>
            </Box>
          </PauseMenuItem>

          {disableLeakOrLiquidLeakRunning && (
            <PauseMenuItem
              disableRipple
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "0.5em",
                cursor: "default",

                "&:hover": {
                  backgroundColor: "transparent",
                  color: "inherit",
                },
              }}
            >
              <InfoOutlined
                sx={{ color: theme => theme.palette.otherTextTertiary.main }}
              />
              <Typography
                component="span"
                variant="inputLabel"
                color="text.secondary"
              >
                Leak and Liquid Leak cannot run at the same time. <br />
                Please disable {runningLeakModels[0]} model before <br />
                starting {currentModel?.serviceName} model.
              </Typography>
            </PauseMenuItem>
          )}

          <DeleteMenuItem
            onClick={(): void => {
              popupState.close();

              onDeleteHandler();
            }}
          >
            <Typography variant="body2Regular">Delete</Typography>
          </DeleteMenuItem>
        </StyledMenu>
      );
    }
  );

export default StyledModelMenuComponent;
