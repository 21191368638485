import TextField, { TextFieldProps } from "@mui/material/TextField";

import {
  PAN_TILT_STEP,
  PTZ_MAX_WIDTH,
  PTZ_MIN_WIDTH,
  TILT_MAX,
  TILT_MIN,
  TILT_PATTERN,
} from "./constants";

interface TiltInputProps {
  onTiltChange: (tilt: string) => void;
  value: string;
}

type TiltInputPropsType = TextFieldProps & TiltInputProps;

const TiltInput = ({ onTiltChange, value, ...props }: TiltInputPropsType) => {
  const handleTiltInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const tilt = event.target.value;

    if (TILT_PATTERN.test(tilt)) {
      onTiltChange(tilt);
    }
  };

  const tilt = value.includes(".")
    ? value.slice(0, value.indexOf(".") + 2)
    : value;

  return (
    <TextField
      type="text"
      error={props.error}
      helperText={props.helperText}
      inputProps={{
        inputMode: "decimal",
        pattern: TILT_PATTERN,
        min: TILT_MIN,
        max: TILT_MAX,
        step: PAN_TILT_STEP,
      }}
      sx={{
        minWidth: PTZ_MIN_WIDTH,
        maxWidth: PTZ_MAX_WIDTH,
        "& input": {
          height: "18px",
          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      }}
      id="tilt"
      size="small"
      variant="outlined"
      margin="dense"
      disabled={props.disabled}
      value={tilt}
      onChange={handleTiltInputChange}
    />
  );
};

export default TiltInput;
