import { useMemo } from "react";

import { usePTGetZonesByDevice } from "./usePTGetZonesByDevice";

export interface IZonesByDeviceTableRow {
  id: string;
  deviceId: string;
  name: string;
  pan: string;
  tilt: string;
  zoom: string;
  status: boolean;
  keyFrame: string;
}

export interface IGetZonesByDeviceTableRow {
  rows: IZonesByDeviceTableRow[];
  loading: boolean;
}

export const usePTGetZonesByDeviceTableRow = (
  currentDeviceId: any
): IGetZonesByDeviceTableRow => {
  const { data, loading } = usePTGetZonesByDevice(currentDeviceId);

  const rows = useMemo((): IZonesByDeviceTableRow[] => {
    return (
      data?.getZonesByDevice.items?.map(item => ({
        id: item?.id ?? "",
        deviceId: item?.deviceId ?? "",
        name: item?.name ?? "",
        pan: item?.pan ?? "",
        tilt: item?.tilt ?? "",
        zoom: item?.zoom ?? "",
        status: item?.status ?? false,
        keyFrame: item?.keyFrame ?? "",
      })) ?? []
    );
  }, [data?.getZonesByDevice.items]);

  return { rows, loading };
};
