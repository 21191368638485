import { useMutation, type FetchResult } from "@apollo/client";

import type {
  DeleteServiceInput,
  DeleteServiceMutation,
  DeleteServiceMutationVariables,
  GetServicesByLocationQuery,
  Service,
} from "../../../API";
import { DELETE_SERVICE } from "../../../common/operations/mutations";
import { GET_SERVICES_BY_LOCATION } from "../../../common/operations/queries";
import { servicesByLocationVariable } from "../../devices/variables/devices";
import { useSyncShadow } from "./useSyncShadow";

interface DeleteServiceInterface {
  deleteService: (
    input: DeleteServiceInput
  ) => Promise<FetchResult<DeleteServiceMutation>>;
  data?: DeleteServiceMutation | null;
  loading: boolean;
}

export const useDeleteService = (): DeleteServiceInterface => {
  const { syncShadowsForNode } = useSyncShadow();

  const [removeService, { data, loading }] = useMutation<
    DeleteServiceMutation,
    DeleteServiceMutationVariables
  >(DELETE_SERVICE);

  const deleteService = async (
    input: DeleteServiceInput
  ): Promise<FetchResult<DeleteServiceMutation>> => {
    const nodeId = input.nodeId;
    const parts = nodeId.split("#");
    const locationId = parts.slice(0, 4).join("#");

    return await removeService({
      variables: {
        input,
      },
      update(cache): void {
        const id = input.serviceId;
        const normalizedId = cache.identify({ id, __typename: "Service" });

        cache.evict({ id: normalizedId });

        cache.gc();

        const queryData = cache.readQuery<GetServicesByLocationQuery>({
          query: GET_SERVICES_BY_LOCATION,
          variables: { locationId },
        });

        if (queryData) {
          const updatedServices = queryData.getServicesByLocation.items.filter(
            service => service?.id !== id
          );

          cache.writeQuery({
            query: GET_SERVICES_BY_LOCATION,
            data: {
              getServicesByLocation: {
                ...queryData.getServicesByLocation,
                items: updatedServices,
              },
            },
          });

          servicesByLocationVariable(updatedServices as Service[]);
        }

        syncShadowsForNode(input.nodeId);
      },
      refetchQueries: [
        "GetServices",
        "GetServicesByLocation",
        "GetZonesByDevice",
      ],
    });
  };

  return { deleteService, data, loading };
};
