import { Box, Dialog, Paper, TextField, Typography } from "@mui/material";

import { useState } from "react";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import env from "../../../../../../environment.json";
import { useLocation } from "react-router-dom";
import BreadcrumbNavigation from "../../../common/components/tabs/BreadcrumbNavigation";
import { useCreateSecret } from "../hooks/useCreateSecret";
import { SecretType } from "../../../API";

const S3IntegrationPage = (): JSX.Element => {
  const [accessKey, setAccessKey] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const [bucketName, setBucketName] = useState("");
  const [bucketRegion, setBucketRegion] = useState("");

  const [isDisconnectModalVisible, setIsDisconnectModalVisible] =
    useState(false);
  const customerId = useCustomerIdGuard();
  const location = useLocation();
  const { createSecret } = useCreateSecret();

  const showTrusts = (): void => {
    // should we store this in secrets manager?
    window.alert(env.name === "prod" ? "448051442496" : "534256877503");
  };

  const handleConnect = (): void => {
    const accessKeySecretParams = {
      secretType: SecretType.ACCESS_KEY,
      secretValue: accessKey,
    };

    const secretAccessKeySecretParams = {
      secretType: SecretType.SECRET_ACCESS_KEY,
      secretValue: secretAccessKey,
    };

    const bucketNameSecretParams = {
      secretType: SecretType.BUCKET_NAME,
      secretValue: bucketName,
    };

    const bucketRegionSecretParams = {
      secretType: SecretType.BUCKET_REGION,
      secretValue: bucketRegion,
    };

    const secretsParams = [
      accessKeySecretParams,
      secretAccessKeySecretParams,
      bucketNameSecretParams,
      bucketRegionSecretParams,
    ];

    secretsParams.forEach(secretParams => {
      createSecret({ customerId, ...secretParams });
    });
  };

  const integrationPath = location.pathname.includes("sensory")
    ? "/sensory/system-settings/integrations"
    : "/system-settings/integrations";

  const breadcrumbItems = [
    { label: "Integrations", path: integrationPath },
    {
      label: "S3",
    },
  ];

  return (
    <>
      <Dialog
        open={isDisconnectModalVisible}
        onClose={(): void => setIsDisconnectModalVisible(false)}
      >
        <Typography variant="h3" marginBottom={2}>
          Are you sure you want to disconnect S3 integration?
        </Typography>
        <Typography marginBottom={2}>
          After you disconnect the integration you won&apos;t be receving any
          notifications from there. You can connect back at any time.
        </Typography>
        <button>Cancel</button>
        <button>Disconnect</button>
      </Dialog>
      <Box>
        <Box sx={{ marginBottom: "1em" }}>
          <BreadcrumbNavigation items={breadcrumbItems} />
        </Box>

        <Box padding="0" marginBottom="1em">
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" marginBottom={2}>
              S3
            </Typography>
            <button
              onClick={(): void => {
                setIsDisconnectModalVisible(true);
              }}
            >
              Disconnect
            </button>
          </Box>
          <Paper style={{ padding: 16 }}>
            <Typography style={{ fontWeight: "bold" }}>
              Connection Details
            </Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                required
                id="access-key-id"
                label="Access Key ID"
                value={accessKey}
                onChange={(e): void => setAccessKey(e.target.value)}
              />
              <TextField
                required
                id="secret access key"
                label="Secret Access Key"
                value={secretAccessKey}
                onChange={(e): void => setSecretAccessKey(e.target.value)}
              />
              <TextField
                required
                id="secret bucket name"
                label="Secret Bucket Name"
                value={bucketName}
                onChange={(e): void => setBucketName(e.target.value)}
              />
              <TextField
                required
                id="secret bucket region"
                label="Secret Bucket Region"
                value={bucketRegion}
                onChange={(e): void => setBucketRegion(e.target.value)}
              />
            </Box>
            <Typography style={{ fontWeight: "bold" }}>
              Switch on notifications you &apos;d like to receive
            </Typography>
            <Box flexDirection="column" display="flex">
              <div>toggle button table</div>
              <button disabled>toggleEventRoutersAlerts</button>
              <button disabled>toggleNodeConfigAlerts</button>
            </Box>
            <Box>
              <button>Cancel</button>
              <button onClick={(): void => handleConnect()}>Connect S3</button>
              <button onClick={(): void => showTrusts()}>Setup trusts</button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default S3IntegrationPage;
