import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function PlayIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&": {
          cursor: "pointer",
          fill: "none",
        },
        "&:hover": {
          fill: theme.palette.primary.main,
        },
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M20.4086 9.35258C22.5305 10.5065 22.5305 13.4935 20.4086 14.6474L7.59662 21.6145C5.53435 22.736 3 21.2763 3 18.9671L3 5.0329C3 2.72368 5.53435 1.26402 7.59661 2.38548L20.4086 9.35258Z"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}

const TableCellWithVideocamIconOnHover = (props: SvgIconProps) => {
  return (
    <IconButton size="small" color="primary">
      <PlayIcon {...props} />
    </IconButton>
  );
};

export default TableCellWithVideocamIconOnHover;
