import { Box, Typography } from "@mui/material";
import AccessDeniedIcon from "../../common/components/icons/AccessDeniedIcon";

const NotAuthorizedPage = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1em",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "450px",
        }}
      >
        <Box>
          <AccessDeniedIcon />
        </Box>

        <Typography variant="h4">Oops, Access Denied</Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: theme => theme.palette.text.secondary,
          }}
        >
          We are sorry, but you don’t have permission to access this page.
          Please, refer to your system administrator.
        </Typography>
      </Box>
    </Box>
  );
};

export default NotAuthorizedPage;
