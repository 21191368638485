import { useTheme } from "@mui/material/styles";

const AccessDeniedIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 36C0 16.1178 16.1178 0 36 0C55.8823 0 72 16.1178 72 36C72 55.8823 55.8823 72 36 72C16.1178 72 0 55.8823 0 36Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M44.3337 32.6667V29.3333C44.3337 24.731 40.6027 21 36.0003 21C31.398 21 27.667 24.731 27.667 29.3333V32.6667M36.0003 40.1667V43.5M30.667 51H41.3337C44.1339 51 45.5341 51 46.6036 50.455C47.5444 49.9757 48.3093 49.2108 48.7887 48.27C49.3337 47.2004 49.3337 45.8003 49.3337 43V40.6667C49.3337 37.8664 49.3337 36.4663 48.7887 35.3967C48.3093 34.4559 47.5444 33.691 46.6036 33.2116C45.5341 32.6667 44.1339 32.6667 41.3337 32.6667H30.667C27.8667 32.6667 26.4666 32.6667 25.397 33.2116C24.4562 33.691 23.6913 34.4559 23.212 35.3967C22.667 36.4663 22.667 37.8664 22.667 40.6667V43C22.667 45.8003 22.667 47.2004 23.212 48.27C23.6913 49.2108 24.4562 49.9757 25.397 50.455C26.4666 51 27.8667 51 30.667 51Z"
        stroke="white"
        stroke-width="3.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AccessDeniedIcon;
