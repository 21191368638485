import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  IGasLeakTableRow,
  IParsedGasLeakEvent,
} from "../../common/events.models";
import { useGetTimestreamDataToExport } from "../../common/hooks/useGetTimestreamDataToExport";

export const useGetGasLeakEventsToExport = () => {
  const { fetchTimestreamDataToExport, loading } =
    useGetTimestreamDataToExport();

  const getCsvDataToExport = async (): Promise<IGasLeakTableRow[]> => {
    const rows = await fetchTimestreamDataToExport(mapData);

    return rows;
  };

  const mapData: (parsedData: IParsedGasLeakEvent[]) => IGasLeakTableRow[] = (
    parsedData: IParsedGasLeakEvent[]
  ): IGasLeakTableRow[] => {
    const mappedData: IGasLeakTableRow[] =
      parsedData?.map((item: IParsedGasLeakEvent, index: number) => ({
        rowIndex: index + 1,
        rowId: item?.id ?? "",
        location: item?.location_id ?? "",
        zone: item?.zone_id ?? "",
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        rate: item?.rate_mcf ?? "",
        volume: item?.leak_volume ?? "",
        mediaOutput: item?.mediaOutput ?? "",
        keyFrame: item?.keyFrame ?? "",
        cameraId: item?.camera_id ?? "",
      })) ?? [];

    return mappedData;
  };

  return { loading, getCsvDataToExport };
};
