import { ZONE_NAME_PATTERN } from "../variables/common";
import { errorNotification } from "../variables/notification";

export function validateZoneName(zoneName: string): boolean {
  const validName = ZONE_NAME_PATTERN.test(zoneName);

  if (!validName) {
    errorNotification("Invalid characters used");

    return false;
  }

  return validName;
}
