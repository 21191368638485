// OBSOLETE: replaced with RoleEum

// TODO: remove after testing
export const enum AccountTypeEnum {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
  CC_ADMIN = "CC ADMIN",
  CC_OPS_ADMIN = "CC OPS ADMIN",
  CC_OPS = "CC OPS",
  CC_SALES = "CC SALES",
  PARTNER_ADMIN = "PARTNER ADMIN",
  PARTNER_USER = "PARTNER USER",
  CUSTOMER_ADMIN = "CUSTOMER ADMIN",
  CUSTOMER_OPERATOR_FOREMAN = "CUSTOMER OPERATOR FOREMAN",
  CUSTOMER_OPS_TEAM = "CUSTOMER OPS TEAM",
  CUSTOMER_HSE = "CUSTOMER HSE",
  CUSTOMER_SERCURITY = "CUSTOMER SECURITY",
  CC_PROCTORS = "CC PROCTORS",
  PENDING_CUSTOMER = "PENDING CUSTOMER",
  VERIFIER = "VERIFIER",
}

export enum RouteEnum {
  Home = "/",
  Login = "/login",
  PendingUser = "/pending-user",
  Unauthorized = "/unauthorized",
  Success = "/success",
  AppOld = "old",
  Minerva = "/minerva",
  NothingHerePage = "*",

  Products = "/products",
  SystemSettings = "/system-settings",
  DeploymentHealth = "/deployment-health",
  EmailNotifications = "/email-notifications",

  Integrations = "/system-settings/integrations",
  IntegrationsAzure = "/integrations/azure",
  IntegrationsS3 = "/integrations/s3",
  IntegrationsEdge = "/integrations/edge",

  RecentActivities = "/recent-activities",
  LiveView = "/recent-activities/live-view",
  ZoneList = "/recent-activities/zones-list/:deviceId",
  ZoneListShort = "/recent-activities/zones-list",
  CreateZone = "/recent-activities/create-zone",

  GasLeak = "/recent-activities/gasleak",
  FireSmoke = "/recent-activities/firesmoke",
  Fire = "/recent-activities/fire",
  Smoke = "/recent-activities/smoke",
  HardHat = "/recent-activities/hardhat",
  Agg = "/recent-activities/agg",
  Tlm = "/recent-activities/tlm",
  LiquidLeak = "/recent-activities/liquidleak",

  CustomerSettings = "/customer-settings",
  SettingsPage = ":tabId",

  ManageLocations = "manage-locations",
  CreateLocation = "/manage-locations/create",
  EditLocation = "manage-locations/edit",
  EditLocationPageLocationId = "/manage-locations/edit/:locationId",

  AlertManagement = "alert-management",
  CreateAlert = "alert-management/create",

  Nodes = "/nodes",
  CreateNode = "/nodes/create",

  Devices = "/devices",
  DevicesPageLocationId = "/devices/:locationId",
  DevicesPageNodeId = "/devices/:nodeId",
  Device = "/device/:deviceId",
  CreateDevice = "/devices/create",
  ZoneSetup = "/devices/zone-setup",
  CreateDeviceZone = "/device/create-zone",
  EditDevice = "/device/edit/:deviceId",
  CreateDeviceZoneDeviceId = "/device/:deviceId/create-zone",
  EditZoneDeviceIdZoneId = "/device/:deviceId/:zoneId/edit-zone",
  ZoneOverviewPageDeviceIdZoneId = "/device/:deviceId/:zoneId",

  Model = "/model",
  CreateModel = "/model/create",
  AddModelPage = "/device/:deviceId/add-model/:modelToRun",
  OverviewModelPage = "/device/:deviceId/model/:modelType",

  Statistics = "/statistics",

  Sensory = "/sensory",
  SensoryRecentActivities = "/sensory/recent-activities",
  SensoryDataModel = "/sensory/recent-activities/:model",
  SensoryLiveView = "/sensory/live-view",
  SensoryLiveViewZoneList = "/sensory/live-view/zones-list/:deviceId",
  SensoryLiveViewZoneListShort = "/sensory/live-view/zones-list",
  SensoryDeploymentHealth = "/sensory/deployment-health",
  SensorySystemSettings = "/sensory/system-settings",
  SensoryEditLocation = "/sensory/manage-locations/edit/",
  SensoryStatistics = "/sensory/statistics",

  SensoryIntegrations = "/sensory/system-settings/integrations",
  SensoryIntegrationsAzure = "/sensory/integrations/azure",
  SensoryIntegrationsS3 = "/sensory/integrations/s3",
  SensoryIntegrationsEdge = "/sensory/integrations/edge",

  SensoryGasLeak = "/sensory/recent-activities/gasleak",
  SensoryFireSmoke = "/sensory/recent-activities/firesmoke",
  SensoryFire = "/sensory/recent-activities/fire",
  SensorySmoke = "/sensory/recent-activities/smoke",
  SensoryHardHat = "/sensory/recent-activities/hardhat",
  SensoryAgg = "/sensory/recent-activities/agg",
  SensoryTlm = "/sensory/recent-activities/tlm",
  SensoryLiquidLeak = "/sensory/recent-activities/liquidleak",

  RecentActivitiesPlatesManagement = "/recent-activities/agg/plates-management",
  RecentActivitiesPlatesManagementPauseAlerts = "/recent-activities/agg/plates-management/pause-alerts",
  RecentActivitiesPlatesManagementCreatePlateNumber = "/recent-activities/agg/plates-management/create-plate-number",
  RecentActivitiesPlatesManagementEditPlateNumber = "/recent-activities/agg/plates-management/edit-plate-number",
  RecentActivitiesPlatesManagementEditCategory = "/recent-activities/agg/plates-management/edit-category",
  SystemSettingsPlatesManagementCreatePlateNumber = "/system-settings/create-plate-number",

  SensoryRecentActivitiesPlatesManagement = "/sensory/recent-activities/agg/plates-management",
  SensoryRecentActivitiesPlatesManagementCreatePlateNumber = "/sensory/recent-activities/agg/plates-management/create-plate-number",
  SensoryRecentActivitiesPlatesManagementEditPlateNumber = "/sensory/recent-activities/agg/plates-management/edit-plate-number",
  SensoryRecentActivitiesPlatesManagementEditCategory = "/sensory/recent-activities/agg/plates-management/edit-category",
  SensorySystemSettingsPlatesManagementCreatePlateNumber = "/sensory/system-settings/create-plate-number",

  HumanValidator = "/human-validator",
  HVReview = "/hv-review",

  LeakFinder = "/leak-finder",
  LeakFinderClientUploads = "/leak-finder/client-uploads",
  LeakFinderClientUploadsCreate = "/leak-finder/client-uploads/create",
  LeakFinderClientUploadsEditClient = "/leak-finder/client-uploads/edit-client",
  LeakFinderClientUploadsEditGroup = "/leak-finder/client-uploads/edit-group",
  LeakFinderUploadNewVideoGroupPage = "/leak-finder/new-video-group",
  LeakFinderUserPreferences = "/leak-finder/user-preferences",

  MediaViewer = "/media/:base64/:bucketKey",

  Marketplace = "https://auth-fe.dev.autonomous365.ai/marketplace",
  Luminary = "https://auth-fe.dev.autonomous365.ai/luminary",
  EnerGPT = "https://auth-fe.dev.autonomous365.ai/ener-gpt",
  Administration = "https://auth.dev.autonomous365.ai",
}

export type AccountType =
  | AccountTypeEnum.ADMIN
  | AccountTypeEnum.CUSTOMER
  | AccountTypeEnum.PARTNER
  | AccountTypeEnum.CC_ADMIN
  | AccountTypeEnum.CC_OPS_ADMIN
  | AccountTypeEnum.CC_OPS
  | AccountTypeEnum.CC_SALES
  | AccountTypeEnum.PARTNER_ADMIN
  | AccountTypeEnum.PARTNER_USER
  | AccountTypeEnum.CUSTOMER_ADMIN
  | AccountTypeEnum.CUSTOMER_OPERATOR_FOREMAN
  | AccountTypeEnum.CUSTOMER_OPS_TEAM
  | AccountTypeEnum.CUSTOMER_HSE
  | AccountTypeEnum.CUSTOMER_SERCURITY
  | AccountTypeEnum.CC_PROCTORS
  | AccountTypeEnum.PENDING_CUSTOMER
  | AccountTypeEnum.VERIFIER;

export enum RoleEnum {
  ROOT = "R#ROOT",
  CC_OPS_ADMIN = "R#CC_OPS_ADMIN",
  CC_OPS = "R#CC_OPS",
  CC_SALES = "R#CC_SALES",
  PARTNER_ADMIN = "R#PARTNER_ADMIN",
  PARTNER_USER = "R#PARTNER_USER",
  CUSTOMER_ADMIN = "R#CUSTOMER_ADMIN",
  CUSTOMER_OPERATOR_FOREMAN = "R#CUSTOMER_OPERATOR_FOREMAN",
  CUSTOMER_OPS_TEAM = "R#CUSTOMER_OPS_TEAM",
  CUSTOMER_HSE = "R#CUSTOMER_HSE",
  CUSTOMER_SECURITY = "R#CUSTOMER_SECURITY",
  CC_PROCTORS = "R#HUMAN_VALIDATOR",
  LEAKFINDER_DIRECTOR = "R#LEAKFINDER_DIRECTOR",
  PENDING = "R#PENDING",
  VERIFIER = "R#VERIFIER",
  NONE = "",
}

export const CC_ADMIN_ROLES = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.PARTNER_USER,
  RoleEnum.VERIFIER,
];

export const ROLES = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.PARTNER_USER,
  RoleEnum.CUSTOMER_ADMIN,
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
  RoleEnum.CC_PROCTORS,
  RoleEnum.LEAKFINDER_DIRECTOR,
  RoleEnum.PENDING,
  RoleEnum.VERIFIER,
];

export enum ModelsEnum {
  AllEvents = "All events",
  GasVolumes = "Gas Volumes",
  PPEInfractions = "PPE Infractions",
  Visitors = "Visitors",
  MostActive = "Most active",
}

export enum LeftMenuItems {
  DEVICES = "Devices",
  AI_MANAGER = "AI manager",
  NODES = "Nodes",
  CUSTOMER_SETTINGS = "Customer settings",
  RECENT_ACTIVITIES = "Recent activities",
  LIVE_VIEW = "Live view",
  DEPLOYMENT_HEALTH = "Deployment health",
  STATISTICS = "Statistics",
  SYSTEM_SETTINGS = "System settings",
}
