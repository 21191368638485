import TextField, { TextFieldProps } from "@mui/material/TextField";

import {
  PAN_MAX,
  PAN_MIN,
  PAN_PATTERN,
  PAN_TILT_STEP,
  PTZ_MAX_WIDTH,
  PTZ_MIN_WIDTH,
} from "./constants";

interface PanInputProps {
  onPanChange: (pan: string) => void;
  value: string;
}

type PanInputPropsType = TextFieldProps & PanInputProps;

const PanInput = ({ onPanChange, value, ...props }: PanInputPropsType) => {
  const handlePanInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const panValue = event.target.value;

    if (PAN_PATTERN.test(panValue)) {
      onPanChange(panValue);
    }
  };

  const pan = value.includes(".")
    ? value.slice(0, value.indexOf(".") + 2)
    : value;

  return (
    <TextField
      type="text"
      error={props.error}
      helperText={props.helperText}
      inputProps={{
        inputMode: "decimal",
        pattern: PAN_PATTERN,
        min: PAN_MIN,
        max: PAN_MAX,
        step: PAN_TILT_STEP,
      }}
      sx={{
        minWidth: PTZ_MIN_WIDTH,
        maxWidth: PTZ_MAX_WIDTH,
        "& input": {
          borderRadius: "8px",
          height: "18px",
          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
        ...props.sx,
      }}
      id="pan"
      size="small"
      variant="outlined"
      margin="dense"
      disabled={props.disabled}
      value={pan}
      onChange={handlePanInputChange}
      {...props}
    />
  );
};

export default PanInput;
